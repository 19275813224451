import React, { useEffect, useState } from "react";
import BrowseJobsBanner from "../../Assets/Images/BrowseJobsBanner.png";
import AvatarJob from "../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../Assets/Images/ChatIco.png";
import dollarIco from "../../Assets/Images/dollar-circle.png";
import indiaIco from "../../Assets/Images/indiaFlag.png";
import locationIco from "../../Assets/Images/location.png";
import timeIco from "../../Assets/Images/clock-01.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import del from "../../Assets/Images/delet.png";
import doc from "../../Assets/Images/document-attachment.png";
import fb from "../../Assets/Images/facebook.svg";
import tw from "../../Assets/Images/twitter.svg";
import linked from "../../Assets/Images/linkedin.svg";
import red from "../../Assets/Images/reddit.svg";
import tel from "../../Assets/Images/telegram.svg";
import war from "../../Assets/Images/alert-01.png";
import MailIcon from "../../Assets/Images/mail.png";
import DialIcon from "../../Assets/Images/calling.png";
import { useLocation } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import loader from "../../Assets/Images/loader.svg";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import "./ProjectDetails.css";
import { DropzoneArea } from "material-ui-dropzone";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  useMediaQuery,
  Container,
  Grid,
  IconButton,
  Typography,
  Rating,
  FormLabel,
  TextField,
  duration,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import Markdown from "markdown-to-jsx";
import StarIcon from "@mui/icons-material/Star";
import styled from "@emotion/styled";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { getAPI, postAPI, updateAPI } from "../../Services/Api";
import {
  getCountryCode,
  getCountryName,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../Helpers/Utility";
import NodataFound from "../../Components/NodataFound/NodataFound";
// import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon properly
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function ProjectDetails() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [projectDetail, setProjectDetail] = React.useState(
    location?.state?.project || {}
  );
  const [isBookmarked, setIsBookmarked] = useState(false);
  const isMobile = useMediaQuery("(max-width:768px)");
  const [isFocused, setIsFocused] = useState(false);
  const [isProposalSent, setIsProposalSent] = useState(false);
  const [currentProposal, setCurrentProposal] = useState(null);
  const [myCurrentProposal, setMyCurrentProposal] = useState([]);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const copytext = (value) => {
    navigator.clipboard.writeText(value);
    toast.success(t("toast44"));
  };

  const getProjectDetails = async () => {
    LoaderShow();
    try {
      const res = await postAPI(
        "project/porjectById",
        {
          projectId: searchParams.get("project_id"),
        },
        GetUserDetails().token
      );
      if (res.statusCode == 200) {
        setProjectDetail(res.data);
        await getMyProposals();
        // searchParams.delete("project_id");
        // setSearchParams(searchParams);
      } else {
        throw new Error("");
      }
    } catch (err) {
      console.log(err);
      toast.error(t("toast45"));
      navigate("/");
    }
    LoaderHide();
  };

  const [formData, setFormData] = useState({
    amount: "",
    deliveryTime: "",
    coverLetter: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    amount: "",
    deliveryTime: "",
    coverLetter: "",
  });

  const [dropDownValues, setDropDownValues] = useState([]);

  useEffect(() => {
    LoaderHide();
    getProjectData();
    getMyCurrentUserProposal();
  }, []);

  const [userProposalData, setUserProposalData] = useState("");

  useEffect(() => {
    const userProposal = myCurrentProposal.find(
      (proposal) => proposal?.project?._id === projectDetail?._id
    );
    setUserProposalData(userProposal);

    if (myCurrentProposal.length > 0) {
      if (userProposal) {
        setIsProposalSent(true);
        setCurrentProposal(userProposal);
        setFormData({
          amount: userProposal?.maxAmount,
          deliveryTime: userProposal?.deliveryTime?._id,
          coverLetter: userProposal?.description,
        });
      }
    } else {
      setIsProposalSent(false);
      setFormData({
        amount: "",
        deliveryTime: "",
        coverLetter: "",
      });
    }
  }, [myCurrentProposal, projectDetail]);

  const getProjectData = async () => {
    try {
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setDropDownValues(response?.data);
      }
    } catch (error) {
      console.log("error===", error);
    }
  };

  const handleBookmarkClick = () => {
    setIsBookmarked(!isBookmarked);
  };

  const handleAmount = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      amount: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      amount: value ? (value <= 0 ? t("greaterThen0") : "") : t("addAmt"),
    }));
  };

  const handleDeliveryTime = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      deliveryTime: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      deliveryTime: isDropdownSelected(value) ? "" : t("selectDel"),
    }));
  };

  const handleCoverLetter = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      coverLetter: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      coverLetter: value.length < 10 ? t("coverLet") : "",
    }));
  };

  const isDropdownSelected = (value) => value !== "";

  const isNotEmpty = (value) => value.trim() !== "";

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!isNotEmpty(formData.amount)) {
      errors.amount = t("addAmt");
      isValid = false;
    } else if (formData.amount <= 0) {
      errors.amount = t("greaterThen0");
      isValid = false;
    }
    if (
      !isNotEmpty(formData?.coverLetter) ||
      formData?.coverLetter?.length < 10
    ) {
      errors.coverLetter = t("coverLet");
      isValid = false;
    }

    if (!isDropdownSelected(formData.deliveryTime)) {
      errors.deliveryTime = t("selectDel");
      isValid = false;
    }

    setErrorMessages(errors);
    return isValid;
  };

  const sendProposal = async () => {
    if (validateForm()) {
      LoaderShow();
      try {
        const token = GetUserDetails().token;

        const dataToSend = {
          description: formData?.coverLetter,
          minAmount: "1",
          maxAmount: formData?.amount,
          currency: "USD",
          deliveryTime: formData?.deliveryTime,
          projectId: projectDetail?._id,
        };

        const response = await postAPI("proposal/create", dataToSend, token);
        if (response.statusCode === 200) {
          LoaderHide();
          getMyProposals();
          getMyCurrentUserProposal();
          toast.success(t(response.message));
          setIsProposalSent(true);
          setCurrentProposal(dataToSend);
          setFormData({
            amount: dataToSend.maxAmount,
            deliveryTime: dataToSend.deliveryTime,
            coverLetter: dataToSend.description,
          });
        }
      } catch (error) {
        toast.error(error.message);
        LoaderHide();
      }
    } else {
      LoaderHide();
    }
  };

  const updateProposal = async () => {
    if (validateForm()) {
      LoaderShow();
      try {
        const token = GetUserDetails().token;

        const dataToSend = {
          description: formData?.coverLetter,
          minAmount: "1",
          maxAmount: formData?.amount,
          currency: "USD",
          deliveryTime: formData?.deliveryTime,
        };
        // let projectID

        // if (userProposalData) {
        //   projectID = userProposalData?._id
        // } else {
        //   projectID = projectDetail?._id
        // }

        const response = await updateAPI(
          `proposal/${userProposalData?._id}`,
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(t(response.message));
          setCurrentProposal(dataToSend);
          getMyProposals();
          getMyCurrentUserProposal();
          setFormData({
            amount: dataToSend.maxAmount,
            deliveryTime: dataToSend.deliveryTime,
            coverLetter: dataToSend.description,
          });
        }
      } catch (error) {
        toast.error(error.message);
        LoaderHide();
      }
    } else {
      LoaderHide();
    }
  };

  const [proposals, setProposals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (searchParams.get("project_id")) {
      setProjectDetail({});
      getProjectDetails();
    } else {
      getMyProposals();
    }
  }, []);

  const getMyProposals = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails().token;
      const dataToSend = {
        projectId: projectDetail?._id || searchParams.get("project_id"),
      };
      const response = await postAPI(
        `proposal/project-Proposals`,
        dataToSend,
        token
      );
      if (response.statusCode === 200) {
        LoaderHide();
        setProposals(response?.data);
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const getMyCurrentUserProposal = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails().token;

      const response = await getAPI(`proposal/myproposals`, token);
      if (response.statusCode === 200) {
        LoaderHide();
        setMyCurrentProposal(response?.data);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message);
    }
  };

  const getUrl = () => {
    return `${window.location.href}?project_id=${projectDetail?._id}`;
  };

  const getWhatsAppMsg = () => {
    return encodeURIComponent(`Check out this page: ${getUrl()}`);
  };

  const getMailMsg = () => {
    return encodeURIComponent(
      `Check out this page: <a href="${getUrl()}">Work wave hub</a>`
    );
  };

  return (
    <section className="BrowseJobsEmployee">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Container maxWidth="xl">
        <Box sx={{ py: 10 }}>
          {isMobile ? (
            <Box
              sx={{
                backgroundColor: "#0B3013",
                p: 2,
                border: "1px solid #ccc",
                borderRadius: 4,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{ color: "#fff", fontWeight: 500, mb: 1 }}
                >
                  {projectDetail?.title}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Rating
                  name="hover-feedback"
                  value={projectDetail?.user?.averageRating || 0}
                  precision={0.5}
                  getLabelText={getLabelText}
                  // onChange={(event, newValue) => {
                  //   setValue(newValue);
                  // }}
                  // onChangeActive={(event, newHover) => {
                  //   setHover(newHover);
                  // }}
                  style={{ pointerEvents: "none" }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                  }
                />
                {projectDetail?.user?.averageRating ? (
                  <Box sx={{ mx: 1, color: "#fff" }}>
                    {projectDetail?.user?.averageRating?.toFixed(2)}
                  </Box>
                ) : (
                  ""
                )}
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Avatar
                  src={projectDetail?.user?.profile}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, color: "#fff" }}
                >
                  {projectDetail?.user?.name}
                </Typography>
                <Box>
                  {/* <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      zIndex: 9,
                      display: "flex",
                      alignItems: "center",
                      transform: "translate(-50%, 100%)",
                    }}
                  >
                    <FavoriteBorderIcon
                      sx={{ color: "#fff", cursor: "pointer" }}
                    />
                    <ShareIcon
                      sx={{ px: 1, color: "#fff", cursor: "pointer" }}
                    />
                  </Box> */}
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                {projectDetail?.location ? (
                  getCountryCode(projectDetail?.location) ? (
                    <img
                      width={25}
                      height={25}
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                        projectDetail?.location
                      )}.svg`}
                      alt="chat"
                      style={{ width: 20, marginRight: 8 }}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  {projectDetail?.location || ""}
                </Typography>
              </Box>

              {/* <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={timeIco}
                  alt="chat"
                  style={{ width: 20, marginRight: 8 }}
                />
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  Member Since April 24, 2020
                </Typography>
              </Box> */}
            </Box>
          ) : (
            <Box sx={{ position: "relative", display: "flex" }}>
              <img
                src={BrowseJobsBanner}
                className="bannerImageSize"
                alt="BrowseJobsBanner"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  width: "100%",
                  maxWidth: "50%",
                  left: 0,
                  transform: "translateY(-50%)",
                  textAlign: "right",
                  color: "white",
                  paddingLeft: 12,
                }}
              >
                <Box
                  sx={{ display: "flex", position: "relative", width: "100%" }}
                >
                  <Box sx={{ py: 2, position: "relative" }}>
                    <Typography variant="h4" sx={{ textAlign: "start" }}>
                      {projectDetail?.title}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "start",
                        fontWeight: "normal",
                        display: "block",
                      }}
                    >
                      {/* Lorem Ipsum */}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex" }}>
                    <Avatar
                      src={projectDetail?.user?.image}
                      sx={{
                        width: 70,
                        height: 70,
                        border: 1,
                        borderColor: "#ccc",
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="h5"
                        sx={{ color: "#fff", fontWeight: 500 }}
                      >
                        {projectDetail.user?.name || "User"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {projectDetail?.location ? (
                        getCountryCode(projectDetail?.location) ? (
                          <img
                            width={25}
                            height={25}
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                              projectDetail?.location
                            )}.svg`}
                            alt="chat"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <Typography variant="p" sx={{ color: "#fff", px: 1 }}>
                        {projectDetail?.location}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", py: 1, alignItems: "center" }}>
                      <Rating
                        name="hover-feedback"
                        value={projectDetail?.user?.averageRating || 0}
                        precision={0.5}
                        getLabelText={getLabelText}
                        // onChange={(event, newValue) => {
                        //   setValue(newValue);
                        // }}
                        // onChangeActive={(event, newHover) => {
                        //   setHover(newHover);
                        // }}
                        style={{ pointerEvents: "none" }}
                        emptyIcon={
                          <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                        }
                      />
                      {projectDetail?.user?.averageRating ? (
                        <Box sx={{ mx: 2 }}>
                          {projectDetail?.user?.averageRating?.toFixed(2)}
                        </Box>
                      ) : (
                        ""
                      )}

                      {projectDetail?.user?.ratingCount && (
                        <Box
                          sx={{ display: "flex", alignItems: "center", px: 2 }}
                        >
                          <img
                            src={chatIco}
                            className="filterCommonWhite"
                            alt="chat"
                          />
                          <Typography variant="p" sx={{ color: "#fff", px: 1 }}>
                            {projectDetail?.user?.ratingCount}
                          </Typography>
                        </Box>
                      )}

                      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                        {projectDetail?.user?.location ? (
                          getCountryCode(projectDetail?.user?.location) ? (
                            <img
                              width={25}
                              height={25}
                              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                                projectDetail?.user?.location
                              )}.svg`}
                              alt="chat"
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          projectDetail?.user?.countryCode && (
                            <img
                              width={25}
                              height={25}
                              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${projectDetail?.user?.countryCode}.svg`}
                              alt="chat"
                            />
                          )
                        )}
                        <Typography variant="p" sx={{ color: "#fff", px: 1 }}>
                          {projectDetail?.user?.location ||
                            getCountryName(projectDetail?.user?.countryCode)}
                        </Typography>
                      </Box> */}
                      {projectDetail?.user?.createAt && (
                        <Box
                          sx={{ display: "flex", py: 1, alignItems: "center" }}
                        >
                          <img src={timeIco} className="" alt="chat" />
                          <Typography variant="p" sx={{ color: "#fff", px: 1 }}>
                            Member Since{" "}
                            {new Date(projectDetail?.user?.createAt)
                              ? new Date(
                                  projectDetail?.user?.createAt
                                ).toLocaleDateString()
                              : ""}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  zIndex: 9,
                  display: "flex",
                  alignItems: "center",
                  transform: "translate(-50%, 100%)",
                }}
              >
                <FavoriteBorderIcon sx={{ color: "#fff", cursor: "pointer" }} />
                <ShareIcon sx={{ px: 1, color: "#fff", cursor: "pointer" }} />
              </Box> */}
            </Box>
          )}
        </Box>
        <Box sx={{ pb: 10 }}>
          <Grid container spacing={3}>
            {" "}
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  my: 4,
                  py: 3,
                  mt: 0,
                  borderRadius: 10,
                  border: 1,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: 1,
                    borderColor: "#dadbda",
                    p: 2.5,
                    pt: 0,
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 500, color: "#000" }}
                    >
                      {t("ProjectDetailsOne")}
                    </Typography>
                  </Box>
                </Box>

                <Grid container spacing={2}>
                  {/* Left half */}
                  <Grid item xs={12}>
                    <Box sx={{ py: 2, pb: 0 }}>
                      <Box sx={{ px: 4 }}>
                        <Typography
                          variant="p"
                          sx={{ color: "#222", fontWeight: 400 }}
                        >
                          {projectDetail?.firstName}
                        </Typography>{" "}
                        <Typography
                          className="markdown"
                          sx={{ color: "#222", fontWeight: 400 }}
                        >
                          <Markdown>{projectDetail?.description}</Markdown>
                        </Typography>{" "}
                        <Typography variant="h6" sx={{ color: "#222", mt: 3 }}>
                          {t("SkillsRequired")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          p: 2.5,
                          pt: 0,
                          px: 4,
                          my: 2,
                          mb: 0,
                        }}
                      >
                        {projectDetail?.skillsRequired?.map((skill, index) => (
                          <Box
                            sx={{
                              width: "auto",
                              height: "fit-content",
                              maxWidth: "fit-content",
                              backgroundColor: "#0B3013",
                              color: "#fff",
                              py: 1.2,
                              px: 3,
                              my: 1,
                              borderRadius: 20,
                              mr: "15px",
                            }}
                          >
                            {skill?.name}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    py: 3,
                    borderRadius: 10,
                    border: 1,
                    borderColor: "#e3e3e3",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "#dadbda",
                      p: 2.5,
                      pt: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#000" }}
                      >
                        {t("Place")}
                      </Typography>
                    </Box>
                  </Box>

                  <Grid container spacing={2}>
                    {/* Left half */}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          px: 2.5,
                          my: 2,
                        }}
                      >
                        <Box>
                          <Grid container spacing={2}>
                            {/* Left half */}
                            <Grid item xs={12} md={6}>
                              <Box sx={{ py: 1.5 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  {t("Proposal")}
                                </FormLabel>
                                <TextField
                                  type="number"
                                  value={formData.amount}
                                  onChange={handleAmount}
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth={true}
                                  placeholder={t("Proposal")}
                                />
                                {errorMessages.amount && (
                                  <Typography variant="p" sx={{ color: "red" }}>
                                    {errorMessages.amount}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {/* <Box>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1.7,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  How long will this project take?
                                </FormLabel>
                               
                                <Dropdown
                                  value={dropDownValues?.durationEnumValues?.find(option => option._id === formData.deliveryTime)}
                                  onChange={handleDeliveryTime}
                                  options={dropDownValues?.durationEnumValues?.map(value => ({
                                    value: value._id,
                                    label: `${value?.name}`
                                  }))}
                                  label="Price"
                                />
                              </Box> */}
                              <FormControl sx={{ mt: 6 }} fullWidth>
                                <InputLabel id="time-select-label">
                                  {t("Duration")}
                                </InputLabel>
                                <Select
                                  labelId="time-select-label"
                                  id="time-select"
                                  value={formData?.deliveryTime}
                                  label={t("Duration")}
                                  onChange={handleDeliveryTime}
                                >
                                  {dropDownValues?.durationEnumValues?.map(
                                    (option) => (
                                      <MenuItem
                                        key={option?._id}
                                        value={option?._id}
                                      >
                                        {
                                          option?.[
                                            i18n.language == "en"
                                              ? "name"
                                              : `name_${i18n.language}`
                                          ]
                                        }
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              {errorMessages.deliveryTime && (
                                <Typography variant="p" sx={{ color: "red" }}>
                                  {errorMessages.deliveryTime}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              px: 2.5,
                            }}
                          >
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1.4,
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              {t("CoverLetter")}
                            </FormLabel>
                            <Textarea
                              sx={{
                                width: "100%",
                                border: 1,
                                borderColor: "#cbcbcb",
                                borderRadius: 1,
                              }}
                              minRows={6}
                              maxRows={6}
                              placeholder={t("CoverLetter")}
                              value={formData.coverLetter}
                              onChange={handleCoverLetter}
                            />
                          </Box>
                          {errorMessages.coverLetter && (
                            <Typography
                              variant="p"
                              sx={{ color: "red", ml: 3 }}
                            >
                              {errorMessages.coverLetter}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Box
                        sx={{
                          px: 4,
                          my: 4,
                        }}
                      >
                        {/* <Typography
                          variant="h6"
                          sx={{
                            color: "#000",
                            fontWeight: 600,
                            pb: 0,
                            fontSize: 18,
                            px: 0,
                          }}
                        >
                          Upload Attachments
                        </Typography>
                        <Box
                          className="uploadSec"
                          sx={{
                            py: 3,
                            pb: 0,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={doc}
                              alt="Delete"
                              style={{ cursor: "pointer" }}
                            />

                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 2, wordBreak: 'break-all' }}

                            >
                              84c110f299f31bad53eee9731d43cbca.jpg (154kB)
                            </Typography>
                            <img
                              src={del}
                              alt="Delete"
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                          <Box sx={{ py: 4, pb: 0 }}>
                            <DropzoneArea
                              acceptedFiles={["image/*"]}
                              dropzoneText="Drag and drop an image here or click"
                              // onDelete={(file) => console.log("File deleted:", file)}
                              showPreviewsInDropzone={false}
                              showAlerts={false}
                              dropzoneclassName="custom-dropzone" // Custom CSS class for DropzoneArea
                            />
                          </Box>
                        </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box>
                {proposals?.length && proposals?.length > 0 ? (
                  <Box sx={{ my: 5 }}>
                    <Typography variant="h6" sx={{ color: "#000" }}>
                      {t("Other")}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
                {proposals?.length === 0 ? (
                  <></>
                ) : (
                  proposals?.map((proposal, index) => (
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: 10,
                        border: 1,
                        p: 2.5,
                        borderColor: "#dadbda",
                        my: 4,
                      }}
                    >
                      <Box
                        className="flexColumnRespo"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ display: "flex" }}>
                            <Avatar
                              src={proposal?.user?.profile}
                              className="responsive-image avatarImg"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              px: 2,
                            }}
                          >
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Typography
                                className="userNameText"
                                variant="h5"
                                sx={{ color: "#000", fontWeight: 500 }}
                              >
                                {proposal?.user?.firstName}{" "}
                                {proposal?.user?.lastName}
                              </Typography>
                              <Typography
                                className="userNameText"
                                variant="h5"
                                sx={{ color: "#222", fontWeight: 400, pr: 2 }}
                              >
                                {proposal?.user?.email}
                              </Typography>
                            </Box>
                            <Box
                              className="flexWrapResp"
                              sx={{
                                display: "flex",
                                py: 1,
                                alignItems: "center",
                              }}
                            >
                              <Rating
                                disabled
                                name="hover-feedback"
                                value={proposal?.user?.averageRating || 0}
                                precision={0.5}
                                getLabelText={getLabelText}
                                // onChange={(event, newValue) => {
                                //   setValue(newValue);
                                // }}
                                // onChangeActive={(event, newHover) => {
                                //   setHover(newHover);
                                // }}
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                              {proposal?.user?.averageRating ? (
                                <Box sx={{ mx: 2 }}>
                                  {proposal?.user?.averageRating}
                                </Box>
                              ) : (
                                ""
                              )}

                              {proposal?.user?.totalComments && (
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <img src={chatIco} alt="chat" />
                                  <Typography
                                    variant="p"
                                    sx={{ color: "#000", px: 1 }}
                                  >
                                    {proposal?.user?.totalComments}
                                  </Typography>
                                </Box>
                              )}
                              {/* <Box
                                className="filterSec"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <img src={dollarIco} alt="chat" />
                                <Typography
                                  variant="p"
                                  sx={{ color: "#000", px: 1 }}
                                >
                                  8.7
                                </Typography>
                              </Box> */}
                              {proposal?.user?.location != "Not available" && (
                                <Box
                                  className="filterSec"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    pl: 2,
                                  }}
                                >
                                  {getCountryCode(proposal?.user?.location) && (
                                    <img
                                      width={25}
                                      height={25}
                                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                                        proposal?.user?.location
                                      )}.svg`}
                                      alt="chat"
                                    />
                                  )}
                                  <Typography
                                    variant="p"
                                    sx={{ color: "#000", px: 1 }}
                                  >
                                    {proposal?.user?.location}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                py: 0.5,
                              }}
                            >
                              {proposal?.user?.skills?.map((skill) => (
                                <Typography
                                  className="langText skillText"
                                  variant="h6"
                                  sx={{
                                    color: "#222",
                                    fontSize: 18,
                                    borderRight: 1,
                                    fontWeight: 500,
                                    px: 2,
                                    pl: 0,
                                    my: 1,
                                    ml: 2,
                                  }}
                                >
                                  {skill?.name}
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          className="flexDirectionRowResp catBtnTop"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="userNameText "
                            variant="h5"
                            sx={{
                              color: "#000",
                              px: 2,
                              fontWeight: 500,
                              width: "180px",
                              maxWidth: "min-content",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "block",
                            }}
                          >
                            ${proposal?.maxAmount || 0}
                          </Typography>
                          {/* <Typography
                            variant="p"
                            sx={{ color: "#000", px: 2, fontWeight: 500 }}
                          >
                            Per Hour
                          </Typography> */}
                        </Box>
                      </Box>
                      <Box sx={{ py: 1 }}>
                        <Typography
                          className="markdown"
                          variant="p"
                          sx={{ color: "#000" }}
                        >
                          {/* {proposal?.user?.aboutMe} */}
                          <Markdown>{proposal?.description}</Markdown>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          ml: "auto",
                          justifyContent: "end",
                          py: 0.6,
                        }}
                      >
                        <Typography variant="p" sx={{ color: "#000" }}>
                          {/* Replies within hour */}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                )}
                {/* <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 10,
                    border: 1,
                    p: 2.5,
                    borderColor: "#dadbda",
                    my: 4,
                  }}
                >
                  <Box
                    className="flexColumnRespo"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ display: "flex" }}>
                        <img src={AvatarJob} className="responsive-image avatarImg" alt="dfh" />
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", px: 2 }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            className="userNameText"
                            variant="h5"
                            sx={{ color: "#000", fontWeight: 500 }}
                          >
                            John Doe
                          </Typography>
                          <Typography
                            className="userNameText"
                            variant="h5"
                            sx={{ color: "#222", fontWeight: 400, px: 2 }}
                          >
                            @johndoe
                          </Typography>
                        </Box>
                        <Box
                          className="flexWrapResp"
                          sx={{ display: "flex", py: 1, alignItems: "center" }}
                        >
                          <Rating
                            name="hover-feedback"
                            value={value}
                            precision={0.5}
                            getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          {value !== null && (
                            <Box sx={{ mx: 2 }}>
                              {labels[hover !== -1 ? hover : value]}
                            </Box>
                          )}

                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={chatIco} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              200
                            </Typography>
                          </Box>
                          <Box className='filterSec' sx={{ display: "flex", alignItems: "center" }}>
                            <img src={dollarIco} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              8.7
                            </Typography>
                          </Box>
                          <Box className='filterSec' sx={{ display: "flex", alignItems: "center" }}>
                            <img src={indiaIco} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              India
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", py: 0.5 }}>
                          <Typography
                            className="langText skillText"
                            variant="h6"
                            sx={{
                              color: "#222",
                              fontSize: 18,
                              borderRight: 1,
                              fontWeight: 500,
                              px: 2,
                              pl: 0,
                            }}
                          >
                            UI/UX Designer
                          </Typography>
                          <Typography
                            className="langText skillText"

                            variant="h6"
                            sx={{
                              color: "#222",
                              fontSize: 18,
                              borderRight: 1,
                              fontWeight: 500,
                              px: 2,
                            }}
                          >
                            Illustrations Designer
                          </Typography>
                          <Typography
                            className="langText skillText"

                            variant="h6"
                            sx={{
                              color: "#222",
                              fontSize: 18,
                              fontWeight: 500,
                              px: 2,
                            }}
                          >
                            Figma Expert
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className="flexDirectionRowResp catBtnTop"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        className="userNameText "
                        variant="h5"
                        sx={{ color: "#000", px: 2, fontWeight: 500 }}
                      >
                        $150
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ color: "#000", px: 2, fontWeight: 500 }}
                      >
                        Per Hour
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ py: 1 }}>
                    <Typography variant="p" sx={{ color: "#000" }}>
                      I'm seeking an experienced and efficient app developer to
                      create an innovative social networking application for iOS
                      platform. Details about the target audience are currently
                      confidential. Since the completion I'm seeking an
                      experienced and efficient app developer to create more....
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      ml: "auto",
                      justifyContent: "end",
                      py: 0.6,
                    }}
                  >
                    <Typography variant="p" sx={{ color: "#000" }}>
                      Replies within hour
                    </Typography>
                  </Box>
                </Box> */}
              </Box>

              {/* <Box>
                <Box sx={{ my: 5 }}>
                  <Typography variant="h6" sx={{ color: "#000" }}>
                    Similar Projects
                  </Typography>
                </Box>

                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: 10,
                    border: 1,
                    p: 1,
                    borderColor: "#dadbda",
                  }}
                >
                  <Box
                    className="employeeMainHead flexColumnRespo"
                    sx={{
                      p: 2.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Link to="/projectdetails">
                        <Typography
                          className="employeeHead"
                          variant="h5"
                          sx={{
                            color: "#000",
                            fontWeight: 500,
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Food Delivery Mobile App
                        </Typography>
                      </Link>
                    </Box>
                    <Box
                      className="priceHeadEmployee flexColumnRespo"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        className="catBtnTop"
                        variant="p"
                        sx={{ color: "#222" }}
                      >
                        6 bids
                      </Typography>
                      <Typography
                        className="priceHeadEmployee catBtnTop"
                        variant="h5"
                        sx={{ color: "#000", px: 2, fontWeight: 500 }}
                      >
                        $100 - $150
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ p: 2.5, pt: 0 }}>
                    <Typography
                      className="budgetHeadEmployee"
                      variant="p"
                      sx={{ fontWeight: 500, color: "#000", fontSize: 17 }}
                    >
                      Budget $30 - 250 USD
                    </Typography>
                  </Box>

                  <Box sx={{ p: 2.5, pt: 0 }}>
                    <Typography
                      className="priceHeadEmployee"
                      variant="p"
                      sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
                    >
                      I'm seeking an experienced and efficient app developer to
                      create an innovative social networking application for iOS
                      platform. Details about the target audience are currently
                      confidential. Since the completion I'm seeking an
                      experienced and efficient app developer to create more....
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
                    <Box
                      className="priceHeadEmployee"
                      sx={{
                        width: "auto",
                        maxWidth: "fit-content",
                        backgroundColor: "#F7FDF9",
                        color: "#0B3013",
                        p: 1.4,
                        px: 3,
                        borderRadius: 20,
                      }}
                    >
                      Figma
                    </Box>
                    <Box
                      className="priceHeadEmployee"
                      sx={{
                        width: "auto",
                        maxWidth: "fit-content",
                        backgroundColor: "#F7FDF9",
                        color: "#0B3013",
                        p: 1.4,
                        px: 3,
                        borderRadius: 20,
                      }}
                    >
                      Sketch
                    </Box>
                    <Box
                      className="priceHeadEmployee"
                      sx={{
                        width: "auto",
                        maxWidth: "fit-content",
                        backgroundColor: "#F7FDF9",
                        color: "#0B3013",
                        p: 1.4,
                        px: 3,
                        borderRadius: 20,
                      }}
                    >
                      HTML5
                    </Box>
                  </Box>

                  <Box
                    className="flexColumnRespo"
                    sx={{
                      p: 3,
                      pt: 0,
                      pb: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {value !== null && (
                        <Box sx={{ ml: 2 }}>
                          {labels[hover !== -1 ? hover : value]}
                        </Box>
                      )}
                    </Box>

                    <Box className="catBtnTop">
                      <Typography variant="p" sx={{ color: "#0B3013" }}>
                        32 minutes ago
                      </Typography>

                      <IconButton onClick={handleBookmarkClick}>
                        {isBookmarked ? (
                          <BookmarkIcon sx={{ color: "#FFB800" }} /> // Fill color when bookmarked
                        ) : (
                          <BookmarkBorderIcon sx={{ color: "#0B3013" }} /> // Default color when not bookmarked
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box> */}
            </Grid>
            <Grid item md={8} lg={4} xl={4}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  my: 4,
                  mt: 0,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 1.5 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {projectDetail?.budget?.min < projectDetail?.budget?.max
                      ? `$${Number(projectDetail?.budget?.min)}-$${Number(
                          projectDetail?.budget?.max
                        )}`
                      : `$${Number(projectDetail?.budget?.max)}`}
                  </Typography>

                  <Typography
                    variant="p"
                    sx={{ fontWeight: 300, color: "#000", fontSize: 15 }}
                  >
                    {
                      projectDetail?.[
                        i18n.language == "en"
                          ? "paymentType"
                          : `paymentType_${i18n.language}`
                      ]
                    }
                  </Typography>
                  <br />

                  <Typography
                    variant="p"
                    sx={{
                      fontWeight: 300,
                      py: 1,
                      pb: 0,
                      display: "block",
                      color: "#000",
                      fontSize: 15,
                    }}
                  >
                    {t("Bids")}{" "}
                    {
                      projectDetail?.duration?.[
                        i18n.language == "en" ? "name" : `name_${i18n.language}`
                      ]
                    }
                  </Typography>
                  <br />
                  {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      onClick={sendProposal}
                      sx={{
                        textTransform: "capitalize",
                        my: 2,
                        bgcolor: "#0B3013",
                        fontSize: 16,
                        color: "#fff",
                        borderRadius: 10,
                        px: 4,
                        py: 1,
                        fontWeight: 400,
                      }}
                      endIcon={<ArrowOutwardIcon color="#fff" />}
                    >
                      Send Proposal
                    </Button>{" "}
                  </Box> */}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {isProposalSent ? (
                      <Button
                        onClick={updateProposal}
                        sx={{
                          textTransform: "capitalize",
                          my: 2,
                          bgcolor: "#0B3013",
                          fontSize: 16,
                          color: "#fff",
                          borderRadius: 10,
                          px: 4,
                          py: 1,
                          fontWeight: 400,
                          "&:hover": {
                            bgcolor: "#0B3013",
                          },
                        }}
                        endIcon={<ArrowOutwardIcon color="#fff" />}
                      >
                        {t("UpdateProposal")}
                      </Button>
                    ) : (
                      <Button
                        onClick={sendProposal}
                        sx={{
                          textTransform: "capitalize",
                          my: 2,
                          bgcolor: "#0B3013",
                          fontSize: 16,
                          color: "#fff",
                          borderRadius: 10,
                          px: 4,
                          py: 1,
                          fontWeight: 400,
                          "&:hover": {
                            bgcolor: "#0B3013",
                          },
                        }}
                        endIcon={<ArrowOutwardIcon color="#fff" />}
                      >
                        {t("SendProp")}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      color: "#000",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    {t("Share")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
                  <Box sx={{ px: 1, cursor: "pointer" }}>
                    <a
                      href={`https://api.whatsapp.com/send?text=${getWhatsAppMsg()}`}
                      target="_blank"
                      data-action="share/whatsapp/share"
                    >
                      <WhatsAppIcon sx={{ color: "black" }} />
                    </a>
                  </Box>
                  <Box sx={{ px: 1, cursor: "pointer" }}>
                    <a
                      href={`https://twitter.com/intent/tweet?text=${getWhatsAppMsg()}`}
                      target="_blank"
                    >
                      <img src={tw} alt="dd" />
                    </a>
                  </Box>
                  <Box sx={{ px: 1, cursor: "pointer" }}>
                    <a
                      href={`mailto:?subject=${encodeURIComponent(
                        "Checkout this website"
                      )}&body=${getWhatsAppMsg()}`}
                      target="_blank"
                    >
                      <EmailIcon sx={{ color: "black" }} />
                    </a>
                  </Box>
                  <Box sx={{ px: 1, cursor: "pointer" }}>
                    <a
                      href={`https://t.me/share/url?url=${getUrl()}&text=${getWhatsAppMsg()}`}
                      target="_blank"
                    >
                      <img src={tel} alt="dd" />
                    </a>
                  </Box>
                </Box>
                <Box>
                  <div dir="ltr" className="container">
                    <div className="copy-text">
                      <input
                        type="text"
                        disabled
                        className="text"
                        style={{ background: "transparent", flex: 1 }}
                        value={`${window.location.href}?project_id=${projectDetail?._id}`}
                      />
                      <button
                        onClick={(ev) =>
                          copytext(
                            `${window.location.href}?project_id=${projectDetail?._id}`
                          )
                        }
                      >
                        <ContentCopyIcon />
                      </button>
                    </div>
                  </div>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    alignItems: "center",
                    py: 3,
                    pb: 2,
                  }}
                >
                  <img src={war} alt="dff" />
                  <Typography variant="p" sx={{ px: 1, color: "#222" }}>
                    Report Project
                  </Typography>
                </Box> */}
              </Box>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  my: 4,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 1.5, borderBottom: 1, borderColor: "#d6d6d6" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {t("Client")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", py: 2 }}>
                  {projectDetail?.user?.isEmailVerified ? (
                    <>
                      <img src={MailIcon} alt="df" />
                      <Typography sx={{ px: 2 }}>
                        {t("emailVerified")}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <CancelScheduleSendIcon sx={{ color: "#239f40" }} />
                      <Typography sx={{ px: 2 }}>
                        {t("emailNotVerified")}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box sx={{ display: "flex" }}>
                  {/* {particularUser?.isPhoneNumberVerified ? ( */}
                  <>
                    <img src={DialIcon} alt="df" />
                    <Typography sx={{ px: 2 }}>{t("phoneVerified")}</Typography>
                  </>
                  {/* ) : (
                    <>
                      <Typography sx={{ px: 2 }}>
                        Phone number not verified
                      </Typography>
                    </>
                  )} */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
