import React, { useEffect, useState } from "react";
import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
// import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cat1 from "../../../Assets/Images/Category1.png";
import Cat2 from "../../../Assets/Images/Category2.png";
import Cat3 from "../../../Assets/Images/Category3.png";
import Cat4 from "../../../Assets/Images/Category4.png";
import Cat5 from "../../../Assets/Images/Category5.png";
import "./HomeTalentCategory.css";
import { getCategory } from "../../../Services/CategoryApi";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";

export default function HomeTalentCategory() {
  const { t, i18n } = useTranslation();

  // const categories = [
  //   { image: Cat1, text: "Website, IT & Software" },
  //   { image: Cat2, text: "Design, Media & Architecture" },
  //   { image: Cat3, text: "Data Entry & Admin" },
  //   { image: Cat4, text: "Education" },
  //   { image: Cat5, text: "Artificial Intelligence" },
  // ];

  const [categories, setCategories] = useState([]);

  const fetchCategory = async () => {
    let response = await getCategory(i18n.language);
    if (response) setCategories(response?.data);
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust autoplay speed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section className="HomePartners">
      <Container
        maxWidth="xl"
        sx={{ px: { md: 8, xl: 4, xxl: 4 }, my: 10, mb: 2 }}
      >
        <Box
          className="TalentHeading flexColumnRespo"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h4"
              className="talentHeadingMain"
              sx={{ my: 1, color: "black", fontWeight: 700 }}
            >
              {t("BrowseTalent")}
            </Typography>
            <Typography variant="p" sx={{ color: "black" }}>
              {t("Find")}
            </Typography>
          </Box>

          {/* <Box>
            <Button
            className='catBtnTop'
              sx={{
                textTransform: "capitalize",
                bgcolor: "#F1FCF4",
                fontSize: 16,
                color: "#0B3013",
                borderRadius: 10,
                px: 2,
                py: 1,
              }}
              endIcon={<ArrowOutwardIcon color="#0B3013" />}
            >
              All Categories
            </Button>{" "}
          </Box> */}
        </Box>

        <Box sx={{ py: 4 }}>
          <Slider {...settings} className="slick-slider-custom">
            {categories.map((category, index) => (
              <div key={index} className="slick-slide-custom">
                <Card
                  sx={{
                    border: 0,
                    boxShadow: 0,
                    backgroundColor: "transparent",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      mx: 3,
                      border: 0,
                      borderRadius: 4,
                    }}
                  >
                    <CardMedia
                      style={{
                        height: "300px",
                        paddingTop: "0%",
                        borderRadius: 20,
                        backgroundColor: "rgba(0, 35, 82, 0.7)",
                        opacity: "0.9",
                      }}
                      component="img"
                      image={category.image}
                      title={
                        category?.[
                          i18n.language == "en"
                            ? "name"
                            : `name_${i18n.language}`
                        ]
                      }
                      alt={
                        category?.[
                          i18n.language == "en"
                            ? "name"
                            : `name_${i18n.language}`
                        ]
                      }
                    />
                    <Typography
                      className="repoTextTalentSubHead"
                      variant="h5"
                      style={{
                        position: "absolute",
                        color: "white",
                        top: 29,
                        left: "10px",
                        width: "100%",
                      }}
                    >
                      {
                        category?.[
                          i18n.language == "en"
                            ? "name"
                            : `name_${i18n.language}`
                        ]
                      }
                    </Typography>
                  </Box>
                </Card>
              </div>
            ))}
          </Slider>
        </Box>
      </Container>
    </section>
  );
}
