import React, { useEffect, useState } from "react";
import { Grid, Container, Box, Typography, Button } from "@mui/material";
import donateBg from "../../../Assets/Images/donateBG.png";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import "./HomeDonation.css";
import DonationModal from "./DonationModal/DonationModal";
import { getAPI } from "../../../Services/Api";
import donationBg from "../../../Assets/Images/donation.png";
import PayoutModal from "./PayoutModal/PayoutModal";

export default function HomeDonation() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [payoutOpen, setPayoutOpen] = useState(false);
  const [totalDonation, setTotalDonation] = useState(0);
  const [payoutAmt, setPayoutAmt] = useState(0);

  useEffect(() => {
    getAPI("donation/total", "").then((res) => {
      if (res?.statusCode == 200) {
        setTotalDonation(res?.data?.donation?.totalAmount || 0);
        setPayoutAmt(res?.data?.donationInvestment?.totalAmount || 0);
      }
    });
  }, []);

  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(2) + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2) + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2) + "K";
    } else {
      return num.toString();
    }
  }

  return (
    <>
      <PayoutModal open={payoutOpen} onClose={(ev) => setPayoutOpen(false)} />
      <DonationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        afterPaid={() => {
          getAPI("donation/total", "").then((res) => {
            if (res?.statusCode == 200) {
              setTotalDonation(res?.data?.donation?.totalAmount || 0);
              setPayoutAmt(res?.data?.donationInvestment?.totalAmount || 0);
            }
          });
        }}
      />
      <section className="HomeTestinomials" style={{ marginTop: "30px" }}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={4} // Add spacing between grid items
            sx={{
              px: { xs: 2, md: 6, xl: 6, xxl: 6 },
              pb: 10,
              alignItems: "center",
            }}
            className="testinomialPaddingTop"
          >
            <Grid item xs={12} lg={6}>
              <img src={donateBg} className="donateBG" alt="df" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box>
                <Typography
                  className="LoveHeadeText"
                  variant="h4"
                  sx={{ color: "black", py: 1, fontWeight: 600 }}
                >
                  {t("DonateOneTake")}
                </Typography>
                <Typography
                  className="LoveHeadeText"
                  variant="body1" // Use body1 instead of p
                  sx={{ color: "black", pt: 2, display: "block" }}
                >
                  {t("DonateTwoTake")}
                </Typography>
                <Box
                  sx={{
                    display: { md: "flex", sx: "block" },
                    justifyContent: "start",
                  }}
                >
                  <Typography
                    className="LoveHeadeText"
                    variant="h5"
                    sx={{
                      fontWeight: "normal !important",
                      color: "black",
                      py: 2,
                      position: "relative",
                    }}
                  >
                    <img src={donationBg} alt="donation" />
                    <span
                      style={{
                        position: "absolute",
                        top: "54px",
                        left: "100px",
                        color: "white",
                      }}
                    >
                      {t("totdonation")}
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        top: "60px",
                        left: "21px",
                        width: "61px",
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: "17px",
                      }}
                    >
                      <span>{formatNumber(totalDonation)}$</span>
                    </div>
                  </Typography>
                  <Typography
                    onClick={(ev) => setPayoutOpen(true)}
                    className="LoveHeadeText"
                    variant="h5"
                    sx={{
                      cursor: "pointer",
                      fontWeight: "normal !important",
                      color: "black",
                      py: 2,
                      position: "relative",
                    }}
                  >
                    <img src={donationBg} alt="donation" />
                    <span
                      style={{
                        position: "absolute",
                        top: "54px",
                        left: "95px",
                        color: "white",
                      }}
                    >
                      {t("totPayout")}
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        top: "60px",
                        left: "21px",
                        width: "61px",
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: "17px",
                      }}
                    >
                      <span>{formatNumber(payoutAmt)}$</span>
                    </div>
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "start" }}>
                  <Button
                    onClick={(ev) => setOpenModal(true)}
                    className="catBtnTop"
                    sx={{
                      textTransform: "capitalize",
                      bgcolor: "#0B3013",
                      fontSize: 16,
                      color: "#fff",
                      borderRadius: 10,
                      px: 8,
                      py: 1,
                      "&:hover": { bgcolor: "#0B3013" },
                    }}
                    endIcon={<ArrowOutwardIcon color="#fff" />}
                  >
                    {t("Dontae")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}
