import { Button, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import doc from "../../../../Assets/Images/document-attachment.png";
import Markdown from "markdown-to-jsx";

export default function CompletedProjectDetails({ particularProject, onBack }) {
  const [tabValue, setTabValue] = useState(0); // Change default tab value to 0
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
      <Box sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }} className="plZero prZero">
        <Tabs
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : "off"}
          allowScrollButtonsMobile={isMobile}
          textColor="#222222"
          sx={{
            borderBottom: 1,
            borderColor: "#dbdbdb",
            "& .MuiTabs-indicator": {
              backgroundColor: "#000",
            },
          }}
          value={tabValue}
          onChange={handleChange}
          aria-label={t("ProjectDetails")}
        >
          <Tab
            sx={{
              textTransform: "capitalize",
              pl: 0,
              mx: 1,
              fontSize: 17,
              fontFamily: "inherit",
            }}
            label={t("ProjectDetails")}
          />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <Box sx={{ px: 4, py: 3 }}>
          <Box
            className="flexColumnRespo"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h5"
              sx={{ color: "#000" }}
              className="employeeHead"
            >
              {particularProject?.project?.title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#000" }}
              className="employeeHead"
            >
              $ {particularProject?.project?.budget?.min} - ${" "}
              {particularProject?.project?.budget?.max}
            </Typography>
          </Box>
          <Box className="priceHeadEmployee" sx={{ py: 4 }}>
            <Typography
              className="markdown"
              variant="p"
              sx={{ color: "#000", fontSize: 17 }}
            >
              <Markdown>{particularProject?.project?.description}</Markdown>
            </Typography>
          </Box>

          <Box sx={{ py: 4 }}>
            <Typography variant="h6" sx={{ color: "#000" }}>
              {t("Skill")}
            </Typography>
            <Box
              className="flexWrapResp"
              sx={{ display: "flex", flexWrap: "wrap", py: 3 }}
            >
              {particularProject?.project?.skillsRequired?.map(
                (skill, index) => (
                  <Button
                    onClick={onBack}
                    className="skillText catBtnTop"
                    sx={{
                      fontFamily: "inherit",
                      px: 4,
                      mx: 1,
                      fontSize: "16px",
                      borderRadius: 10,
                      pb: 1,
                      backgroundColor: "#0B3013",
                      width: "auto",
                      maxWidth: "fit-content",
                      textTransform: "capitalize",
                      color: "#fff",
                      display: "flex",
                      fontWeight: "400",

                      "&:hover": {
                        backgroundColor: "#0B3013", // Keep the same color as default
                      },
                    }}
                  >
                    {skill?.name}
                  </Button>
                )
              )}
            </Box>
          </Box>
          <Box sx={{}}>
            <Typography
              variant="h6"
              sx={{
                color: "#000",
                fontWeight: 600,
                pb: 0,
                fontSize: 18,
                px: 0,
              }}
            >
              {t("Attachments")}
            </Typography>
            <Box
              className="uploadSec"
              sx={{
                py: 3,
                pb: 0,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />

                <Typography
                  variant="p"
                  sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
                >
                  {particularProject?.project?.documents?.map((url, index) => (
                    <div key={index}>
                      <h3>{t("FileName")}</h3>
                      <a
                        href={url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url}
                      </a>
                    </div>
                  ))}
                </Typography>
                {/* <img
                  src={del}
                  alt="Delete"
                  style={{ cursor: "pointer" }}
                /> */}
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button
              onClick={onBack}
              sx={{
                fontFamily: "inherit",
                px: 4,
                fontSize: "16px",
                pb: 1,
                backgroundColor: "#0B3013",
                width: "auto",
                maxWidth: "fit-content",
                textTransform: "capitalize",
                color: "#fff",
                display: "flex",
                ml: "auto",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              {t("BackButton")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
