import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  TextField,
  Grid,
  Paper,
  Typography,
  FormLabel,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import ModalAuthBanner from "../../Assets/Images/signinauthBanner.png";
import forgot1 from "../../Assets/Images/ForgotPassVec1.png";
import forgot2 from "../../Assets/Images/ForgotPassVec2.png";
import forgot3 from "../../Assets/Images/ForgotPassVec3.png";
import {
  hasSpecialCharacter,
  isValidEmail,
  LoaderHide,
  LoaderShow,
} from "../../Helpers/Utility";
import { postAPI } from "../../Services/Api";
import { toast } from "react-toastify";
import ModalBannerText3 from "../Signup/ModalBannerText/ModalBannerText3";
// import { useNavigate } from "react-router-dom"; // Add this import
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export default function ForgotPassword({ open, handleClose }) {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [showSection1, setShowSection1] = useState(true);
  const [showSection2, setShowSection2] = useState(false);
  const [showSection3, setShowSection3] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [errors, setErrors] = React.useState({}); // State for validation errors
  const [email, setEmail] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [changeToken, setChangeToken] = useState("");

  // const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    if (searchParams.get("change_password_token")) {
      setShowSection1(false);
      setShowSection2(true);
      setChangeToken(searchParams.get("change_password_token"));
      searchParams.delete("change_password_token");
      setSearchParams(searchParams);
    }
  }, []);

  const handleBackToLogin = () => {
    setShowSection1(true);
    setShowSection2(false);
    setShowSection3(false);
    handleClose();
  };

  // const bgImage = ModalAuthBanner;

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleResetPassword = async () => {
    if (showSection1) {
      // Perform validation before proceeding with signup
      const newErrors = {};

      if (!email.trim()) {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(email)) {
        newErrors.email = "Invalid email format";
      }

      // Update the errors state with new validation errors
      setErrors(newErrors);

      // If there are no errors, proceed with signup
      LoaderShow();
      if (Object.keys(newErrors).length === 0) {
        try {
          const userData = {
            email: email,
          };

          const response = await postAPI("user/forgot-password", userData);
          if (response.success === 200) {
            toast.success(t(response.message), {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowSection1(false); // Hide section 1
            // setShowSection2(true); // Show section 2
            handleClose();
          } else {
            toast.error(t("toast4"), {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          console.error("Failed to post data:", error.message);
          toast.error(t("toast4"), {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        LoaderHide();
        // setShowSection1(false); // Hide section 1
        // setShowSection2(true); // Show section 2
      }
    } else if (changeToken && showSection2) {
      if (password1 !== password2) {
        setErrors({ password2: "Password does not match" });
        return;
      }
      if (password2.length < 8 || !hasSpecialCharacter(password2)) {
        setErrors({ password2: t("PassWordMustBe8Characters") });
        return;
      }
      LoaderShow();
      try {
        const res = await postAPI("user/reset-password", {
          password: password2,
          token: changeToken,
        });
        if (res?.success) {
          toast.success(t("toast5"));
          setShowSection2(false); // Hide section 2
          setShowSection3(true); // Show section 3
        }
      } catch (err) {
        console.log(err);
        toast.error(t(err.message));
      }
      LoaderHide();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="signin-modal-title"
      aria-describedby="signin-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 1200,
          bgcolor: "background.paper",
          boxShadow: 24,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          component="main"
          sx={{ height: "100vh", maxHeight: "90vh" }}
        >
          <CssBaseline />
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "block" }, // Hide on extra-small screens
              backgroundImage: `url(${ModalAuthBanner})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "top",
              position: "relative", // Add relative positioning for the text container
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white", // Adjust text color based on background
                textAlign: "center",
                padding: "16px",
                width: "100%",
                mt: 4,
                // backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for text readability
                borderRadius: "8px",
              }}
            >
              <ModalBannerText3 />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            component={Paper}
            elevation={6}
            square
            sx={{
              display: "flex",
              paddingLeft: 0,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f8f8f9",
              overflowY: "auto",
              maxHeight: "calc(100vh - 64px)",
            }}
          >
            <Box sx={{ p: 9 }}>
              {showSection1 && (
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "start",
                      fontWeight: 700,
                      fontFamily: "inherit",
                    }}
                  >
                    {t("Forgot")}
                  </Typography>
                  <p className="beginText">{t("ToReset")}</p>
                  <Box sx={{ width: "100%", py: 5 }}>
                    <img src={forgot1} alt="c" />
                  </Box>
                  <div>
                    <FormLabel
                      sx={{
                        display: "block",
                        textAlign: "start",
                        py: 1,
                        fontSize: 14,
                      }}
                    >
                      {t("EmailAddd")}
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      sx={{
                        background: isFocused ? "#FFFFFF" : "transparent",
                        borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                        boxShadow: isFocused
                          ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                          : "none",
                      }}
                      fullWidth={true}
                      placeholder={t("EnterEmailAdd")}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </div>
                  <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
                    <Button
                      className="SignUpBtn"
                      sx={{
                        textTransform: "capitalize",
                        width: 100,
                        color: "white",
                      }}
                      onClick={handleResetPassword}
                    >
                      {t("Reset")}
                    </Button>
                  </Box>
                  <Box sx={{ my: 1, display: "flex", justifyContent: "start" }}>
                    <Button
                      onClick={handleBackToLogin}
                      className="SignUpGoogle"
                      sx={{
                        background: "#fff",
                        textTransform: "capitalize",
                        color: "white",
                        mt: 2,
                      }}
                    >
                      {t("BackToHome")}
                    </Button>
                  </Box>
                </Box>
              )}
              {changeToken && showSection2 && (
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "start",
                      fontWeight: 700,
                      fontFamily: "inherit",
                    }}
                  >
                    Reset Password
                  </Typography>
                  {/* <p className="beginText">To reset your password, you need your email or mobile number that can be authenticated</p> */}
                  <Box sx={{ width: "100%", py: 5 }}>
                    <img src={forgot2} alt="c" />
                  </Box>
                  <Box sx={{ my: 1 }}>
                    <FormLabel
                      sx={{
                        display: "block",
                        textAlign: "start",
                        py: 1,
                        fontSize: 14,
                      }}
                    >
                      New password
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      sx={{
                        background: isFocused ? "#FFFFFF" : "transparent",
                        borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                        boxShadow: isFocused
                          ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                          : "none",
                      }}
                      fullWidth={true}
                      placeholder={t("Enter")}
                      type={showPassword1 ? "text" : "password"}
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      error={!!errors.password1}
                      helperText={errors.password1}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(ev) => setShowPassword1((dt) => !dt)}
                              edge="end"
                            >
                              {showPassword1 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box sx={{ my: 1 }}>
                    <FormLabel
                      sx={{
                        display: "block",
                        textAlign: "start",
                        py: 1,
                        fontSize: 14,
                      }}
                    >
                      Confirm Password
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      sx={{
                        background: isFocused ? "#FFFFFF" : "transparent",
                        borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                        boxShadow: isFocused
                          ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                          : "none",
                      }}
                      fullWidth={true}
                      placeholder={t("Enter")}
                      type={showPassword2 ? "text" : "password"}
                      value={password2}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(e) => setPassword2(e.target.value)}
                      error={!!errors.password2}
                      helperText={errors.password2}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(ev) => setShowPassword2((dt) => !dt)}
                              edge="end"
                            >
                              {showPassword2 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
                    <Button
                      className="SignUpBtn"
                      sx={{
                        textTransform: "capitalize",
                        width: 100,
                        color: "white",
                      }}
                      onClick={handleResetPassword}
                    >
                      Reset password
                    </Button>
                  </Box>
                  <Box sx={{ my: 1, display: "flex", justifyContent: "start" }}>
                    <Button
                      onClick={handleBackToLogin}
                      className="SignUpGoogle"
                      sx={{
                        background: "#fff",
                        textTransform: "capitalize",
                        color: "white",
                        mt: 2,
                      }}
                    >
                      Back To Login
                    </Button>
                  </Box>
                  {/* <Box
                    sx={{ display: "flex", justifyContent: "center", py: 4 }}
                  >
                    <span>You have not received the email?</span>{" "}
                    <span className="SigninTextJoin unRe">&nbsp;Resend</span>
                  </Box> */}
                </Box>
              )}
              {changeToken && showSection3 && (
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "start",
                      fontWeight: 700,
                      fontFamily: "inherit",
                    }}
                  >
                    Successfully
                  </Typography>
                  <p className="beginText">
                    Your password has been updated, please change your password
                    regularly to avoid this happening
                  </p>
                  <Box sx={{ width: "100%", py: 5 }}>
                    <img src={forgot3} alt="c" />
                  </Box>
                  {/* <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
                    <Button
                      className="SignUpBtn"
                      sx={{ textTransform: "capitalize", width: 100, color: "white" }}
                    // Add logic to continue here
                    >
                      Continue
                    </Button>
                  </Box> */}
                  <Box sx={{ my: 1, display: "flex", justifyContent: "start" }}>
                    <Button
                      className="SignUpGoogle"
                      onClick={handleBackToLogin}
                      sx={{
                        background: "#fff",
                        textTransform: "capitalize",
                        color: "white",
                        mt: 2,
                      }}
                    >
                      Back To Login
                    </Button>
                  </Box>
                  {/* <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <span>You have not received the email?</span>{" "}
                    <span className="SigninTextJoin unRe">&nbsp;Resend</span>
                  </Box> */}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
