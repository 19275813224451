import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  IconButton,
  InputBase,
  Button,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Search from "../../../Assets/Images/search.png";
import ChatAvatar from "../../../Assets/Images/ChatAvatar.png";
import ChatAvatar2 from "../../../Assets/Images/chat1.png";
import ChatAvatar3 from "../../../Assets/Images/Chat2.png";
import arowCross from "../../../Assets/Images/arrow-up-right-01-round.png";
import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon
import "./EmployeeMessage.css";
import { db } from "../../../Auth/Firebase/firebase";
import {
  collection,
  addDoc,
  orderBy,
  query,
  onSnapshot,
  serverTimestamp,
  Timestamp,
  setDoc,
  doc,
  getDocs,
  where,
  getDoc,
} from "firebase/firestore";
import { getAPI } from "../../../Services/Api";
import {
  GetUserDetails,
  getUserDetailsFromAPI,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import loader from "../../../Assets/Images/loader.svg";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { decryptText, encryptText } from "../../../Helpers/crypto";
import { useTranslation } from "react-i18next";
import { useBrowserJob } from "../../../context/BrowserJob";
import axios from "axios";

// Define chat list data array
const chatListData = [
  {
    name: "Mery Keth",
    role: "HOD",
    lastActive: "35 mins",
    unreadMessages: 2,
  },
  {
    name: "John Doe",
    role: "Software Engineer",
    lastActive: "1 hour",
    unreadMessages: 9,
  },
  {
    name: "John Keth",
    role: "Data Engineer",
    lastActive: "1 hour",
    unreadMessages: 0,
  },
  {
    name: "Keth Doe",
    role: "QA Engineer",
    lastActive: "1 hour",
    unreadMessages: 2,
  },
  {
    name: "Mery Keth",
    role: "HOD",
    lastActive: "35 mins",
    unreadMessages: 2,
  },
  {
    name: "John Doe",
    role: "Software Engineer",
    lastActive: "1 hour",
    unreadMessages: 9,
  },
  {
    name: "John Keth",
    role: "Data Engineer",
    lastActive: "1 hour",
    unreadMessages: 0,
  },
  {
    name: "Keth Doe",
    role: "QA Engineer",
    lastActive: "1 hour",
    unreadMessages: 2,
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: 12,
}));

export default function EmployeeMessage() {
  const [isActive] = useState(true); // Example state for active status

  const [currentUserDetail, setCurrentUserDetail] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [employeeList, setEmployeeList] = useState([]);
  const [accessToken, setAccessToken] = useState("");

  const { rooms, setIsNew } = useBrowserJob();

  const [messages, setMessages] = useState([]);
  const [unseenMessage, setUnSeenMessages] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [unsubscribe, setUnsubscribe] = useState(false);
  const inputRef = useRef(null); // Ref for input field
  const searchInputRef = useRef(null); // Ref for input field
  const chatContainerRef = useRef(null);

  const { t } = useTranslation();

  const sendNotif = () => {
    if (selectedUser?.fcm_token && accessToken) {
      try {
        // DEV CHANGE
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        axios.post(`${process.env.REACT_APP_FCM_URL}`, {
          message: {
            token: selectedUser.fcm_token,
            notification: {
              title: `New message`,
              body: `Unread message sent by ${currentUserDetail.firstName}`,
            },
            webpush: {
              fcm_options: {
                link: `${window.location.origin}/dashboard/employer/message?room_id=${selectedUser.roomId}`,
              },
            },
            data: {
              room_id: selectedUser.roomId,
              type: "CHAT_MESSAGE",
            },
          },
        });
      } catch (err) {}
    }
  };

  const fileRef = useRef(null);

  const handleFileChange = async (ev) => {
    LoaderShow();
    try {
      const storage = getStorage();
      const file = ev.target.files[0];
      const validImgTypes = ["image/jpeg", "image/gif"];
      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      let messageData = {};
      if (validImgTypes.includes(file.type)) {
        messageData = {
          authorId: currentUserDetail.firebaseUID,
          createdAt: Timestamp.now(),
          name: file.name,
          type: "image",
          status: "sent",
          updatedAt: Timestamp.now(),
          uri: url,
          size: file.size,
          width: 500,
          height: 500,
        };
      } else {
        messageData = {
          authorId: currentUserDetail.firebaseUID,
          createdAt: Timestamp.now(),
          name: file.name,
          type: "file",
          status: "sent",
          updatedAt: Timestamp.now(),
          uri: url,
          mimeType: file.type,
          size: file.size,
        };
      }
      setDoc(
        doc(db, "rooms", selectedUser.roomId),
        {
          updatedAt: Timestamp.now(),
        },
        { merge: true }
      );
      await addDoc(
        collection(db, "rooms", selectedUser.roomId, "messages"),
        messageData
      );
      sendNotif();
    } catch (err) {
      console.log(err);
    }
    LoaderHide();
  };

  useEffect(() => {
    getUserDetails();
    getAccessToken();
  }, []);

  useEffect(() => {
    // setEmployeeList([]);
    if (currentUserDetail?.firebaseUID && rooms.length > 0) getRooms();
  }, [currentUserDetail, rooms]);

  useEffect(() => {
    if (searchParams.get("room_id") && employeeList.length > 0) {
      const user = employeeList.find(
        (ele) => ele.roomId == searchParams.get("room_id")
      );
      if (user) setSelectedUser(user);
      searchParams.delete("room_id");
      setSearchParams("room_id");
    }
  }, [employeeList]);

  const getAccessToken = async () => {
    try {
      const token = GetUserDetails()?.token;
      const response = await getAPI("chat/getAccessToken", token);
      if (response?.statusCode == 200) {
        setAccessToken(response?.data || "");
      }
    } catch (err) {}
  };

  const getRooms__old = async () => {
    LoaderShow();
    try {
      setUnSeenMessages({});
      setIsNew(0);
      rooms.map(async (ele) => {
        function getOtherValue(arr, value) {
          if (arr[0] === value) {
            return arr[1];
          } else if (arr[1] === value) {
            return arr[0];
          } else {
            return false;
          }
        }
        const messagesCollection = collection(db, "rooms", ele.id, "messages");
        const messagesQuery = query(
          messagesCollection,
          where("status", "==", "sent"),
          where("authorId", "!=", currentUserDetail.firebaseUID)
        );
        const messagesSnapshot = await getDocs(messagesQuery);
        setUnSeenMessages((dt) => ({
          ...dt,
          [ele.id]: messagesSnapshot.size,
        }));
        const userIds = getOtherValue(
          ele.data().userIds,
          currentUserDetail?.firebaseUID
        );
        if (userIds) {
          const chatUser = await getDoc(doc(db, "users", userIds));
          if (chatUser.data()) {
            setEmployeeList((dt) => {
              const findDt = dt.find((el) => el.roomId == ele.id);
              if (findDt?.roomId) {
                return dt;
              }
              return [
                ...dt,
                { ...chatUser.data(), roomId: ele.id, id: chatUser.id },
              ];
            });
          }
        }
      });
    } catch (err) {
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const moveItem = (fromIndex, toIndex) => {
    // Make a copy of the items array
    const updatedItems = [...employeeList];

    // Remove the item from the original position
    const [movedItem] = updatedItems.splice(fromIndex, 1);

    // Insert the item at the new position
    updatedItems.splice(toIndex, 0, movedItem);

    // Update the state with the new array
    setEmployeeList(updatedItems);
  };

  const getRooms = async () => {
    LoaderShow();
    try {
      setUnSeenMessages({});
      setIsNew(0);
      rooms.map(async (room, index) => {
        const messagesCollection = collection(db, "rooms", room.id, "messages");
        const messagesQuery = query(
          messagesCollection,
          where("status", "==", "sent"),
          where("authorId", "!=", currentUserDetail.firebaseUID)
        );
        const messagesSnapshot = await getDocs(messagesQuery);
        setUnSeenMessages((dt) => ({
          ...dt,
          [room.id]: messagesSnapshot.size,
        }));

        function getOtherValue(arr, value) {
          if (arr[0] === value) {
            return arr[1];
          } else if (arr[1] === value) {
            return arr[0];
          } else {
            return false;
          }
        }

        const thisRoom = employeeList.findIndex((emp) => emp.roomId == room.id);
        if (thisRoom != -1) {
          if (thisRoom != index) moveItem(thisRoom, index);
        } else {
          const userIds = getOtherValue(
            room.data().userIds,
            currentUserDetail?.firebaseUID
          );
          if (userIds) {
            const chatUser = await getDoc(doc(db, "users", userIds));
            if (chatUser.data()) {
              setEmployeeList((dt) => {
                const updatedItems = [...dt];
                updatedItems.splice(index, 0, {
                  ...chatUser.data(),
                  roomId: room.id,
                  id: chatUser.id,
                });
                return updatedItems;
              });
            }
          }
        }
      });
    } catch (err) {
      console.log(t(err.message));
    }
    LoaderHide();
  };

  useEffect(() => {
    if (selectedUser?.roomId) {
      // if (unsubscribe) unsubscribe();
      // getMessages();
      const chatRef = collection(db, "rooms", selectedUser.roomId, "messages");
      const q = query(chatRef, orderBy("createdAt"));
      // const snp = await getDocs(q);
      // const data = snp.docs.map((doc) => ({
      //   id: doc.id,
      //   ...doc.data(),
      // }));
      // setMessages(data);
      const unsubscrib = onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(
          data.map((ele) => {
            if (
              ele?.status == "sent" &&
              ele.authorId != currentUserDetail?.firebaseUID
            ) {
              setDoc(
                doc(db, "rooms", selectedUser.roomId, "messages", ele.id),
                {
                  status: "seen",
                },
                { merge: true }
              );
            }
            if (ele?.text) ele.text = decryptText(ele.text);
            return ele;
          })
        );
        setUnSeenMessages((dt) => ({ ...dt, [selectedUser.roomId]: 0 }));
      });
      return () => unsubscrib();
    }
  }, [selectedUser]);

  const getMessages = async () => {
    const chatRef = collection(db, "rooms", selectedUser.roomId, "messages");
    const q = query(chatRef, orderBy("createdAt"));
    // const snp = await getDocs(q);
    // const data = snp.docs.map((doc) => ({
    //   id: doc.id,
    //   ...doc.data(),
    // }));
    // setMessages(data);
    const unsubscrib = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(data);
      setUnsubscribe(unsubscrib);
    });
  };

  const getUserDetails = async () => {
    LoaderShow();
    try {
      LoaderShow();
      const token = GetUserDetails().token;

      const userResponse = await getUserDetailsFromAPI();
      if (userResponse.statusCode == 200) {
        setCurrentUserDetail(userResponse.data);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
    LoaderHide();
  };

  useEffect(() => {
    // Scroll to bottom after messages are updated
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async () => {
    const timestamp = Timestamp.fromDate(new Date());

    if (inputRef?.current?.value.trim() !== "") {
      let messageData = {
        updatedAt: serverTimestamp(),
        authorId: currentUserDetail.firebaseUID,
        createdAt: timestamp,
        type: "text",
        status: "sent",
      };

      if (inputRef?.current?.value.trim() !== "") {
        messageData.text = encryptText(inputRef?.current?.value);
      }
      setDoc(
        doc(db, "rooms", selectedUser.roomId),
        {
          updatedAt: Timestamp.now(),
        },
        { merge: true }
      );
      await addDoc(
        collection(db, "rooms", selectedUser.roomId, "messages"),
        messageData
      );
      sendNotif();
      inputRef.current.value = ""; // Clear the input field
    }
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return ""; // Handle null or undefined case

    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format hours and minutes (add leading zeros if needed)
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };

  const [search, setSearch] = useState("");
  const [timeoutId, setTimeoutId] = useState(-1);

  const handleSearch = async (ev) => {
    setTimeoutId(
      setTimeout(() => {
        if (timeoutId) clearTimeout(timeoutId);
        setSearch(ev.target.value);
      }, 500)
    );
  };

  const searchedEmployeeList = useMemo(() => {
    const empList = employeeList.filter((ele) =>
      rooms.map((room) => room.id).includes(ele.roomId)
    );
    if (search) {
      const lowerSearch = search.toLocaleLowerCase();
      return empList.filter(
        (ele) =>
          ele?.firstName?.toLocaleLowerCase()?.includes(lowerSearch) ||
          ele?.lastName?.toLocaleLowerCase()?.includes(lowerSearch)
      );
    }
    return empList;
  }, [search, employeeList]);

  const downloadfile = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <section className="messageEmployee">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{}}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("Message")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>
      <Box>
        <Grid className="flexColumnRespo" container spacing={2} sx={{ py: 5 }}>
          <Grid item xs={12} md={5} xl={4} lg={5}>
            <Item sx={{ p: 2, minHeight: "calc(100dvh - 261px)" }}>
              <Box>
                <div style={{ display: "flex" }} className="search">
                  <span className="fa fa-search">
                    <img src={Search} alt="search" />
                  </span>
                  <input
                    style={{ flexGrow: "1" }}
                    placeholder={t("SearchText")}
                    onChange={handleSearch}
                  />
                </div>
              </Box>
              {/* <Box>
                <InputBase
                  id="search-query"
                  placeholder={"Search"}
                  classes={{ input: "noFocusBorder" }}
                  onChange={(e) => setQuerys(e.target.value)}
                  onKeyUp={onSearchKeyDown}
                  sx={{
                    FontFace: "inherit",
                    backgroundColor: "#fff",
                    border: 0,
                    borderRadius: 5,
                    padding: "8px 12px",
                    width: "400px",
                  }}
                />
                <Button
                  sx={{
                    bgcolor: "#0a3114",
                    fontSize: 16,
                    color: "#fff",
                    borderRadius: 10,
                    px: 5,
                    py: 1,
                    ml: 2,
                    mb: 3,
                    "&:hover": {
                      bgcolor: "#0a3114",
                    },
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Box> */}
              <Box
                sx={{
                  py: 2,
                  maxHeight: "calc(100vh - 200px)", // Set a max height for the chat list container
                  overflowY: "auto", // Enable vertical scrolling
                }}
                className="chat-list-scrollbar"
              >
                {searchedEmployeeList?.length > 0 ? (
                  searchedEmployeeList?.map((chat, index) => (
                    <Box
                      key={chat.roomId}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        py: 1,
                        px: 0,
                        cursor: "pointer",
                      }}
                      className=""
                      onClick={(ev) => setSelectedUser(chat)}
                    >
                      <Box sx={{ display: "flex" }}>
                        <div className="">
                          <Avatar src={chat?.imageUrl} />
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "start",
                          px: 3,
                        }}
                      >
                        <div className="">
                          <Typography
                            variant="h7"
                            sx={{
                              color: "#222",
                              fontWeight: "500",
                              fontSize: 16,
                            }}
                          >
                            {chat.firstName}
                          </Typography>
                        </div>
                        <div className="">
                          <Typography
                            variant="h7"
                            sx={{
                              color: "#6B7177",
                              fontWeight: "400",
                              fontSize: 14,
                            }}
                          >
                            {t("Employer")}
                          </Typography>
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "auto",
                          alignItems: "end",
                        }}
                      >
                        <div className="">
                          <Typography variant="h7">
                            {chat.lastActive}
                          </Typography>
                        </div>
                        {unseenMessage[chat.roomId] > 0 && (
                          <Box
                            sx={{
                              bgcolor: "#0B3011",
                              color: "#fff",
                              mt: 0.5,
                              width: 20,
                              height: 20,
                              borderRadius: 50,
                              fontSize: 12,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                          >
                            <span>{unseenMessage[chat.roomId]}</span>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <p>{t("noChatFound")}</p>
                )}
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} xl={8} lg={7} md={7}>
            <Item
              sx={{
                minHeight: "calc(100dvh - 245px)",
                maxHeight: "calc(100dvh - 245px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 2,
                    px: 1,
                    borderBottom: 1,
                    borderColor: "#E9E9E9",
                  }}
                  className=""
                >
                  <Box sx={{ display: "flex" }}>
                    <div className="">
                      <Avatar src={selectedUser?.imageUrl} />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      px: 1,
                    }}
                  >
                    <div className="">
                      <Typography
                        variant="h7"
                        sx={{ color: "#222", fontWeight: "500", fontSize: 16 }}
                      >
                        {selectedUser.firstName}
                      </Typography>
                    </div>
                    {/* <div className="">
                      <Typography variant="h7">
                        {isActive ? ( // Assuming isActive is a boolean indicating active status
                          <span
                            style={{
                              color: "green",
                              marginRight: "4px",
                              fontSize: 20,
                            }}
                          >
                            ●
                          </span>
                        ) : null}
                        Active
                      </Typography>
                    </div> */}
                  </Box>

                  {/* <Box
                    sx={{
                      display: "flex",
                      marginLeft: "auto",
                      alignItems: "center",
                      backgroundColor: "#D20000",
                      borderRadius: 5,
                      px: 2,
                      cursor: "pointer",
                    }}
                  >
                    <IconButton sx={{ px: 0 }}>
                      <DeleteIcon sx={{ color: "#fff" }} />{" "}
                    </IconButton>
                    <div className="">
                      <Typography variant="h7" sx={{ color: "#fff" }}>
                        Delete Chat
                      </Typography>{" "}
                    </div>
                  </Box> */}
                </Box>
              </Box>
              <Box
                ref={chatContainerRef}
                sx={{
                  py: 3,
                  overflowY: "auto",
                  flexGrow: "1",
                }}
              >
                {/* reciever */}

                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 1,
                  }}
                  className="senderDiv"
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ display: "flex" }}>
                        <div className="">
                          <img src={ChatAvatar3} alt="search" />
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "start",
                          px: 3,
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className=""
                        >
                          <Typography variant="h7" sx={{ color: "#222", fontWeight: '500', fontSize: 16 }}>
                            Albert Flores{" "}
                          </Typography>
                          <Typography variant="h7" sx={{ px: 2 }}>
                            35 mins
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#94C2ED",
                        color: "#fff",
                        p: 2,
                        my: 2,
                        borderRadius: 3,
                        width: "50%",
                      }}
                    >
                      <Typography
                        variant="h7"
                        sx={{
                          color: "#fff",
                          fontSize: 15,
                          overflowWrap: "break-word",
                          wordBreak: "break-word",
                          textAlign: "justify",
                          display: "block",
                        }}
                      >
                        How likely are you to recommend our company to your
                        friends and family? How likely are you to recommend our
                        company to your friends and family?
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}

                {/* end of reciever */}

                {/* Sender */}

                {messages.map((msg, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      px: 1,
                      justifyContent: `${
                        msg?.authorId === currentUserDetail?.firebaseUID
                          ? "flex-end"
                          : "flex-start"
                      }`, // Align the receiver div to the right
                    }}
                    className="receiverDiv" // Add a className for styling purposes
                  >
                    <Box
                      sx={{
                        maxWidth: "70%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems:
                          msg?.authorId === currentUserDetail?.firebaseUID
                            ? "end"
                            : "start",
                      }}
                    >
                      {!messages[index - 1] ||
                      messages[index - 1]?.authorId != msg?.authorId ||
                      index == 0 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection:
                              msg?.authorId === currentUserDetail?.firebaseUID
                                ? "row"
                                : "row-reverse",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection:
                                msg?.authorId === currentUserDetail?.firebaseUID
                                  ? "row"
                                  : "row-reverse",
                              justifyContent: "start",
                              alignItems: "start",
                              px: 3,
                            }}
                          >
                            <Typography variant="h7" sx={{ px: 2 }}>
                              {formatTime(msg?.createdAt)}
                            </Typography>
                            <Typography
                              variant="h7"
                              sx={{
                                color: "#222",
                                fontWeight: "500",
                                fontSize: 16,
                              }}
                            >
                              {msg?.authorId === currentUserDetail?.firebaseUID
                                ? "You"
                                : selectedUser?.firstName}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            {msg?.authorId ===
                            currentUserDetail?.firebaseUID ? (
                              <div className="">
                                <Avatar
                                  src={currentUserDetail?.profileImageUrl}
                                />
                              </div>
                            ) : (
                              <div className="">
                                <Avatar src={selectedUser?.imageUrl} />
                              </div>
                            )}
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box
                        sx={{
                          bgcolor: `${
                            msg?.authorId === currentUserDetail.firebaseUID
                              ? "#86BB71"
                              : "#94C2ED"
                          }`,
                          color: "#fff",
                          p: 2,
                          my:
                            !messages[index - 1] ||
                            messages[index - 1]?.authorId !== msg?.authorId
                              ? 2
                              : 1,
                          borderRadius: 3,
                        }}
                      >
                        <Typography
                          variant="h7"
                          sx={{
                            color: "#fff",
                            fontSize: 15,
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                            textAlign:
                              msg?.authorId === currentUserDetail.firebaseUID
                                ? "right"
                                : "left",
                            display: "block",
                          }}
                        >
                          <div
                            key={msg?.id}
                            className={`message ${
                              msg?.authorId === currentUserDetail.firebaseUID
                                ? "sender"
                                : "receiver"
                            }`}
                          >
                            {msg?.uri ? (
                              <div>
                                {msg?.type == "image" ? (
                                  <img
                                    onClick={(ev) => downloadfile(msg?.uri)}
                                    style={{ width: "100%", cursor: "pointer" }}
                                    src={msg?.uri}
                                    alt={msg?.name}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      gap: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(ev) => downloadfile(msg?.uri)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 100 100"
                                      width="90px"
                                      height="90px"
                                    >
                                      <path
                                        fill="#fefdef"
                                        d="M29.614,12.307h-1.268c-4.803,0-8.732,3.93-8.732,8.732v61.535c0,4.803,3.93,8.732,8.732,8.732h43.535c4.803,0,8.732-3.93,8.732-8.732v-50.02C72.74,24.68,68.241,20.182,60.367,12.307H41.614"
                                      />
                                      <path
                                        fill="#1f212b"
                                        d="M71.882,92.307H28.347c-5.367,0-9.732-4.366-9.732-9.732V21.04c0-5.367,4.366-9.732,9.732-9.732h1.268c0.552,0,1,0.448,1,1s-0.448,1-1,1h-1.268c-4.264,0-7.732,3.469-7.732,7.732v61.535c0,4.264,3.469,7.732,7.732,7.732h43.535c4.264,0,7.732-3.469,7.732-7.732V32.969L59.953,13.307H41.614c-0.552,0-1-0.448-1-1s0.448-1,1-1h18.752c0.265,0,0.52,0.105,0.707,0.293l20.248,20.248c0.188,0.188,0.293,0.442,0.293,0.707v50.02C81.614,87.941,77.248,92.307,71.882,92.307z"
                                      />
                                      <path
                                        fill="#fef6aa"
                                        d="M60.114,12.807v10.986c0,4.958,4.057,9.014,9.014,9.014h11.986"
                                      />
                                      <path
                                        fill="#1f212b"
                                        d="M81.114 33.307H69.129c-5.247 0-9.515-4.268-9.515-9.515V12.807c0-.276.224-.5.5-.5s.5.224.5.5v10.985c0 4.695 3.82 8.515 8.515 8.515h11.985c.276 0 .5.224.5.5S81.391 33.307 81.114 33.307zM75.114 51.307c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3C75.614 51.083 75.391 51.307 75.114 51.307zM75.114 59.307c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5s.5.224.5.5v6C75.614 59.083 75.391 59.307 75.114 59.307zM67.956 86.307H32.272c-4.223 0-7.658-3.45-7.658-7.689V25.955c0-2.549 1.264-4.931 3.382-6.371.228-.156.54-.095.695.132.155.229.096.54-.132.695-1.844 1.254-2.944 3.326-2.944 5.544v52.663c0 3.688 2.987 6.689 6.658 6.689h35.685c3.671 0 6.658-3.001 6.658-6.689V60.807c0-.276.224-.5.5-.5s.5.224.5.5v17.811C75.614 82.857 72.179 86.307 67.956 86.307z"
                                      />
                                      <path
                                        fill="#1f212b"
                                        d="M39.802 14.307l-.117 11.834c0 2.21-2.085 3.666-4.036 3.666-1.951 0-4.217-1.439-4.217-3.649l.037-12.58c0-1.307 1.607-2.451 2.801-2.451 1.194 0 2.345 1.149 2.345 2.456l.021 10.829c0 0-.083.667-1.005.645-.507-.012-1.145-.356-1.016-.906v-9.843h-.813l-.021 9.708c0 1.38.54 1.948 1.875 1.948s1.959-.714 1.959-2.094V13.665c0-2.271-1.36-3.5-3.436-3.5s-3.564 1.261-3.564 3.532l.032 12.11c0 3.04 2.123 4.906 4.968 4.906 2.845 0 5-1.71 5-4.75V14.307H39.802zM53.114 52.307h-23c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h23c.276 0 .5.224.5.5S53.391 52.307 53.114 52.307zM44.114 59.307h-14c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h14c.276 0 .5.224.5.5S44.391 59.307 44.114 59.307zM70.114 59.307h-24c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h24c.276 0 .5.224.5.5S70.391 59.307 70.114 59.307zM61.114 66.307h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5S61.391 66.307 61.114 66.307zM71.114 66.307h-8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5S71.391 66.307 71.114 66.307zM48.114 66.307h-18c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h18c.276 0 .5.224.5.5S48.391 66.307 48.114 66.307zM70.114 73.307h-13c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h13c.276 0 .5.224.5.5S70.391 73.307 70.114 73.307zM54.114 73.307h-24c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h24c.276 0 .5.224.5.5S54.391 73.307 54.114 73.307z"
                                      />
                                    </svg>
                                    <div style={{ textAlign: "center" }}>
                                      {msg?.name}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              msg?.text && <div>{msg?.text}</div>
                            )}
                          </div>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}

                {/* End Of Sender */}

                {/* reciever */}
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 1,
                  }}
                  className="senderDiv"
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ display: "flex" }}>
                        <div className="">
                          <img src={ChatAvatar3} alt="search" />
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "start",
                          px: 3,
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className=""
                        >
                          <Typography variant="h7" sx={{ color: "#222", fontWeight: '500', fontSize: 16 }}>
                            Albert Flores{" "}
                          </Typography>
                          <Typography variant="h7" sx={{ px: 2 }}>
                            35 mins
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#94C2ED",
                        color: "#fff",
                        p: 2,
                        my: 2,
                        borderRadius: 3,
                        width: "50%",
                      }}
                    >
                      <Typography
                        variant="h7"
                        sx={{
                          color: "#fff",
                          fontSize: 15,
                          overflowWrap: "break-word",
                          wordBreak: "break-word",
                          textAlign: "left",
                          display: "block",
                        }}
                      >
                        How likely are you to recommend our company to your
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
                {/* end of reciever */}
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 2,
                    px: 1,
                    borderTop: 1,
                    borderColor: "#E9E9E9",
                  }}
                  className=""
                >
                  <div className="chat-searchbox-wrap">
                    <input
                      ref={inputRef}
                      className="messageText"
                      type="text"
                      placeholder={t("Type")}
                      disabled={!selectedUser.roomId}
                      style={{ background: "#F7F7F7" }}
                      // value={inputMessage}
                      // onChange={(e) => setInputMessage(e.target.value)}
                    />
                    <AttachFileIcon
                      onClick={(ev) => fileRef?.current?.click()}
                      sx={{ px: 3, cursor: "pointer" }}
                      disabled={!selectedUser.roomId}
                    />
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.gif,.pdf,.xlsx,.docx,.zip,.mp4"
                      style={{ display: "none" }}
                      disabled={!selectedUser.roomId}
                      ref={fileRef}
                      onChange={handleFileChange}
                    />
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={() => sendMessage()}
                      disabled={!selectedUser.roomId}
                      className="zzzz"
                    >
                      <span>
                        {t("Send")}
                        <img
                          className="imageSend"
                          src={arowCross}
                          alt="search"
                        />
                      </span>
                    </button>
                  </div>
                </Box>
              </Box>

              {/* Text Input Box */}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
}
