import { Box, Button, Radio, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import Paypal from "../../../Assets/Images/Paypal.png";
import Stripe from "../../../Assets/Images/Stripe.svg";
import Crypto from "../../../Assets/Images/Crypto.png";
import { loadStripe } from "@stripe/stripe-js";
import CoinPayments from "../../Home/HomeMembership/CoinPayments/CoinPayments";
import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";

export default function Payment({
  walletBalance,
  subTotal,
  title,
  awardProject,
  isSomePaid,
  employeeId,
  milestoneId,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [paymentType, setPaymentType] = useState("Stripe");

  const [openCoinPaymentDialog, setOpenCoinPaymentDialog] = useState(false);
  const [cryptoDetails, setCryptoDetails] = useState({});

  useEffect(() => {
    getUserDetails();
    fetchData();
  }, []);

  const getUserDetails = async () => {
    try {
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setPaymentType(response?.data?.defaultPaymentMethod || "Stripe");
      }
    } catch (error) {
      // toast.error(error.message)
    }
  };

  const fetchData = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData(res.data.find((ele) => ele.type == "employer"));
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
  };

  const amtToBePaid = useMemo(() => {
    return walletBalance - subTotal;
  });
  const calculatedFees = useMemo(() => {
    switch (paymentType) {
      case "Crypto":
        if (Math.abs(amtToBePaid) < data?.cryptoAmount)
          return (Math.abs(amtToBePaid) * data?.cryptoMinPercentage) / 100;
        return (Math.abs(amtToBePaid) * data?.cryptoPercentage) / 100;
      case "Stripe":
        if (Math.abs(amtToBePaid) < data?.stripeAmount)
          return (Math.abs(amtToBePaid) * data?.stripeMinPercentage) / 100;
        return (Math.abs(amtToBePaid) * data?.stripePercentage) / 100;
      case "Paypal":
        if (Math.abs(amtToBePaid) < data?.paypalAmount)
          return (Math.abs(amtToBePaid) * data?.paypalMinPercentage) / 100;
        return (Math.abs(amtToBePaid) * data?.paypalPercentage) / 100;
      default:
        return 0;
    }
  }, [data, amtToBePaid, paymentType]);

  const estimatedTotal = useMemo(() => {
    return parseFloat(
      parseFloat(Math.abs(amtToBePaid)) + parseFloat(calculatedFees)
    );
  }, [calculatedFees, data, amtToBePaid]);

  const controlProps = (item) => ({
    checked: paymentType === item,
    onChange: (ev) => setPaymentType(ev?.target?.value),
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  /* ============= PAYMENT ===================*/

  const initiateStripePayment = async () => {
    try {
      LoaderShow();
      const stripe = await loadStripe(
        `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
      );

      const response = await fetch(
        "https://api.stripe.com/v1/checkout/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECERT_KEY}`,
          },
          body: new URLSearchParams({
            "payment_method_types[]": "card",
            "line_items[0][price_data][currency]": "usd",
            "line_items[0][price_data][product_data][name]":
              "Milestone Payment",
            "line_items[0][price_data][unit_amount]": Math.trunc(
              estimatedTotal.toFixed(2) * 100
            ), // Stripe expects the amount in cents
            "line_items[0][quantity]": "1",
            mode: "payment",
            success_url: `${
              window.location.origin
            }/dashboard/employer/myprojects?payment_intent={CHECKOUT_SESSION_ID}&user_id=${
              employeeId._id
            }&email=${
              employeeId.email
            }&isNew=false&milestone_id=${milestoneId.toLocaleString()}&amt=${subTotal}&paidAmt=${Math.abs(
              amtToBePaid
            )}&total_fee=${calculatedFees}`,
            cancel_url: `${window.location.origin}`,
            // 'success_url': `http://localhost:3000/sendanoffer?payment_intent={CHECKOUT_SESSION_ID}`,
            // 'cancel_url': `http://localhost:3000/sendanoffer`,
          }).toString(),
        }
      );

      const session = await response.json();

      if (session.error) {
        throw new Error(session.error.message);
      }
      LoaderHide();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        toast.error(result.error.message);
      }
    } catch (error) {
      LoaderHide();
      console.error("Error initiating Stripe payment:", error);
    }
  };

  const initiateCryptoPayment = async () => {
    LoaderShow();
    const projectDetails =
      JSON.parse(localStorage.getItem("projectData")) || "";
    try {
      const { token, userId } = GetUserDetails();
      const data = {
        projectId: projectDetails?._id ? projectDetails?._id : "",
        // currency: "LTCT",
        employerId: userId,
        employeeId: employeeId?._id ? employeeId?._id : employeeId,
        milestones: milestoneId,
        amount: estimatedTotal,
      };
      const response = await postAPI("coinpayment/create-payment", data, token);
      if (response?.payment?.data) {
        setCryptoDetails(response?.payment?.data);
        setOpenCoinPaymentDialog(true);
      }
    } catch (error) {
      console.error("Error initiating Stripe payment:", error);
    }
    LoaderHide();
  };

  const deductFromWallet = async () => {
    LoaderShow();
    const projectDetails =
      JSON.parse(localStorage.getItem("projectData")) || "";
    try {
      const dataToSend = {
        amount: subTotal,
        projectId: projectDetails?._id ? projectDetails?._id : "",
        peyeeId: projectDetails?.user._id ? projectDetails?.user._id : "",
        milestoneIds: milestoneId,
        totalFee: 0,
      };
      const token = GetUserDetails()?.token;
      const id = GetUserDetails()?.userId;

      const data = {
        email: employeeId?.email,
        projectId: projectDetails?._id,
        employeeId: employeeId?._id,
        milestoneIds: milestoneId,
      };

      const response = await postAPI("transection/create", dataToSend, token);
      if (response.statusCode === 200) {
        try {
          const response = await postAPI(
            "invoice/create",
            {
              ...dataToSend,
              type: "employer",
              payeeId: dataToSend.peyeeId,
              payerId: id,
              currency: "USD",
              milestoneId: dataToSend.milestoneIds[0],
              paymentMethod: "PayPal Payment",
            },
            token
          );
        } catch (err) {}
        await postAPI("award/projects/award", data, token);
        toast.success(t("toast47"));
        navigate("/dashboard/employer/myprojects");
      }
    } catch (error) {
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const handlePayment = () => {
    if (subTotal > 0) {
      if (amtToBePaid >= 0) {
        deductFromWallet();
      } else {
        switch (paymentType) {
          case "Stripe":
            initiateStripePayment();
            break;
          case "Crypto":
            initiateCryptoPayment();
            break;
        }
      }
    } else {
      toast.error(t("toast48"));
    }
  };

  const createTransaction = async (details) => {
    LoaderShow();
    if (details.message === "completed") {
      // Handle successful transaction here
      setOpenCoinPaymentDialog(false);
      const projectDetails =
        JSON.parse(localStorage.getItem("projectData")) || "";
      try {
        const dataToSend = {
          amount: subTotal,
          remainingAmount: Math.abs(amtToBePaid),
          method: "Crypto Payment",
          peyeeId: projectDetails?.user._id ? projectDetails?.user._id : "",
          transectionId: details?.id,
          milestoneIds: milestoneId,
          totalFee: calculatedFees,
        };
        const token = GetUserDetails()?.token;
        const id = GetUserDetails()?.userId;

        const data = {
          email: employeeId?.email,
          projectId: projectDetails?._id,
          employeeId: employeeId?._id,
          milestoneIds: milestoneId,
          totalFee: calculatedFees,
        };

        const response = await postAPI("transection/create", dataToSend, token);
        if (response.statusCode === 200) {
          try {
            const response = await postAPI(
              "invoice/create",
              {
                ...dataToSend,
                type: "employer",
                payeeId: dataToSend.peyeeId,
                payerId: id,
                currency: "USD",
                milestoneId: dataToSend.milestoneIds[0],
                paymentMethod: "Crypto Payment",
              },
              token
            );
          } catch (err) {}
          // navigate("/dashboard/employer/myprojects");
          await postAPI("award/projects/award", data, token);
          toast.success(t("toast30"));
          window.location.replace("/dashboard/employer/myprojects");
        }
      } catch (error) {
        // toast.error(error.message)
      }
    }
    LoaderHide();
  };

  return (
    <>
      {openCoinPaymentDialog && (
        <CoinPayments
          open={openCoinPaymentDialog}
          onClose={() => setOpenCoinPaymentDialog(false)}
          coinsPayment={cryptoDetails}
          onComplete={createTransaction}
        />
      )}
      <Box
        sx={{
          backgroundColor: "#fff",
          border: 1,
          my: 4,
          mt: 0,
          p: 3,
          py: 2,
          borderRadius: 10,
          borderColor: "#e3e3e3",
          pointerEvents: amtToBePaid < 0 ? "all" : "none",
          opacity: amtToBePaid < 0 ? 1 : 0.6,
        }}
      >
        <Box sx={{ py: 1.5, borderBottom: 1, borderColor: "#d6d6d6" }}>
          <Typography variant="h6" sx={{ fontWeight: 500, color: "#000" }}>
            {t("Payment")}
          </Typography>
        </Box>
        <Box
          sx={{
            py: 1,
            border: 1,
            my: 2,
            mt: 4,
            borderColor: "#E6E6E6",
            borderRadius: 2,
          }}
        >
          <Box
            className="alignBaselineRsp flexColumnRespo"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Radio
                {...controlProps("Paypal")}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: "#0B3013",
                    m: 0,
                    p: 0,
                  },
                }}
              />
              <img src={Paypal} alt="Paypal" />
            </Box>
            <Typography sx={{ px: 2, fontSize: "12px" }}>
              {t("paypalPaymentDes")}{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            py: 1,
            border: 1,
            my: 2,
            borderColor: "#E6E6E6",
            borderRadius: 2,
          }}
        >
          <Box
            className="alignBaselineRsp flexColumnRespo"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "50px",
              }}
            >
              <Radio
                {...controlProps("Stripe")}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: "#0B3013",
                  },
                }}
              />
              <img
                src={Stripe}
                alt="Stripe"
                style={{
                  width: "auto",
                  height: "auto",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
            </Box>
            <Typography sx={{ px: 2, fontSize: "12px" }}>
              {t("stripePaymentDes")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            py: 1,
            border: 1,
            my: 2,
            borderColor: "#E6E6E6",
            borderRadius: 2,
          }}
        >
          <Box
            className="alignBaselineRsp flexColumnRespo"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Radio
                {...controlProps("Crypto")}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: "#0B3013",
                  },
                }}
              />
              <img src={Crypto} alt="Crypto" />
            </Box>
            <Typography sx={{ px: 2, fontSize: "12px" }}>
              {t("cryptoPaymentDes")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          border: 1,
          my: 4,
          mt: 0,
          p: 3,
          py: 2,
          borderRadius: 10,
          borderColor: "#e3e3e3",
        }}
      >
        <Box sx={{ py: 1.5, borderBottom: 1, borderColor: "#d6d6d6" }}>
          <Typography variant="h6" sx={{ fontWeight: 500, color: "#000" }}>
            {t("hireFor")} : {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pb: 1.5,
            pt: 2,
            borderBottom: "1px solid #cac8c8",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            {t("walletBal")}
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            ${walletBalance.toFixed(2)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 2,
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            {t("mileFee")}
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            ${subTotal.toFixed(2)}
          </Typography>
        </Box>
        {amtToBePaid < 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pb: 2,
                borderColor: "#d6d6d6",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
              >
                {t("amtPaidWithoutFees")}
              </Typography>
              <Typography
                variant="p"
                sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
              >
                ${Math.abs(amtToBePaid).toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pb: 2,
                borderColor: "#d6d6d6",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
              >
                {t("marketPlaceFee")}
              </Typography>
              <Typography
                variant="p"
                sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
              >
                {calculatedFees ? "$" + calculatedFees.toFixed(2) : "$0"}
              </Typography>
            </Box>
          </>
        ) : (
          ""
        )}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 0,
            pb: 3,
            borderBottom: 1,
            borderColor: "#d6d6d6",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            Flat fee
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            {subTotal ? "$" + data.flatFee : "$0"}
          </Typography>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: 2,
            pb: amtToBePaid < 0 ? 0 : 2,
            borderTop: "1px solid #cac8c8",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            {t("newWalletBal")}
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            ${amtToBePaid < 0 ? 0 : amtToBePaid.toFixed(2)}
          </Typography>
        </Box>
        {amtToBePaid < 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 2,
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
            >
              {t("amtPaidWithfees")}
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
            >
              {subTotal === 0 ? "$0" : "$" + estimatedTotal.toFixed(2)}
            </Typography>
          </Box>
        )}
        {amtToBePaid < 0 ? (
          <div>
            {paymentType != "Paypal" ? (
              <Button
                fullWidth={true}
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "inherit",
                  fontSize: 16,
                  backgroundColor: "#0B3013",
                  color: "#fff",
                  py: 1.2,
                  my: 2,
                  px: 3,
                  borderRadius: 20,
                  "&:hover": {
                    bgcolor: "#0a3114",
                    color: "#fff",
                  },
                }}
                onClick={handlePayment}
              >
                {t("pay")}
              </Button>
            ) : (
              <div
                style={{
                  position: "relative",
                  marginBottom: "16px",
                  marginTop: "16px",
                  //   overflow: "hidden",
                }}
              >
                <Button
                  fullWidth={true}
                  style={{
                    pointerEvents: "none",
                  }}
                  sx={{
                    position: "relative",
                    zIndex: 10,
                    textTransform: "capitalize",
                    fontFamily: "inherit",
                    fontSize: 16,
                    backgroundColor: "#0B3013",
                    color: "#fff",
                    py: 1.2,
                    px: 3,
                    borderRadius: 20,
                    "&:hover": {
                      bgcolor: "#0a3114",
                      color: "#fff",
                    },
                  }}
                  onClick={handlePayment}
                >
                  {t("pay")}
                </Button>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    borderRadius: "48px",
                    overflow: "hidden",
                    zIndex: 8,
                  }}
                >
                  <PayPalScriptProvider
                    options={{
                      "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY,
                      currency: "USD",
                    }}
                  >
                    <PayPalButtons
                      fundingSource={FUNDING.PAYPAL}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: estimatedTotal,
                              },
                            },
                          ],
                          application_context: {
                            // Disable shipping address collection
                            shipping_preference: "NO_SHIPPING",
                          },
                        });
                      }}
                      onApprove={async (data, actions) => {
                        const projectDetails =
                          JSON.parse(localStorage.getItem("projectData")) || "";
                        const details = await actions.order.capture();
                        console.log("details paypal=== --- ", details);
                        if (details.status === "COMPLETED") {
                          // Handle successful transaction here
                          try {
                            const dataToSend = {
                              amount: subTotal,
                              remainingAmount: Math.abs(amtToBePaid),
                              projectId: projectDetails?._id
                                ? projectDetails?._id
                                : "",
                              method: "PayPal Payment",
                              peyeeId: projectDetails?.user._id
                                ? projectDetails?.user._id
                                : "",
                              transectionId: details?.id,
                              milestoneIds: milestoneId,
                              totalFee: calculatedFees,
                            };
                            const token = GetUserDetails()?.token;
                            const id = GetUserDetails()?.userId;

                            const data = {
                              email: employeeId?.email,
                              projectId: projectDetails?._id,
                              employeeId: employeeId?._id,
                              milestoneIds: milestoneId,
                            };

                            const response = await postAPI(
                              "transection/create",
                              dataToSend,
                              token
                            );
                            if (response.statusCode === 200) {
                              try {
                                const response = await postAPI(
                                  "invoice/create",
                                  {
                                    ...dataToSend,
                                    type: "employer",
                                    payeeId: dataToSend.peyeeId,
                                    payerId: id,
                                    currency: "USD",
                                    milestoneId: dataToSend.milestoneIds[0],
                                    paymentMethod: "PayPal Payment",
                                  },
                                  token
                                );
                                await postAPI(
                                  "award/projects/award",
                                  data,
                                  token
                                );
                              } catch (err) {}
                            }
                            navigate("/dashboard/employer/myprojects");
                          } catch (error) {
                            // toast.error(error.message)
                          }
                        }
                      }}
                    />
                  </PayPalScriptProvider>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Button
            fullWidth={true}
            sx={{
              textTransform: "capitalize",
              fontFamily: "inherit",
              fontSize: 16,
              backgroundColor: "#0B3013",
              color: "#fff",
              py: 1,
              my: 2,
              px: 3,
              borderRadius: 20,
              "&:hover": {
                bgcolor: "#0a3114",
                color: "#fff",
              },
            }}
            onClick={handlePayment}
          >
            {t("payfrmWallet")} ${subTotal.toFixed(2)}
          </Button>
        )}
        {isSomePaid && (
          <Button
            fullWidth={true}
            sx={{
              textTransform: "capitalize",
              fontFamily: "inherit",
              fontSize: 16,
              backgroundColor: "#0B3013",
              color: "#fff",
              py: 1,
              my: 2,
              px: 3,
              borderRadius: 20,
              "&:hover": {
                bgcolor: "#0a3114",
                color: "#fff",
              },
            }}
            onClick={awardProject}
          >
            Award
          </Button>
        )}
      </Box>
    </>
  );
}
