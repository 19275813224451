import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import Paypal from "../../Assets/Images/Paypal.png";
import Stripe from "../../Assets/Images/Stripe.svg";
import Crypto from "../../Assets/Images/Crypto.png";
import { loadStripe } from "@stripe/stripe-js";
import loader from "../../Assets/Images/loader.svg";
import { getAPI, postAPI } from "../../Services/Api";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { Form, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import CoinPayments from "../../Pages/Home/HomeMembership/CoinPayments/CoinPayments";
import { EMPLOYER_ROLE } from "../../Helpers/roles";
// import CoinPayments from "../../HomeMembership/CoinPayments/CoinPayments";
import close from "../../Assets/Images/cancel-circle-half-dot.png";

const DisputeBalanceAdd = ({
  open,
  onClose,
  afterPaid = () => {},
  balance,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  //   const [balance, setBalance] = useState(10);
  //   const [showAmtInput, setShowAmtInput] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState("Stripe");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [milestone, setMilestone] = useState("");
  const [additionalTaxes] = useState(15); // Additional taxes amount
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCoinPaymentDialog, setOpenCoinPaymentDialog] = useState(false);
  const [cryptoDetails, setCryptoDetails] = useState({});
  const [openPayPal, setOpenPayPal] = useState(false);

  const [data, setData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = GetUserDetails()?.token;
      const roles = GetUserDetails()?.role;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData(
            res.data.find((ele) =>
              roles.includes(EMPLOYER_ROLE)
                ? ele.type == "employer"
                : ele.type == "employee"
            )
          );
        }
      }
    } catch (err) {
      //   console.log(t(err.message));
      //   toast.error(t(err.message));
    }
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  const calculatedFees = useMemo(() => {
    switch (selectedValue) {
      case "Crypto":
        if (balance < data?.cryptoAmount)
          return (balance * data?.cryptoMinPercentage) / 100;
        return (balance * data?.cryptoPercentage) / 100;
      case "Stripe":
        if (balance < data?.stripeAmount)
          return (balance * data?.stripeMinPercentage) / 100;
        return (balance * data?.stripePercentage) / 100;
      case "Paypal":
        if (balance < data?.paypalAmount)
          return (balance * data?.paypalMinPercentage) / 100;
        return (balance * data?.paypalPercentage) / 100;
      default:
        return 0;
    }
  }, [data, balance, selectedValue]);

  const estimatedTotal = useMemo(() => {
    return Number(balance) + Number(calculatedFees);
  }, [calculatedFees, data]);

  const projectDetails = JSON.parse(localStorage.getItem("projectData")) || "";

  useEffect(() => {
    if (searchParams.get("payment_intent")) {
      afterPayment();
    }
  }, []);

  const afterPayment = async () => {
    LoaderShow();
    const transactionId = searchParams.get("payment_intent");
    const bl = Number(searchParams.get("balance"));
    const fee = Number(searchParams.get("fee"));
    const user = GetUserDetails();
    searchParams.delete("payment_intent");
    searchParams.delete("balance");
    searchParams.delete("fee");
    setSearchParams(searchParams);

    const token = GetUserDetails()?.token;
    const id = GetUserDetails()?.userId;
    const dataToSend = {
      amount: Number(bl),
      transactionId: transactionId,
      fee: Number(fee),
      method: "Credit card",
    };

    const response = await postAPI("wallet/add", dataToSend, token);
    if (response.statusCode === 200) {
      await afterPaid();
    }
    try {
    } catch (err) {
      console.log(err);
      toast.error(t(err.message) || t("toast9"));
    }
    LoaderHide();
  };

  useEffect(() => {
    if (!open) {
      //   setShowAmtInput(true);
      setOpenPayPal(false);
      setOpenCoinPaymentDialog(false);
      //   setBalance(10);
    }
  }, [open]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const initiateStripePayment = async () => {
    setLoading(true);
    try {
      // const stripe = await loadStripe('pk_test_51LNAXqSHRoKsJ0YdCsPcfRlrqrZRufKJ51lnvg7lEEknm1tIQp08z8Fir9hzZgxPvm57OHaEtmxlBn9hurjwB7yc00U7it2i0i');
      // await stripe.redirectToCheckout({
      //   lineItems: [{ price: 'price_1P3ckKSHRoKsJ0YdWJZOTMyM', quantity: 1 }],
      //   mode: 'payment',
      //   successUrl: 'http://localhost:3000/sendanoffer',
      //   cancelUrl: 'http://localhost:3000/sendanoffer',
      // });

      const stripe = await loadStripe(
        `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
      );

      const response = await fetch(
        "https://api.stripe.com/v1/checkout/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECERT_KEY}`,
          },
          body: new URLSearchParams({
            "payment_method_types[]": "card",
            "line_items[0][price_data][currency]": "usd",
            "line_items[0][price_data][product_data][name]":
              "Milestone Payment",
            "line_items[0][price_data][unit_amount]": Math.trunc(
              parseFloat(estimatedTotal).toFixed(2) * 100
            ), // Stripe expects the amount in cents
            "line_items[0][quantity]": "1",
            mode: "payment",
            success_url: `${window.location.origin}/dashboard/${
              GetUserDetails()?.role.includes(EMPLOYER_ROLE)
                ? "employer"
                : "employee"
            }/dispute?payment_intent={CHECKOUT_SESSION_ID}&balance=${balance}&fee=${calculatedFees}`,
            cancel_url: `${window.location.origin}`,
            // success_url: `${"http://localhost:3000"}/dashboard/employer/bilingpayments?payment_intent={CHECKOUT_SESSION_ID}&balance=${balance}&fee=${calculatedFees}`,
            // 'cancel_url': `http://localhost:3000/sendanoffer`,
          }).toString(),
        }
      );

      const session = await response.json();

      if (session.error) {
        throw new Error(session.error.message);
      }
      LoaderHide();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      LoaderHide();
      console.error("Error initiating Stripe payment:", error);
    }
  };

  const initiateCryptoPayment = async () => {
    setLoading(true);
    LoaderShow();
    try {
      const { token, userId } = GetUserDetails();
      const data = {
        // currency: "LTCT",
        employeeId: userId,
        amount: estimatedTotal,
      };
      const response = await postAPI(
        "coinpayment/wallet-payment-add",
        data,
        token
      );
      if (response?.payment?.data) {
        setCryptoDetails(response?.payment?.data);
        setOpenCoinPaymentDialog(true);
      }
    } catch (error) {
      console.error("Error initiating Crypto payment:", error);
    }
    LoaderHide();
  };

  const closePayPalModal = () => {
    setOpenPayPal(false);
  };

  const handlePaymentSubmit = () => {
    LoaderShow();
    if (selectedValue === "Paypal") {
      setOpenPayPal(true);
      onClose();
      LoaderHide();
    } else if (selectedValue === "Stripe") {
      initiateStripePayment();
    } else if (selectedValue === "Crypto") {
      initiateCryptoPayment();
    }
  };

  const createTransaction = async (details) => {
    LoaderShow();
    if (details.message === "completed") {
      // Handle successful transaction here
      setOpenCoinPaymentDialog(false);
      try {
        const token = GetUserDetails().token;
        const userId = GetUserDetails().userId;

        const dataToSend = {
          amount: balance,
          transactionId: details?.id,
          fee: calculatedFees,
          method: "Crypto Payment",
        };
        const response = await postAPI("wallet/add", dataToSend, token);
        if (response.statusCode === 200) {
          toast.success(t("toast30"));
          window.location.reload();
        }
      } catch (error) {}
    }
    LoaderHide();
  };

  const nexPage = (ev) => {
    ev.preventDefault();
    // setShowAmtInput(false);
  };

  return (
    <>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      {openCoinPaymentDialog && (
        <CoinPayments
          open={openCoinPaymentDialog}
          onClose={() => setOpenCoinPaymentDialog(false)}
          coinsPayment={cryptoDetails}
          onComplete={createTransaction}
        />
      )}

      <>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="subscribe-modal-title"
          aria-describedby="subscribe-modal-description"
        >
          {/* {showAmtInput ? (
            <>
              <Box
                className="employeeProjdetails"
                sx={{
                  position: "absolute",
                  width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "white",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 5,
                }}
              >
                <Box className="SubHeader">
                  <Typography
                    variant="h5"
                    id="subscribe-modal-title"
                    sx={{
                      borderBottom: 1,
                      py: 2,
                      pt: 0,
                      borderColor: "#E6E6E6",
                    }}
                    gutterBottom
                  >
                    {t("Payment")}

                    <CloseIcon
                      sx={{ cursor: "pointer", float: "right" }}
                      onClick={onClose}
                    />
                  </Typography>
                </Box>
                <form onSubmit={(ev) => nexPage(ev)}>
                  <FormControl
                    sx={{
                      mt: 3,
                    }}
                    fullWidth
                  >
                    <FormLabel
                      sx={{
                        display: "block",
                        textAlign: "start",
                        // py: 1.4,
                        mt: -1,
                        fontSize: 20,
                      }}
                    >
                      Enter amount(in USD)
                    </FormLabel>
                    <TextField
                      required
                      type="number"
                      variant="outlined"
                      min={0.01}
                      sx={{
                        background: "transparent",
                        borderColor: "#E0E0E0",
                        boxShadow: "none",
                      }}
                      fullWidth={true}
                      placeholder="Enter Amount"
                      id="perHourAmount"
                      name="perHourAmount"
                      onChange={(ev) => setBalance(ev.target.value)}
                      value={balance}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    className="SignUpBtn"
                    sx={{
                      mt: 2,
                      textTransform: "capitalize",
                      py: "8px !important",
                      px: "24px !important",
                      width: "auto !important",
                      borderRadius: 12,
                      maxWidth: "auto !important",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Next
                  </Button>
                </form>
              </Box>
            </>
          ) : ( */}
          <Box
            className="employeeProjdetails"
            sx={{
              position: "absolute",
              width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: 5,
            }}
          >
            <Box>
              <>
                <Box className="SubHeader">
                  <Typography
                    variant="h5"
                    id="subscribe-modal-title"
                    sx={{
                      borderBottom: 1,
                      py: 2,
                      pt: 0,
                      borderColor: "#E6E6E6",
                    }}
                    gutterBottom
                  >
                    {t("Payment")}
                    <img
                      onClick={onClose}
                      src={close}
                      alt="Close"
                      style={{ cursor: "pointer", float: "right" }}
                    />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Paypal")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                            m: 0,
                            p: 0,
                          },
                        }}
                      />
                      <img src={Paypal} alt="Paypal" />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("paypalPaymentDes")}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Stripe")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                          },
                        }}
                      />
                      <img
                        src={Stripe}
                        alt="Stripe"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                      />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("stripePaymentDes")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                    mb: 2.5,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Crypto")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                          },
                        }}
                      />
                      <img src={Crypto} alt="Crypto" />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("cryptoPaymentDes")}
                    </Typography>
                  </Box>
                </Box>
              </>
              <hr />
              <Box
                sx={{
                  py: 1,
                  my: 2,
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      alignItems: "center",
                    }}
                  >
                    <span>{t("subTotal")}</span>
                    <span>${balance?.toFixed?.(2)}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      alignItems: "center",
                    }}
                  >
                    <span>{t("marketPlaceFee")}</span>
                    <span>${calculatedFees.toFixed(2)}</span>
                  </div>
                  {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        alignItems: "center",
                      }}
                    >
                      <span>Flat Fee</span>
                      <span>${data?.flatFee}</span>
                    </div> */}
                </Box>
              </Box>
              {/* You can add more content or form inputs here */}
              {selectedValue != "Paypal" ? (
                <Button
                  variant="contained"
                  fullWidth={true}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: 18,
                    bgcolor: "#0B3013!important",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                  onClick={handlePaymentSubmit}
                >
                  {t("pay")} ${estimatedTotal.toFixed(2)}
                </Button>
              ) : (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth={true}
                    style={{
                      pointerEvents: "none",
                    }}
                    sx={{
                      position: "relative",
                      zIndex: 10,
                      textTransform: "capitalize",
                      fontSize: 18,
                      bgcolor: "#0B3013!important",
                      color: "#fff",
                      borderRadius: 2,
                    }}
                    onClick={handlePaymentSubmit}
                  >
                    {t("pay")} ${estimatedTotal.toFixed(2)}
                  </Button>
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      borderRadius: "8px",
                      overflow: "hidden",
                      zIndex: 8,
                    }}
                  >
                    <PayPalScriptProvider
                      options={{
                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY,
                        currency: "USD",
                      }}
                    >
                      <PayPalButtons
                        fundingSource={FUNDING.PAYPAL}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: estimatedTotal,
                                },
                              },
                            ],
                            application_context: {
                              // Disable shipping address collection
                              shipping_preference: "NO_SHIPPING",
                            },
                          });
                        }}
                        onApprove={async (data, actions) => {
                          const details = await actions.order.capture();
                          if (details.status === "COMPLETED") {
                            // Handle successful transaction here
                            try {
                              const token = GetUserDetails()?.token;
                              const id = GetUserDetails()?.userId;

                              const dataToSend = {
                                amount: balance,
                                transactionId: details?.id,
                                fee: calculatedFees,
                                userId: id,
                                method: "PayPal Payment",
                              };

                              const response = await postAPI(
                                "wallet/add",
                                dataToSend,
                                token
                              );
                              if (response.statusCode === 200) {
                                await afterPaid();
                                toast.success(t("toast30"));
                                closePayPalModal();
                              }
                            } catch (error) {
                              // toast.error(error.message)
                            }
                          }
                        }}
                      />
                    </PayPalScriptProvider>
                  </div>
                </div>
              )}
            </Box>
          </Box>
          {/* )} */}
        </Modal>
      </>
    </>
  );
};

export default DisputeBalanceAdd;
