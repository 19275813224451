import { useTheme } from "@emotion/react";
import {
  Button,
  FormLabel,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, display, height, minHeight } from "@mui/system";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useEffect, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { postAPI, updateAPI } from "../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import { t } from "i18next";

export default function AddPayoutDialog({ open, onClose, onSave, payout }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState({});

  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(10); // Default to 'active'

  const handleFocus = () => {
    validateform();
  };

  function convertToDateInputValue(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (!open) {
      setDate("");
      setName("");
      setError({});
    } else if (payout?._id && open) {
      setDate(convertToDateInputValue(payout?.date));
      setName(payout?.organizationName);
      setAmount(payout?.amount);
    }
  }, [open, payout]);

  // const handleFileChange = (newFiles) => {
  //   if (
  //     [...files, ...newFiles].reduce((val, ele) => ele?.size + val, 0) >
  //     1_048_576
  //   ) {
  //     toast.warn("Maximum size of attachments sho uld be less than 2mb.");
  //     return;
  //   }
  //   setFiles([...files, ...newFiles]);
  //   const names = newFiles.map((file) => URL.createObjectURL(file));
  //   setFileNames((dt) => [...dt, ...names]);
  // };

  const validateform = () => {
    let err = {};
    let isValid = true;

    if (!date.trim()) {
      err = { ...err, date: t("plzEnterDate") };
      isValid = false;
    }

    if (!name.trim()) {
      err = { ...err, name: t("plzEnterorgName") };
      isValid = false;
    }

    if (amount <= 0) {
      err = { ...err, amount: t("amtgrttheEq0") };
      isValid = false;
    }

    setError(err);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateform()) return;
    const token = GetUserDetails()?.token;
    if (!token) return;
    if (payout?._id) {
      const dataToSend = {
        organizationName: name,
        amount: parseFloat(amount),
        date: new Date(date),
      };
      try {
        LoaderShow();
        const response = await updateAPI(
          `donation-investments/update/${payout._id}`,
          dataToSend,
          token
        );
        if (response.statusCode == 200) {
          toast.success(t("Payout updated successfully"));
          onClose();
          onSave();
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    } else {
      const dataToSend = {
        organizationName: name,
        amount: parseFloat(amount),
        date: new Date(date),
      };
      try {
        LoaderShow();
        const response = await postAPI(
          "donation-investments/create",
          dataToSend,
          token
        );
        if (response.statusCode == 200) {
          toast.success(t("Payout added successfully"));
          onClose();
          onSave();
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    }
    LoaderHide();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="logout-modal"
      aria-describedby="logout-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
          {payout?._id ? t("updatePayout") : t("addPayout")}
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            top: 14,
            right: 10,
          }}
          onClick={onClose}
        >
          <img src={close} alt="Close" />
        </IconButton>
        <Box sx={{ flex: 1, mt: 3 }}>
          <FormLabel
            sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
          >
            {t("orgName")}
          </FormLabel>
          <TextField
            onChange={(ev) => setName(ev.target.value)}
            value={name}
            error={!!error.name}
            helperText={error.name}
            variant="outlined"
            sx={{
              background: isFocused ? "#FFFFFF" : "transparent",
              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            }}
            fullWidth
            placeholder={t("orgName")}
            onFocus={handleFocus}
            onBlur={handleFocus}
            required
          />
        </Box>
        <Box sx={{ flex: 1, mt: 2 }}>
          <FormLabel
            sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
          >
            {t("date")}
          </FormLabel>
          <TextField
            onChange={(ev) => setDate(ev.target.value)}
            value={date}
            error={!!error.date}
            helperText={error.date}
            type="date"
            variant="outlined"
            sx={{
              background: isFocused ? "#FFFFFF" : "transparent",
              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            }}
            fullWidth
            placeholder={t("date")}
            onFocus={handleFocus}
            onBlur={handleFocus}
            required
          />
        </Box>
        <Box sx={{ flex: 1, mt: 2 }}>
          <FormLabel
            sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
          >
            {t("amt")}
          </FormLabel>
          <TextField
            onChange={(ev) => setAmount(ev.target.value)}
            value={amount}
            error={!!error.amount}
            helperText={error.amount}
            type="number"
            variant="outlined"
            sx={{
              background: isFocused ? "#FFFFFF" : "transparent",
              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            }}
            fullWidth
            placeholder={t("amt")}
            onFocus={handleFocus}
            onBlur={handleFocus}
            required
          />
        </Box>
        <Box sx={{ my: 3, display: "flex" }}>
          <Button
            onClick={onClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              textTransform: "capitalize",
              bgcolor: "#0a3114",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              px: 5,
              py: 1,
              "&:hover": {
                bgcolor: "#0a3114",
              },
            }}
            endIcon={<ArrowOutwardIcon color="#fff" />}
          >
            {t("Cancel")}
          </Button>{" "}
          <Button
            onClick={handleSave}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              textTransform: "capitalize",
              bgcolor: "#0a3114",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              px: 5,
              py: 1,
              "&:hover": {
                bgcolor: "#0a3114",
              },
            }}
            endIcon={<ArrowOutwardIcon color="#fff" />}
          >
            {t("Save")}
          </Button>{" "}
        </Box>
      </Box>
    </Modal>
  );
}
