import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Radio, Button } from "@mui/material";
import Paypal from "../../../Assets/Images/Paypal.png";
import Stripe from "../../../Assets/Images/Stripe.svg";
import Crypto from "../../../Assets/Images/Crypto.png";
import arrowCross from "../../../Assets/Images/arrow-up-right-01-round.png";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import BalanceAddDialog from "./BalanceAddDialog";
import BalanceWithdrawDialog from "../../../Components/BalanceWithdrawDialog/BalanceWithdrawDialog";

export default function EmployeerBillingPay() {
  const [userDetails, setUserDetails] = useState({});
  const [selectedValue, setSelectedValue] = useState("Stripe");

  const [openAddDialog, setopenAddDialog] = useState(false);
  const [openWithdrawDialog, setopenWithdrawDialog] = useState(false);

  const { t } = useTranslation();

  const [data, setData] = React.useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData(res.data.find((ele) => ele.type == "employer"));
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getUserDetails = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setUserDetails(response.data);
        setSelectedValue(response?.data?.defaultPaymentMethod || "Stripe");
      }
    } catch (error) {
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const handleSubmit = async () => {
    LoaderShow();
    const { token, userId } = GetUserDetails();
    try {
      const response = await updateAPI(
        `user/setDefaultPaymentMethod`,
        { defaultPaymentMethod: selectedValue },
        token
      );
      if (response.statusCode === 200) {
        toast.success(t("toast34"));
      }
    } catch (err) {
      console.log(err);
    }
    LoaderHide();
  };
  return (
    <Box>
      <BalanceAddDialog
        afterPaid={getUserDetails}
        open={openAddDialog}
        onClose={() => setopenAddDialog(false)}
        data={data}
      />
      <BalanceWithdrawDialog
        open={openWithdrawDialog}
        onClose={() => setopenWithdrawDialog(false)}
        data={data}
        balance={userDetails?.wallet ? userDetails?.wallet?.toFixed(2) : 0}
        cryptoAddress={userDetails?.cryptoAddress}
      />
      <Box>
        <div id="hideloding" class="loding-display" style={{ display: "none" }}>
          <img
            src="/static/media/loader.15a258c937618ca6463d23970033ccc9.svg"
            alt="loader-img"
          />
        </div>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("BillingandPayments")}
        </Typography>
        <Box>
          {/* <Box sx={{ borderTop: 1, borderColor: "#E9E9E9" }}></Box> */}
          <Grid container>
            <Grid
              sx={{ background: "#fff", borderRadius: 5, px: 4, py: 3, my: 3 }}
              xs={12}
            >
              <Box>
                <Box sx={{ py: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {t("Balance")}: $
                    {!isNaN(userDetails?.wallet)
                      ? userDetails?.wallet.toFixed(2)
                      : 0}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "start",
                  gap: "20px",
                }}
              >
                <Button
                  onClick={() => setopenAddDialog(true)}
                  className="SignUpBtn"
                  sx={{
                    textTransform: "capitalize",
                    py: "8px !important",
                    px: "24px !important",
                    width: "auto !important",
                    borderRadius: 12,
                    maxWidth: "auto !important",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                  endIcon={
                    <Box
                      component="img"
                      src={arrowCross}
                      sx={{ width: 24, height: 24, marginRight: 1 }}
                    />
                  }
                >
                  {t("addBal")}
                </Button>
                <Button
                  onClick={() => setopenWithdrawDialog(true)}
                  className="SignUpBtn"
                  sx={{
                    textTransform: "capitalize",
                    py: "8px !important",
                    px: "24px !important",
                    width: "auto !important",
                    borderRadius: 12,
                    maxWidth: "auto !important",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                  endIcon={
                    <Box
                      component="img"
                      src={arrowCross}
                      sx={{ width: 24, height: 24, marginRight: 1 }}
                    />
                  }
                >
                  {t("withdraw")}
                </Button>
              </Box>
            </Grid>

            <Grid
              sx={{ background: "#fff", borderRadius: 5, px: 4, py: 2, my: 3 }}
              xs={12}
            >
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: "#E9E9E9", py: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {t("addBillingMethod")}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      py: 1,
                      border: 1,
                      my: 2,
                      mt: 4,
                      borderColor: "#E6E6E6",
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      className="alignBaselineRsp flexColumnRespo"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          {...controlProps("Paypal")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                              color: "#0B3013",
                              m: 0,
                              p: 0,
                            },
                          }}
                        />
                        <img src={Paypal} alt="Paypal" />
                      </Box>
                      <Typography sx={{ px: 2 }}>
                        {t("paypalPaymentDes")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      py: 1,
                      border: 1,
                      my: 2,
                      borderColor: "#E6E6E6",
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      className="alignBaselineRsp flexColumnRespo"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          {...controlProps("Stripe")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                              color: "#0B3013",
                            },
                          }}
                        />
                        <img
                          src={Stripe}
                          alt="Stripe"
                          style={{
                            width: "auto",
                            height: "auto",
                            maxHeight: "100%",
                            maxWidth: "100%",
                          }}
                        />
                      </Box>
                      <Typography sx={{ px: 2 }}>
                        {t("stripePaymentDes")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      py: 1,
                      border: 1,
                      my: 2,
                      borderColor: "#E6E6E6",
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      className="alignBaselineRsp flexColumnRespo"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          {...controlProps("Crypto")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                              color: "#0B3013",
                            },
                          }}
                        />
                        <img src={Crypto} alt="Crypto" />
                      </Box>
                      <Typography sx={{ px: 2 }}>
                        {t("cryptoPaymentDes")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  {/* You can add more content or form inputs here */}
                </Box>
              </Box>
              <Box sx={{ mt: 3, display: "flex", justifyContent: "start" }}>
                <Button
                  onClick={handleSubmit}
                  className="SignUpBtn"
                  sx={{
                    textTransform: "capitalize",
                    py: "8px !important",
                    px: "24px !important",
                    width: "auto !important",
                    borderRadius: 12,
                    maxWidth: "auto !important",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                  endIcon={
                    <Box
                      component="img"
                      src={arrowCross}
                      sx={{ width: 24, height: 24, marginRight: 1 }}
                    />
                  }
                >
                  {t("Save")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
