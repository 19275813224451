import React from "react";
import { Container, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box sx={{ py: 1.5 }}>
          <Box className="terms-main" sx={{ py: 1.5 }}>
            <h4 className="terms-heading">{t("privacyPolicy")}</h4>
          </Box>
        </Box>
        <Box
          className="terms-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy1")}</p>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy2")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>
              {t("privacyPolicy3")} <br />
              <a href="/privacypolicy">https://workwavehub.com/privacypolicy</a>
            </p>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy4")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy5")}</p>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy6")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy7")}</p>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}> {t("privacyPolicy38")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy44")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "0.5rem" }}>
                <strong>{t("privacyPolicy45")}</strong> :{" "}
                <a href="mailto:contact@workwavehub.com" target="_blank">
                  contact@workwavehub.com
                </a>
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                <strong>{t("privacyPolicy46")}</strong> :{" "}
                <a href="/contact" target="_blank">
                  http://workwavehub.com/contact
                </a>
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                <strong>{t("privacyPolicy47")}</strong> : +1 (236) 591-9207
              </li>
            </ul>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy48")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy49")}</p>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy50")}</h2>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy51")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy52")}</p>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy53")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy54")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy8")}</strong>
                {t("privacyPolicy9")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy10")}</strong>
                {t("privacyPolicy11")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy12")}</strong>
                {t("privacyPolicy13")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy14")}</strong>
                {t("privacyPolicy15")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy16")}</strong>
                {t("privacyPolicy17")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy18")}</strong>
                {t("privacyPolicy19")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy20")}</strong>
                {t("privacyPolicy21")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy22")}</strong>
                {t("privacyPolicy23")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy24")}</strong>
                {t("privacyPolicy25")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy26")}</strong>
                {t("privacyPolicy27")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy28")}</strong>
                {t("privacyPolicy29")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy30")}</strong>
                {t("privacyPolicy31")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy32")}</strong> {t("privacyPolicy33")}{" "}
                <a href="/" target="_blank">
                  https://www.workwavehub.com/
                </a>
                .
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy34")}</strong> {t("privacyPolicy35")}
              </li>
            </ul>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy36")}</h2>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy37")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy39")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy40")}</li>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy41")}</li>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy42")}</li>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy43")}</li>
              {/* <li style={{ marginBottom: "2rem" }}>{t("privacyPolicy30")}</li> */}
            </ul>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy77")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy55")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy56")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy57")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy58")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy59")}</li>
            </ul>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy60")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy61")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy62")}</strong>
                {t("privacyPolicy63")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy64")}</strong>
                {t("privacyPolicy65")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy66")}</strong>
                {t("privacyPolicy67")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy68")}</strong>
                {t("privacyPolicy69")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy70")}</strong>
                {t("privacyPolicy71")}
              </li>
            </ul>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy95")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy72")}</p>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy98")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy73")}</p>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy131")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy74")}</p>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy102")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy75")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy78")}</strong>
                {t("privacyPolicy79")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy80")}</strong>
                {t("privacyPolicy81")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy82")}</strong>
                {t("privacyPolicy83")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy84")}</strong>
                {t("privacyPolicy85")}
              </li>
            </ul>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy114")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy76")}</p>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy116")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy86")}</p>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy119")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy87")}</p>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy122")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy88")}</p>
          </Box>

          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy89")}</h2>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy90")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy91")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy40")}</li>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy41")}</li>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy42")}</li>
              <li style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy43")}</li>
              {/* <li style={{ marginBottom: "2rem" }}>{t("privacyPolicy30")}</li> */}
            </ul>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy92")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy93")}</p>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy94")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy100")}</p>
            <h3 style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
              {t("privacyPolicy100")}
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy103")}</p>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy104")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy105")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "0.5rem" }}>
                {t("privacyPolicy106")}
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                {t("privacyPolicy107")}
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                {t("privacyPolicy108")}{" "}
                <a href="mailto:contact@workwavehub.com" target="_blank">
                  contact@workwavehub.com
                </a>
              </li>
            </ul>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy109")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy110")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy111")}</strong>
                {t("privacyPolicy112")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy113")}</strong>
                {t("privacyPolicy115")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy117")}</strong>
                {t("privacyPolicy118")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy120")}</strong>
                {t("privacyPolicy121")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy123")}</strong>
                {t("privacyPolicy124")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy125")}</strong>
                {t("privacyPolicy126")}
              </li>
            </ul>
          </Box>
          <Box sx={{ py: 1.5 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy127")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy128")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy129")}</strong>
                {t("privacyPolicy130")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy132")}</strong>
                {t("privacyPolicy133")}
              </li>
            </ul>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy134")}</p>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
