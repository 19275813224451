import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import Paypal from "../../../../Assets/Images/Paypal.png";
import Stripe from "../../../../Assets/Images/Stripe.svg";
import Crypto from "../../../../Assets/Images/Crypto.png";
import { loadStripe } from "@stripe/stripe-js";
import { getAPI, postAPI } from "../../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
// import CoinPayments from "../CoinPayments/CoinPayments";
import CloseIcon from "@mui/icons-material/Close";
import CoinPayments from "../../../../Pages/Home/HomeMembership/CoinPayments/CoinPayments";

const Payment = ({
  open,
  onClose,
  milestoneId,
  employee,
  subTotal,
  afterPaid,
  data,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedValue, setSelectedValue] = React.useState("Stripe");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [milestone, setMilestone] = useState("");
  const [additionalTaxes] = useState(15); // Additional taxes amount
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCoinPaymentDialog, setOpenCoinPaymentDialog] = useState(false);
  const [cryptoDetails, setCryptoDetails] = useState({});
  const [openPayPal, setOpenPayPal] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);

  // const [data, setData] = React.useState({});

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (open) {
      getWalletBalance();
    }
  }, [open]);

  const getWalletBalance = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("user/balance", token);
        if (res?.statusCode == 200) {
          setWalletBalance(Number(res.data));
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const token = GetUserDetails()?.token;
  //     if (token) {
  //       const res = await getAPI("fee/getAll", token);
  //       if (res?.statusCode == 200) {
  //         setData(res.data.find((ele) => ele.type == "employer"));
  //       }
  //     }
  //   } catch (err) {
  //     console.log(t(err.message));
  //     toast.error(t(err.message));
  //   }
  // };

  const amtToBePaid = useMemo(() => {
    return walletBalance - subTotal;
  });

  const calculatedFees = useMemo(() => {
    switch (selectedValue) {
      case "Crypto":
        if (Math.abs(amtToBePaid) < data?.cryptoAmount)
          return (Math.abs(amtToBePaid) * data?.cryptoMinPercentage) / 100;
        return (Math.abs(amtToBePaid) * data?.cryptoPercentage) / 100;
      case "Stripe":
        if (Math.abs(amtToBePaid) < data?.stripeAmount)
          return (Math.abs(amtToBePaid) * data?.stripeMinPercentage) / 100;
        return (Math.abs(amtToBePaid) * data?.stripePercentage) / 100;
      case "Paypal":
        if (Math.abs(amtToBePaid) < data?.paypalAmount)
          return (Math.abs(amtToBePaid) * data?.paypalMinPercentage) / 100;
        return (Math.abs(amtToBePaid) * data?.paypalPercentage) / 100;
      default:
        return 0;
    }
  }, [data, amtToBePaid, selectedValue]);

  const estimatedTotal = useMemo(() => {
    return Math.abs(amtToBePaid) + calculatedFees;
  }, [calculatedFees, data, amtToBePaid]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const projectDetails = JSON.parse(localStorage.getItem("projectData")) || "";

  const getUserDetails = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setSelectedValue(response?.data?.defaultPaymentMethod || "Stripe");
      }
    } catch (error) {
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const initiateStripePayment = async () => {
    setLoading(true);
    try {
      const stripe = await loadStripe(
        `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
      );

      const response = await fetch(
        "https://api.stripe.com/v1/checkout/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECERT_KEY}`,
          },
          body: new URLSearchParams({
            "payment_method_types[]": "card",
            "line_items[0][price_data][currency]": "usd",
            "line_items[0][price_data][product_data][name]":
              "Milestone Payment",
            "line_items[0][price_data][unit_amount]": Math.trunc(
              estimatedTotal.toFixed(2) * 100
            ), // Stripe expects the amount in cents
            "line_items[0][quantity]": "1",
            mode: "payment",
            success_url: `${
              window.location.origin
            }/dashboard/employer/myprojects?payment_intent={CHECKOUT_SESSION_ID}&user_id=${
              employee._id
            }&email=${
              employee.email
            }&isNew=true&milestone_id=${milestoneId.toLocaleString()}&amt=${subTotal}&paidAmt=${Math.abs(
              amtToBePaid
            )}&total_fee=${calculatedFees}`,
            cancel_url: `${window.location.origin}`,
            // 'success_url': `http://localhost:3000/sendanoffer?payment_intent={CHECKOUT_SESSION_ID}`,
            // 'cancel_url': `http://localhost:3000/sendanoffer`,
          }).toString(),
        }
      );

      const session = await response.json();

      if (session.error) {
        throw new Error(session.error.message);
      }
      LoaderHide();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      LoaderHide();
      console.error("Error initiating Stripe payment:", error);
    }
  };

  const initiateCryptoPayment = async () => {
    setLoading(true);
    LoaderShow();
    try {
      const { token } = GetUserDetails();
      const data = {
        projectId: projectDetails?._id ? projectDetails?._id : "",
        // currency: "LTCT",
        employerId: projectDetails?.user?._id
          ? projectDetails?.user?._id
          : projectDetails?.user._id
          ? projectDetails?.user._id
          : "",
        employeeId: employee?._id ? employee?._id : employee,
        milestones: milestoneId,
        amount: estimatedTotal,
      };
      const response = await postAPI("coinpayment/create-payment", data, token);
      if (response?.payment?.data) {
        setCryptoDetails(response?.payment?.data);
        setOpenCoinPaymentDialog(true);
      }
    } catch (error) {
      console.error("Error initiating Stripe payment:", error);
    }
    LoaderHide();
  };

  const closePayPalModal = () => {
    setOpenPayPal(false);
  };

  const deductFromWallet = async () => {
    LoaderShow();
    const projectDetails =
      JSON.parse(localStorage.getItem("projectData")) || "";
    try {
      const dataToSend = {
        amount: subTotal,
        projectId: projectDetails?._id ? projectDetails?._id : "",
        peyeeId: projectDetails?.user?._id ? projectDetails?.user?._id : "",
        milestoneIds: milestoneId,
        totalFee: 0,
      };
      const token = GetUserDetails()?.token;
      const id = GetUserDetails()?.userId;

      const data = {
        email: employee?.email,
        projectId: projectDetails?._id,
        employeeId: employee?._id,
        milestoneIds: milestoneId,
      };

      const response = await postAPI("transection/create", dataToSend, token);
      if (response.statusCode === 200) {
        try {
          const response = await postAPI(
            "invoice/create",
            {
              ...dataToSend,
              type: "employer",
              payeeId: dataToSend.peyeeId,
              payerId: id,
              currency: "USD",
              milestoneId: dataToSend.milestoneIds[0],
              paymentMethod: "PayPal Payment",
            },
            token
          );
          // await postAPI("award/projects/award", data, token);
        } catch (err) {}
        onClose();
        toast.success("Amount deducted from your wallet successfully");
        afterPaid();
      }
    } catch (error) {
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const handlePaymentSubmit = () => {
    LoaderShow();
    // if (selectedValue === "Paypal") {
    //   setOpenPayPal(true);
    //   onClose();
    //   LoaderHide();
    // } else
    if (amtToBePaid >= 0) {
      deductFromWallet();
    } else {
      if (selectedValue === "Stripe") {
        initiateStripePayment();
      } else if (selectedValue === "Crypto") {
        initiateCryptoPayment();
      }
    }
  };

  const createTransaction = async (details) => {
    LoaderShow();
    if (details.message === "completed") {
      // Handle successful transaction here
      setOpenCoinPaymentDialog(false);
      try {
        const dataToSend = {
          amount: subTotal,
          remainingAmount: Math.abs(amtToBePaid),
          projectId: projectDetails?._id ? projectDetails?._id : "",
          method: "Crypto Payment",
          peyeeId: projectDetails?.user?._id ? projectDetails?.user?._id : "",
          transectionId: details?.id,
          milestoneIds: milestoneId,
          totalFee: calculatedFees,
        };
        const token = GetUserDetails()?.token;
        const id = GetUserDetails()?.userId;

        const data = {
          email: employee?.email,
          projectId: projectDetails?._id,
          employeeId: employee?._id,
          milestoneIds: milestoneId,
          totalFee: calculatedFees,
        };

        const response = await postAPI("transection/create", dataToSend, token);
        if (response.statusCode === 200) {
          try {
            const response = await postAPI(
              "invoice/create",
              {
                ...dataToSend,
                type: "employer",
                payeeId: dataToSend.peyeeId,
                payerId: id,
                currency: "USD",
                milestoneId: dataToSend.milestoneIds[0],
                paymentMethod: "Crypto Payment",
              },
              token
            );
          } catch (err) {}
          // navigate("/dashboard/employer/myprojects");
          toast.success(t("toast30"));
          afterPaid();
        }
      } catch (error) {
        // toast.error(error.message)
      }
    }
    LoaderHide();
  };

  return (
    <>
      {openCoinPaymentDialog && (
        <CoinPayments
          open={openCoinPaymentDialog}
          onClose={() => setOpenCoinPaymentDialog(false)}
          coinsPayment={cryptoDetails}
          onComplete={createTransaction}
        />
      )}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="subscribe-modal-title"
        aria-describedby="subscribe-modal-description"
      >
        <Box
          className="employeeProjdetails"
          sx={{
            position: "absolute",
            width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box>
            <>
              <Box className="SubHeader" style={{ position: "relative" }}>
                <Typography
                  variant="h5"
                  id="subscribe-modal-title"
                  sx={{ borderBottom: 1, py: 2, pt: 0, borderColor: "#E6E6E6" }}
                  gutterBottom
                >
                  {t("Payment")}
                </Typography>
                <CloseIcon
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                  onClick={onClose}
                />
              </Box>
              {/* {amtToBePaid < 0 && (
                <> */}
              <div
                style={{
                  pointerEvents: amtToBePaid < 0 ? "all" : "none",
                  opacity: amtToBePaid < 0 ? 1 : 0.6,
                }}
              >
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Paypal")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                            m: 0,
                            p: 0,
                          },
                        }}
                      />
                      <img src={Paypal} alt="Paypal" />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("paypalPaymentDes")}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Stripe")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                          },
                        }}
                      />
                      <img
                        src={Stripe}
                        alt="Stripe"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                      />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("stripePaymentDes")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                    mb: 2.5,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Crypto")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                          },
                        }}
                      />
                      <img src={Crypto} alt="Crypto" />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("cryptoPaymentDes")}
                    </Typography>
                  </Box>
                </Box>
              </div>
              <hr />
              {/* </>
              )} */}
            </>
            <Box
              sx={{
                pb: 1,
                mb: 2,
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pb: 1.5,
                    pt: 2,
                    borderBottom: "1px solid #cac8c8",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {t("walletBal")}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    ${walletBalance.toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: 2,
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {t("mileFee")}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    ${subTotal.toFixed(2)}
                  </Typography>
                </Box>
                {amtToBePaid < 0 ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pb: 2,
                        borderColor: "#d6d6d6",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                      >
                        {t("amtPaidWithoutFees")}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                      >
                        ${Math.abs(amtToBePaid).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pb: 2,
                        borderColor: "#d6d6d6",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                      >
                        {t("marketPlaceFee")}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                      >
                        {calculatedFees
                          ? "$" + calculatedFees.toFixed(2)
                          : "$0"}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 0,
            pb: 3,
            borderBottom: 1,
            borderColor: "#d6d6d6",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            Flat fee
          </Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
          >
            {subTotal ? "$" + data.flatFee : "$0"}
          </Typography>
        </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pt: 2,
                    pb: amtToBePaid < 0 ? 0 : 2,
                    borderTop: "1px solid #cac8c8",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {t("newWalletBal")}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    ${amtToBePaid < 0 ? 0 : amtToBePaid.toFixed(2)}
                  </Typography>
                </Box>
                {amtToBePaid < 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: 2,
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                    >
                      {t("amtPaidWithfees")}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                    >
                      {subTotal === 0 ? "$0" : "$" + estimatedTotal.toFixed(2)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* You can add more content or form inputs here */}
            {amtToBePaid < 0 ? (
              <>
                {selectedValue != "Paypal" ? (
                  <Button
                    variant="contained"
                    fullWidth={true}
                    sx={{
                      textTransform: "capitalize",
                      fontSize: 18,
                      bgcolor: "#0B3013!important",
                      color: "#fff",
                      borderRadius: 2,
                    }}
                    onClick={handlePaymentSubmit}
                  >
                    {t("pay")} ${estimatedTotal.toFixed(2)}
                  </Button>
                ) : (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth={true}
                      style={{
                        pointerEvents: "none",
                      }}
                      sx={{
                        position: "relative",
                        zIndex: 10,
                        textTransform: "capitalize",
                        fontSize: 18,
                        bgcolor: "#0B3013!important",
                        color: "#fff",
                        borderRadius: 2,
                      }}
                      onClick={handlePaymentSubmit}
                    >
                      {t("pay")} ${estimatedTotal.toFixed(2)}
                    </Button>
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        borderRadius: "8px",
                        overflow: "hidden",
                        zIndex: 8,
                      }}
                    >
                      <PayPalScriptProvider
                        options={{
                          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY,
                          currency: "USD",
                        }}
                      >
                        <PayPalButtons
                          fundingSource={FUNDING.PAYPAL}
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              purchase_units: [
                                {
                                  amount: {
                                    value: estimatedTotal,
                                  },
                                },
                              ],
                              application_context: {
                                // Disable shipping address collection
                                shipping_preference: "NO_SHIPPING",
                              },
                            });
                          }}
                          onApprove={async (data, actions) => {
                            const details = await actions.order.capture();
                            console.log("details paypal=== --- ", details);
                            if (details.status === "COMPLETED") {
                              // Handle successful transaction here
                              try {
                                const dataToSend = {
                                  amount: subTotal,
                                  remainingAmount: Math.abs(amtToBePaid),
                                  projectId: projectDetails?._id
                                    ? projectDetails?._id
                                    : "",
                                  method: "PayPal Payment",
                                  peyeeId: projectDetails?.user?._id
                                    ? projectDetails?.user?._id
                                    : "",
                                  transectionId: details?.id,
                                  milestoneIds: milestoneId,
                                  totalFee: calculatedFees,
                                };
                                const token = GetUserDetails()?.token;
                                const id = GetUserDetails()?.userId;

                                const response = await postAPI(
                                  "transection/create",
                                  dataToSend,
                                  token
                                );
                                if (response.statusCode === 200) {
                                  try {
                                    const response = await postAPI(
                                      "invoice/create",
                                      {
                                        ...dataToSend,
                                        type: "employer",
                                        payeeId: dataToSend.peyeeId,
                                        payerId: id,
                                        currency: "USD",
                                        milestoneId: dataToSend.milestoneIds[0],
                                        paymentMethod: "PayPal Payment",
                                      },
                                      token
                                    );
                                  } catch (err) {}
                                  closePayPalModal();
                                  afterPaid();
                                }
                              } catch (error) {
                                // toast.error(error.message)
                              }
                            }
                          }}
                        />
                      </PayPalScriptProvider>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Button
                variant="contained"
                fullWidth={true}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 18,
                  bgcolor: "#0B3013!important",
                  color: "#fff",
                  borderRadius: 2,
                }}
                onClick={handlePaymentSubmit}
              >
                {t("payfrmWallet")} ${subTotal.toFixed(2)}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openPayPal}
        onClose={closePayPalModal}
        aria-labelledby="subscribe-modal-title"
        aria-describedby="subscribe-modal-description"
      >
        <Box
          className="employeeProjdetails"
          sx={{
            position: "absolute",
            width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box>
            <Box className="SubHeader">
              <Typography
                variant="h5"
                id="subscribe-modal-title"
                sx={{ borderBottom: 1, py: 2, pt: 0, borderColor: "#E6E6E6" }}
                gutterBottom
              >
                PayPal {t("Payment")}
              </Typography>
              {/* <PayPalScriptProvider
                options={{
                  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY,
                  currency: "USD",
                }}
              >
                <PayPalButtons
                  fundingSource={FUNDING.PAYPAL}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: estimatedTotal,
                          },
                        },
                      ],
                      application_context: {
                        // Disable shipping address collection
                        shipping_preference: "NO_SHIPPING",
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    const details = await actions.order.capture();
                    console.log("details paypal=== --- ", details);
                    if (details.status === "COMPLETED") {
                      // Handle successful transaction here
                      try {
                        const dataToSend = {
                          amount: estimatedTotal,
                          projectId: projectDetails?._id? projectDetails?._id: "",
                          method: "PayPal Payment",
                          peyeeId: projectDetails?.user?._id? projectDetails?.user?._id: "",
                          transectionId: details?.id,
                          milestoneIds: milestoneId,
                          totalFee: calculatedFees + data?.flatFee,
                        };
                        const token = GetUserDetails()?.token;
                        const id = GetUserDetails()?.userId;

                        const data = {
                          email: employee?.email,
                          projectId: projectDetails?._id,
                          employeeId: employee?._id,
                          milestoneIds: milestoneId,
                        };

                        const response = await postAPI(
                          "transection/create",
                          dataToSend,
                          token
                        );
                        if (response.statusCode === 200) {
                          try {
                            const response = await postAPI(
                              "invoice/create",
                              {
                                ...dataToSend,
                                type: "employer",
                                payeeId: dataToSend.peyeeId,
                                payerId: id,
                                currency: "USD",
                                milestoneId: dataToSend.milestoneIds[0],
                                paymentMethod: "PayPal Payment",
                              },
                              token
                            );
                          } catch (err) {}
                          closePayPalModal();
                        }
                      } catch (error) {
                        // toast.error(error.message)
                      }
                    }
                  }}
                />
              </PayPalScriptProvider> */}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Payment;
