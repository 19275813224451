import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import { useNavigate } from "react-router-dom";

export default function EmployeeAwardReq() {
  const { t } = useTranslation();

  const [awards, setAwards] = useState([]);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState(true);
  const [selectedProject, setSelectedProject] = useState({});

  const [data, setData] = React.useState({});

  useEffect(() => {
    getAwards();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData(res.data.find((ele) => ele.type == "employee"));
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
  };

  const getAwards = async () => {
    LoaderShow();
    try {
      const { token, userId } = GetUserDetails();
      const response = await postAPI(
        "award/awarded-projects",
        { employeeId: userId },
        token
      );
      if (response.data) {
        setAwards(response.data);
      }
    } catch (err) {
      console.log(err);
    }
    LoaderHide();
  };

  const calculatedFees = useMemo(() => {
    if (selectedProject?.totalMilestoneAmount < data?.cryptoAmount)
      return (
        (selectedProject?.totalMilestoneAmount * data?.cryptoMinPercentage) /
        100
      );
    return (
      (selectedProject?.totalMilestoneAmount * data?.cryptoPercentage) / 100
    );
  }, [selectedProject, data]);

  const onStateChange = async (ev, projectId, status) => {
    ev.stopPropagation();
    setOpenDialog(true);
    setStatus(status);
    setSelectedProject(projectId);
  };

  const onChangeData = async (ev, project, status) => {
    ev.stopPropagation();
    LoaderShow();
    try {
      const { token, userId } = GetUserDetails();
      const data = {
        action: status,
        employeeId: userId,
      };
      const res = await postAPI(
        `award/projects/${project._id}/status`,
        data,
        token
      );
      if (res.statusCode == 200) {
        setAwards((awrds) => awrds.filter((ele) => ele._id != project._id));
        toast.success(t(`Project ${status}ed`));
      }
    } catch (err) {
      toast.error(t(err.message));
      console.log(err);
    }
    setOpenDialog(false);
    LoaderHide();
  };

  const openProject = (award) => {
    navigate(`/dashboard/employee/projectdetails?project_id=${award._id}`);
  };
  return (
    <section className="EmployeerCreateProject">
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("areUsureCon")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedProject?.milestones?.[0]?.userFirstName} {t("aReq1")} $
            {selectedProject?.totalMilestoneAmount} , {t("aReq2")} $
            {selectedProject?.totalMilestoneAmount - (calculatedFees || 0)} ,
            {t("aReq3")} {status == "accept" ? t("accpt") : t("rejct")} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={(ev) => onChangeData(ev, selectedProject, status)}
          >
            {t("confirm")}
          </Button>
          <Button
            color="success"
            onClick={() => setOpenDialog(false)}
            autoFocus
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <div id="hideloding" class="loding-display" style={{ display: "none" }}>
        <img
          src="/static/media/loader.15a258c937618ca6463d23970033ccc9.svg"
          alt="loader-img"
        />
      </div>
      <Box>
        <Typography
          className="employeeHead1"
          variant="h4"
          sx={{ pb: 1, fontWeight: 600 }}
        >
          {t("AwardRequest")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>

      <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5, py: 2 }}>
        {awards.length > 0 && (
          <div style={{ overflow: "auto" }}>
            <Table sx={{ fontFamily: "inherit" }}>
              <TableHead sx={{ borderBottom: "0!important" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      px: 5,
                      fontWeight: 600,
                      fontSize: 18,
                      borderBottom: "0!important",
                      fontFamily: "inherit",
                    }}
                  >
                    {t("ProjectName")}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: 600,
                      fontSize: 18,
                      borderBottom: "0!important",
                      fontFamily: "inherit",
                      textAlign: "center",
                    }}
                  >
                    {t("Action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  awards.length > 0
                    ? awards.map((award) => (
                        <TableRow
                          key={award._id}
                          sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                          style={{ cursor: "pointer" }}
                          onClick={() => openProject(award)}
                        >
                          <TableCell
                            sx={{
                              px: 5,
                              fontWeight: 500,
                              fontSize: 16,
                              fontFamily: "inherit",
                              cursor: "pointer",
                            }}
                          >
                            {award?.title}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            <Button
                              sx={{
                                mx: 2,
                                borderRadius: 9999,
                                textTransform: "capitalize",
                              }} // Set borderRadius to create rounded buttons
                              variant="contained" // Use 'contained' for filled background
                              color="success"
                              startIcon={<CheckIcon />}
                              onClick={(ev) =>
                                onStateChange(ev, award, "accept")
                              }
                            >
                              {t("accpt")}
                            </Button>
                            <Button
                              sx={{
                                borderRadius: 9999,
                                textTransform: "capitalize",
                              }} // Set borderRadius to create rounded buttons
                              variant="contained" // Use 'contained' for filled background
                              color="error"
                              startIcon={<CloseIcon />}
                              onClick={(ev) =>
                                onStateChange(ev, award, "reject")
                              }
                            >
                              {t("rejct")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    : ""
                  // <TableRow
                  //   sx={{
                  //     "&:hover": { backgroundColor: "#f5f5f5" },
                  //     borderBottom: 0,
                  //   }}
                  //   style={{ cursor: "pointer" }}
                  // >
                  //   <TableCell
                  //     sx={{
                  //       px: 5,
                  //       fontWeight: 500,
                  //       fontSize: 16,
                  //       fontFamily: "inherit",
                  //       cursor: "pointer",
                  //     }}
                  //     colSpan={2}
                  //   >
                  //     <NodataFound />
                  //   </TableCell>
                  // </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        )}
        {awards.length > 0 && (
          <Box
            sx={{ py: 3, display: "flex", ml: "auto", justifyContent: "end" }}
          >
            <CustomPagination />
          </Box>
        )}
        {awards.length <= 0 && <NodataFound />}
      </Box>
    </section>
  );
}
