import { Box, Container, Typography } from "@mui/material";
import "./PricingFees.css";
import { useSSR, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getAPI } from "../../Services/Api";

export default function PricingFees() {
  const { t } = useTranslation();
  const [employeeFeeData, setEmployeeFeeData] = useState({});
  const [employerFeeData, setEmployerFeeData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await getAPI("fee/getAll", "");
      if (res?.statusCode == 200) {
        setEmployeeFeeData(res.data.find((ele) => ele.type == "employee"));
        setEmployerFeeData(res.data.find((ele) => ele.type == "employer"));
      }
    } catch (err) {
      console.log(t(err.message));
    }
  };
  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box className="terms-main" sx={{ py: 5 }}>
          <h4 className="terms-heading">{t("pricingPlans")}</h4>
        </Box>
        <div
          className="terms-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <div style={{ padding: "5px 0 5px 0" }}>
              <h2
                style={{
                  paddingBottom: "15px",
                  borderBottom: "1px solid #dbdbdb",
                  marginBottom: "20px",
                }}
              >
                {t("pricingFees")}
              </h2>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees1")}</p>
              <ul>
                <li style={{ marginBottom: "5px" }}>
                  <b>
                    {t("pricingFees2", { amt: employeeFeeData?.cryptoAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employeeFeeData?.cryptoPercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employeeFeeData?.cryptoAmount -
                          (employeeFeeData?.cryptoAmount *
                            employeeFeeData?.cryptoPercentage) /
                            100,
                        percentFee:
                          (employeeFeeData?.cryptoAmount *
                            employeeFeeData?.cryptoPercentage) /
                          100,
                        percent: employeeFeeData?.cryptoPercentage,
                        originalAmt: employeeFeeData?.cryptoAmount,
                      })}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employeeFeeData?.cryptoAmount +
                          100 -
                          ((employeeFeeData?.cryptoAmount + 100) *
                            employeeFeeData?.cryptoPercentage) /
                            100,
                        percentFee:
                          ((employeeFeeData?.cryptoAmount + 100) *
                            employeeFeeData?.cryptoPercentage) /
                          100,
                        percent: employeeFeeData?.cryptoPercentage,
                        originalAmt: employeeFeeData?.cryptoAmount + 100,
                      })}
                    </li>
                  </ul>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <b>
                    {t("pricingFees7", { amt: employeeFeeData?.cryptoAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employeeFeeData?.cryptoMinPercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employeeFeeData?.cryptoAmount -
                          10 -
                          ((employeeFeeData?.cryptoAmount - 10) *
                            employeeFeeData?.cryptoMinPercentage) /
                            100,
                        percentFee:
                          ((employeeFeeData?.cryptoAmount - 10) *
                            employeeFeeData?.cryptoMinPercentage) /
                          100,
                        percent: employeeFeeData?.cryptoMinPercentage,
                        originalAmt: employeeFeeData?.cryptoAmount - 10,
                      })}
                    </li>
                  </ul>
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees23")}</p>
              <h3>{t("pricingFees24")} </h3>
              <ul>
                <li>
                  {t("pricingFees25", { amt: employeeFeeData?.flatWithdrawal })}
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees10")}</p>

              <h2
                style={{
                  padding: "15px 0",
                  borderBottom: "1px solid #dbdbdb",
                  marginBottom: "20px",
                }}
              >
                {t("pricingFees11")}
              </h2>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees1")}</p>
              <h3 style={{ marginTop: "25px" }}>{t("pricingFees20")} </h3>
              <ul>
                <li style={{ marginBottom: "5px" }}>
                  <b>
                    {t("pricingFees2", { amt: employerFeeData?.cryptoAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employerFeeData?.cryptoPercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.cryptoAmount -
                          (employerFeeData?.cryptoAmount *
                            employerFeeData?.cryptoPercentage) /
                            100,
                        percentFee:
                          (employerFeeData?.cryptoAmount *
                            employerFeeData?.cryptoPercentage) /
                          100,
                        percent: employerFeeData?.cryptoPercentage,
                        originalAmt: employerFeeData?.cryptoAmount,
                      })}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.cryptoAmount +
                          100 -
                          ((employerFeeData?.cryptoAmount + 100) *
                            employerFeeData?.cryptoPercentage) /
                            100,
                        percentFee:
                          ((employerFeeData?.cryptoAmount + 100) *
                            employerFeeData?.cryptoPercentage) /
                          100,
                        percent: employerFeeData?.cryptoPercentage,
                        originalAmt: employerFeeData?.cryptoAmount + 100,
                      })}
                    </li>
                  </ul>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <b>
                    {t("pricingFees7", { amt: employerFeeData?.cryptoAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employerFeeData?.cryptoMinPercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.cryptoAmount -
                          10 -
                          ((employerFeeData?.cryptoAmount - 10) *
                            employerFeeData?.cryptoMinPercentage) /
                            100,
                        percentFee:
                          ((employerFeeData?.cryptoAmount - 10) *
                            employerFeeData?.cryptoMinPercentage) /
                          100,
                        percent: employerFeeData?.cryptoMinPercentage,
                        originalAmt: employerFeeData?.cryptoAmount - 10,
                      })}
                    </li>
                  </ul>
                </li>
              </ul>
              <h3 style={{ marginTop: "25px" }}>{t("pricingFees21")} </h3>
              <ul>
                <li style={{ marginBottom: "5px" }}>
                  <b>
                    {t("pricingFees2", { amt: employerFeeData?.stripeAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employerFeeData?.stripePercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.stripeAmount -
                          (employerFeeData?.stripeAmount *
                            employerFeeData?.stripePercentage) /
                            100,
                        percentFee:
                          (employerFeeData?.stripeAmount *
                            employerFeeData?.stripePercentage) /
                          100,
                        percent: employerFeeData?.stripePercentage,
                        originalAmt: employerFeeData?.stripeAmount,
                      })}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.stripeAmount +
                          100 -
                          ((employerFeeData?.stripeAmount + 100) *
                            employerFeeData?.stripePercentage) /
                            100,
                        percentFee:
                          ((employerFeeData?.stripeAmount + 100) *
                            employerFeeData?.stripePercentage) /
                          100,
                        percent: employerFeeData?.stripePercentage,
                        originalAmt: employerFeeData?.stripeAmount + 100,
                      })}
                    </li>
                  </ul>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <b>
                    {t("pricingFees7", { amt: employerFeeData?.stripeAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employerFeeData?.stripeMinPercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.stripeAmount -
                          10 -
                          ((employerFeeData?.stripeAmount - 10) *
                            employerFeeData?.stripeMinPercentage) /
                            100,
                        percentFee:
                          ((employerFeeData?.stripeAmount - 10) *
                            employerFeeData?.stripeMinPercentage) /
                          100,
                        percent: employerFeeData?.stripeMinPercentage,
                        originalAmt: employerFeeData?.stripeAmount - 10,
                      })}
                    </li>
                  </ul>
                </li>
              </ul>
              <h3 style={{ marginTop: "25px" }}>{t("pricingFees22")} </h3>
              <ul>
                <li style={{ marginBottom: "5px" }}>
                  <b>
                    {t("pricingFees2", { amt: employerFeeData?.paypalAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employerFeeData?.paypalPercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.paypalAmount -
                          (employerFeeData?.paypalAmount *
                            employerFeeData?.paypalPercentage) /
                            100,
                        percentFee:
                          (employerFeeData?.paypalAmount *
                            employerFeeData?.paypalPercentage) /
                          100,
                        percent: employerFeeData?.paypalPercentage,
                        originalAmt: employerFeeData?.paypalAmount,
                      })}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.paypalAmount +
                          100 -
                          ((employerFeeData?.paypalAmount + 100) *
                            employerFeeData?.paypalPercentage) /
                            100,
                        percentFee:
                          ((employerFeeData?.paypalAmount + 100) *
                            employerFeeData?.paypalPercentage) /
                          100,
                        percent: employerFeeData?.paypalPercentage,
                        originalAmt: employerFeeData?.paypalAmount + 100,
                      })}
                    </li>
                  </ul>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <b>
                    {t("pricingFees7", { amt: employerFeeData?.paypalAmount })}
                  </b>
                  :{" "}
                  {t("pricingFees3", {
                    percent: employerFeeData?.paypalMinPercentage,
                  })}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>:{" "}
                      {t("pricingFees5", {
                        finalAmt:
                          employerFeeData?.paypalAmount -
                          10 -
                          ((employerFeeData?.paypalAmount - 10) *
                            employerFeeData?.paypalMinPercentage) /
                            100,
                        percentFee:
                          ((employerFeeData?.paypalAmount - 10) *
                            employerFeeData?.paypalMinPercentage) /
                          100,
                        percent: employerFeeData?.paypalMinPercentage,
                        originalAmt: employerFeeData?.paypalAmount - 10,
                      })}
                    </li>
                  </ul>
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees19")}</p>
              <h3>{t("pricingFees24")} </h3>
              <ul>
                <li>
                  {t("pricingFees25", { amt: employerFeeData?.flatWithdrawal })}
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees26")}</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
