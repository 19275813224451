import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteIcon from "../../../Assets/Images/deleteVector.png"; // Import the delete icon
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import "./DeleteModal.css";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { deleteAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ open, handleClose, getMyProposals, proposals }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { t } = useTranslation();

  const handleCloseModal = () => {
    handleClose(); // Close the modal
  };

  const deleteMileStone = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await deleteAPI(`proposal/${proposals?._id}`, "", token);
      if (response.statusCode === 200) {
        LoaderHide();
        toast.success(t(response?.message));
        getMyProposals();
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  return (
    <Modal
      open={open}
      onClick={handleCloseModal}
      aria-labelledby="delete-modal"
      aria-describedby="delete-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 4,
        }}
      >
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <img src={close} alt="db" />
        </IconButton>
        <Box>
          <Box
            sx={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              py: 1,
            }}
          >
            <img className="deleteVec" src={DeleteIcon} alt="db" />
          </Box>
          <Typography
            className="employeeHead1"
            id="logout-modal-description"
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 32,
              textAlign: "center",
              py: 2,
              pb: 1,
            }}
            gutterBottom
          >
            {t("areYouSure")}
          </Typography>
          <Typography
            className="employeeHead"
            id="logout-modal-description"
            variant="body1"
            sx={{ fontSize: 18, textAlign: "center", display: "block" }}
            gutterBottom
          >
            {t("onceDeleted")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{
              backgroundColor: "#0B3013!important",
              fontSize: 16,
              borderRadius: 20,
              textTransform: "capitalize",
              px: 4,
              py: 1,
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={deleteMileStone}
            sx={{
              backgroundColor: "#0B3013!important",
              fontSize: 16,
              borderRadius: 20,
              textTransform: "capitalize",
              px: 6,
              py: 1,
            }}
            variant="contained"
          >
            {t("Yes")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
