import React, { useEffect, useState } from "react";
import "./EmployeeSaved.css";
import { Box, Typography, Rating, IconButton, Button } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api.js";
import { toast } from "react-toastify";
import NodataFound from "../../../Components/NodataFound/NodataFound.jsx";
import { useNavigate } from "react-router-dom";
import loader from "../../../Assets/Images/loader.svg";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import Markdown from "markdown-to-jsx";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function EmployeeSaved() {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [bookmarkedProjectsList, setBookmarkedProjectsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getAllBookMarkedProjects();
  }, []);

  const handleBookmarkClick = async (ev, project, index) => {
    ev.stopPropagation();
    LoaderShow();

    // const isBookmarked = bookmarkedProjects[index];
    // const updatedBookmarks = [...bookmarkedProjects];
    // updatedBookmarks[index] = !isBookmarked;
    // setBookmarkedProjects(updatedBookmarks);

    const apiEndpoint = "project/removebookmark";

    LoaderShow();

    try {
      const token = GetUserDetails().token;
      const dataToSend = { projectId: project?._id };
      const response = await postAPI(apiEndpoint, dataToSend, token);
      if (response.statusCode === 200) {
        setBookmarkedProjectsList((dt) => dt.filter((ele, i) => i != index));
        toast.success(t(response.message));
        LoaderHide();
      } else {
        toast.error(t(response.message));
        LoaderHide();
      }
    } catch (error) {
      toast.error(error.message);
      LoaderHide();
    }
    LoaderHide();
  };

  const getAllBookMarkedProjects = async (pageNumber = currentPage) => {
    LoaderShow();
    try {
      const token = GetUserDetails().token;
      const response = await getAPI(
        `project/get_bookmarks?page=${pageNumber}`,
        token
      );
      if (response.statusCode === 200) {
        setBookmarkedProjectsList(response.data);
        setTotalPages(response.pagination.totalPages);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getAllBookMarkedProjects(value);
  };

  const sendProjectDetails = (project) => {
    navigate(`/dashboard/employee/projectdetails?project_id=${project._id}`, {
      state: {
        project,
      },
    });
  };

  return (
    <section className="messageEmployee">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{}}>
        <Typography
          className="employeeHead1"
          variant="h4"
          sx={{ pb: 1, fontWeight: 600 }}
        >
          {t("MyBookmarks")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>

      <Box sx={{ my: 4, backgroundColor: "#fff", borderRadius: 4, py: 2 }}>
        <Box sx={{ p: 2.5, py: 1 }}>
          <Typography
            variant="h6"
            sx={{ color: "#000", fontWeight: 500, textDecoration: "none" }}
          >
            {t("BookmarkProjects")}
          </Typography>
        </Box>
        {bookmarkedProjectsList?.length === 0 ? (
          <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <NodataFound />
          </Box>
        ) : (
          bookmarkedProjectsList?.map((project, index) => (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => sendProjectDetails(project)}
            >
              <Box
                className="employeeMainHead flexColumnRespo"
                sx={{
                  borderTop: 1,
                  borderColor: "#dadbda",
                  p: 2.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {/* <Link to="/projectdetails"> */}
                  <Button>
                    <Typography
                      className="employeeHead"
                      variant="h5"
                      sx={{
                        color: "#000",
                        fontWeight: 500,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {project?.title}
                    </Typography>
                  </Button>
                  {/* </Link> */}
                </Box>
                <Box
                  className="priceHeadEmployee flexColumnRespo"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    className="catBtnTop"
                    variant="p"
                    sx={{ color: "#222" }}
                  >
                    {/* 6 bids */}
                  </Typography>
                  <Typography
                    className="priceHeadEmployee catBtnTop"
                    variant="h5"
                    sx={{ color: "#000", px: 2, fontWeight: 500 }}
                  >
                    ${project?.budget?.min} - ${project?.budget?.max}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ p: 2.5, pt: 0 }}>
                {/* <Typography
                  className="budgetHeadEmployee"
                  variant="p"
                  sx={{ fontWeight: 500, color: "#000", fontSize: 17 }}
                >
                  Budget ${project?.budget?.min} - {project?.budget?.max}{" "}
                  {project?.currency}
                </Typography> */}
              </Box>

              <Box sx={{ p: 2.5, pt: 0 }}>
                <Typography
                  className="priceHeadEmployee markdown"
                  variant="p"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 10 /* number of lines to show */,
                    lineClamp: 10,
                    WebkitBoxOrient: "vertical",
                  }}
                  sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
                >
                  <Markdown>{project?.description}</Markdown>
                </Typography>
              </Box>

              <Box sx={{ display: "flex", p: 2.5, flexWrap: "wrap", pt: 0 }}>
                {project?.skillsRequired?.map((skill, index) => (
                  <Box
                    className="priceHeadEmployee"
                    sx={{
                      width: "auto",
                      maxWidth: "fit-content",
                      backgroundColor: "#F7FDF9",
                      color: "#0B3013",
                      p: 1.4,
                      px: 3,
                      my: 1,
                      borderRadius: 20,
                    }}
                  >
                    {skill?.name}
                  </Box>
                ))}
              </Box>

              <Box
                className="flexColumnRespo"
                sx={{
                  p: 3,
                  pt: 0,
                  pb: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Rating
                    disabled
                    name="hover-feedback"
                    value={project?.user?.averageRating}
                    precision={0.5}
                    getLabelText={getLabelText}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {project?.user?.averageRating !== null && (
                    <Box sx={{ ml: 2 }}>
                      {project?.user?.averageRating?.toFixed(2)}
                    </Box>
                  )}
                </Box>

                <Box className="catBtnTop">
                  <Typography variant="p" sx={{ color: "#0B3013" }}>
                    {/* 32 minutes ago */}
                  </Typography>

                  <IconButton
                    onClick={(ev) => handleBookmarkClick(ev, project, index)}
                  >
                    {bookmarkedProjectsList[index] ? (
                      <BookmarkIcon sx={{ color: "#FFB800" }} /> // Fill color when bookmarked
                    ) : (
                      <BookmarkBorderIcon sx={{ color: "#0B3013" }} /> // Default color when not bookmarked
                    )}
                  </IconButton>
                </Box>
              </Box>
            </div>
          ))
        )}

        {/* <Box
          sx={{
            borderTop: 1,
            borderColor: "#dadbda",
            p: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="flexColumnRespo"

        >
          <Box>
            <Link to="/projectdetails">
              <Typography
                variant="h5"
                className="talentHeadingMain"
                sx={{ color: "#000", fontWeight: 500, textDecoration: "underline", cursor: "pointer" }}
              >
                Food Delivery Mobile App
              </Typography>
            </Link>

          </Box>
          <Box className="flexColumnRespo filterSec" sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="p" sx={{ color: "#222" }}>
              6 bids
            </Typography>
            <Typography
              className='priceHeadEmployee'
              variant="h5"
              sx={{ color: "#000", px: 2, fontWeight: 500 }}
            >
              $100 - $150
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2.5, pt: 0 }}>
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#000", fontSize: 17 }}
          >
            Budget $30 - 250 USD
          </Typography>
        </Box>

        <Box sx={{ p: 2.5, pt: 0 }}>
          <Typography
            variant="p"
            sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
          >
            I'm seeking an experienced and efficient app developer to
            create an innovative social networking application for iOS
            platform. Details about the target audience are currently
            confidential. Since the completion I'm seeking an
            experienced and efficient app developer to create more....
          </Typography>
        </Box>

        <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
          <Box
            className='skillText'
            sx={{
              width: "auto",
              maxWidth: "fit-content",
              backgroundColor: "#F7FDF9",
              color: "#0B3013",
              p: 1.8,
              px: 3,
              borderRadius: 20,
            }}
          >
            Figma
          </Box>
          <Box
            className='skillText'
            sx={{
              width: "auto",
              maxWidth: "fit-content",
              backgroundColor: "#F7FDF9",
              color: "#0B3013",
              p: 1.8,
              px: 3,
              borderRadius: 20,
            }}
          >
            Sketch
          </Box>
          <Box
            className='skillText'
            sx={{
              width: "auto",
              maxWidth: "fit-content",
              backgroundColor: "#F7FDF9",
              color: "#0B3013",
              p: 1.8,
              px: 3,
              borderRadius: 20,
            }}
          >
            HTML5
          </Box>
        </Box>

        <Box
          className="flexColumnRespo"
          sx={{
            p: 3,
            pt: 0,
            pb: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Rating
              name="hover-feedback"
              value={value}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55 }}
                  fontSize="inherit"
                />
              }
            />
            {value !== null && (
              <Box sx={{ ml: 2 }}>
                {labels[hover !== -1 ? hover : value]}
              </Box>
            )}
          </Box>

          <Box>
            <Typography variant="p" sx={{ color: "#0B3013" }}>
              32 minutes ago
            </Typography>

            <IconButton onClick={handleBookmarkClick}>
              {isBookmarked ? (
                <BookmarkIcon sx={{ color: "#FFB800" }} /> // Fill color when bookmarked
              ) : (
                <BookmarkBorderIcon sx={{ color: "#0B3013" }} /> // Default color when not bookmarked
              )}
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: 1,
            borderColor: "#dadbda",
            p: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="flexColumnRespo"

        >
          <Box>
            <Link to="/projectdetails">
              <Typography
                variant="h5"
                className="talentHeadingMain"
                sx={{ color: "#000", fontWeight: 500, textDecoration: "underline", cursor: "pointer" }}
              >
                Food Delivery Mobile App
              </Typography>
            </Link>

          </Box>
          <Box className="flexColumnRespo filterSec" sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="p" sx={{ color: "#222" }}>
              6 bids
            </Typography>
            <Typography
              className='priceHeadEmployee'
              variant="h5"
              sx={{ color: "#000", px: 2, fontWeight: 500 }}
            >
              $100 - $150
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2.5, pt: 0 }}>
          <Typography
            variant="p"
            sx={{ fontWeight: 500, color: "#000", fontSize: 17 }}
          >
            Budget $30 - 250 USD
          </Typography>
        </Box>

        <Box sx={{ p: 2.5, pt: 0 }}>
          <Typography
            variant="p"
            sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
          >
            I'm seeking an experienced and efficient app developer to
            create an innovative social networking application for iOS
            platform. Details about the target audience are currently
            confidential. Since the completion I'm seeking an
            experienced and efficient app developer to create more....
          </Typography>
        </Box>

        <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
          <Box
            className='skillText'
            sx={{
              width: "auto",
              maxWidth: "fit-content",
              backgroundColor: "#F7FDF9",
              color: "#0B3013",
              p: 1.8,
              px: 3,
              borderRadius: 20,
            }}
          >
            Figma
          </Box>
          <Box
            className='skillText'
            sx={{
              width: "auto",
              maxWidth: "fit-content",
              backgroundColor: "#F7FDF9",
              color: "#0B3013",
              p: 1.8,
              px: 3,
              borderRadius: 20,
            }}
          >
            Sketch
          </Box>
          <Box
            className='skillText'
            sx={{
              width: "auto",
              maxWidth: "fit-content",
              backgroundColor: "#F7FDF9",
              color: "#0B3013",
              p: 1.8,
              px: 3,
              borderRadius: 20,
            }}
          >
            HTML5
          </Box>
        </Box>

        <Box
          className="flexColumnRespo"
          sx={{
            p: 3,
            pt: 0,
            pb: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Rating
              name="hover-feedback"
              value={value}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55 }}
                  fontSize="inherit"
                />
              }
            />
            {value !== null && (
              <Box sx={{ ml: 2 }}>
                {labels[hover !== -1 ? hover : value]}
              </Box>
            )}
          </Box>

          <Box>
            <Typography variant="p" sx={{ color: "#0B3013" }}>
              32 minutes ago
            </Typography>

            <IconButton onClick={handleBookmarkClick}>
              {isBookmarked ? (
                <BookmarkIcon sx={{ color: "#FFB800" }} /> // Fill color when bookmarked
              ) : (
                <BookmarkBorderIcon sx={{ color: "#0B3013" }} /> // Default color when not bookmarked
              )}
            </IconButton>
          </Box>
        </Box> */}
      </Box>
      <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
        {bookmarkedProjectsList?.length > 0 ? (
          <CustomPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        ) : (
          ""
        )}
      </Box>
    </section>
  );
}
