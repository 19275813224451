import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import Paypal from "../../../../Assets/Images/Paypal.png";
import Stripe from "../../../../Assets/Images/Stripe.svg";
import Crypto from "../../../../Assets/Images/Crypto.png";
import { loadStripe } from "@stripe/stripe-js";
import loader from "../../../../Assets/Images/loader.svg";
import { getAPI, postAPI } from "../../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import { Form, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import CoinPayments from "../../HomeMembership/CoinPayments/CoinPayments";

const DonationModal = ({
  open,
  onClose,
  milestoneId,
  userId,
  isNew,
  totalFee,
  afterPaid,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [balance, setBalance] = useState(10);
  const [showAmtInput, setShowAmtInput] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState("Stripe");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [milestone, setMilestone] = useState("");
  const [additionalTaxes] = useState(15); // Additional taxes amount
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCoinPaymentDialog, setOpenCoinPaymentDialog] = useState(false);
  const [cryptoDetails, setCryptoDetails] = useState({});
  const [openPayPal, setOpenPayPal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const projectDetails = JSON.parse(localStorage.getItem("projectData")) || "";

  useEffect(() => {
    if (searchParams.get("payment_intent")) {
      afterPayment();
    }
  }, []);

  const afterPayment = async () => {
    LoaderShow();
    const transactionId = searchParams.get("payment_intent");
    const bl = Number(searchParams.get("balance"));
    const user = GetUserDetails();

    searchParams.delete("payment_intent");
    searchParams.delete("balance");
    setSearchParams(searchParams);

    const dataToSend = {
      userId: user?.userId || "",
      amount: bl,
      currency: "USD",
      paymentMethod: "stripe",
      transactionId: transactionId,
    };
    const response = await postAPI("donation/create", dataToSend, "");
    if (response.statusCode === 200) {
      toast.success(t("toast40"));
      afterPaid();
    }
    try {
    } catch (err) {
      console.log(err);
      toast.error(t(err.message) || t("toast9"));
    }
    LoaderHide();
  };

  useEffect(() => {
    if (!open) {
      setShowAmtInput(true);
      setOpenPayPal(false);
      setOpenCoinPaymentDialog(false);
      setBalance(10);
    }
  }, [open]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const initiateStripePayment = async () => {
    setLoading(true);
    try {
      // const stripe = await loadStripe('pk_test_51LNAXqSHRoKsJ0YdCsPcfRlrqrZRufKJ51lnvg7lEEknm1tIQp08z8Fir9hzZgxPvm57OHaEtmxlBn9hurjwB7yc00U7it2i0i');
      // await stripe.redirectToCheckout({
      //   lineItems: [{ price: 'price_1P3ckKSHRoKsJ0YdWJZOTMyM', quantity: 1 }],
      //   mode: 'payment',
      //   successUrl: 'http://localhost:3000/sendanoffer',
      //   cancelUrl: 'http://localhost:3000/sendanoffer',
      // });

      const stripe = await loadStripe(
        `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
      );

      const response = await fetch(
        "https://api.stripe.com/v1/checkout/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECERT_KEY}`,
          },
          body: new URLSearchParams({
            "payment_method_types[]": "card",
            "line_items[0][price_data][currency]": "usd",
            "line_items[0][price_data][product_data][name]":
              "Milestone Payment",
            "line_items[0][price_data][unit_amount]": Math.trunc(
              parseFloat(balance).toFixed(2) * 100
            ), // Stripe expects the amount in cents
            "line_items[0][quantity]": "1",
            mode: "payment",
            success_url: `${window.location.origin}/?payment_intent={CHECKOUT_SESSION_ID}&balance=${balance}`,
            // 'cancel_url': `http://localhost:3000/sendanoffer`,
          }).toString(),
        }
      );

      const session = await response.json();

      if (session.error) {
        throw new Error(session.error.message);
      }
      LoaderHide();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
    } catch (error) {
      LoaderHide();
      console.error("Error initiating Stripe payment:", error);
    }
  };

  const initiateCryptoPayment = async () => {
    setLoading(true);
    LoaderShow();
    try {
      const data = {
        amount: balance,
      };
      const response = await postAPI("coinpayment/donation-create", data, "");
      if (response?.payment) {
        setCryptoDetails(response?.payment);
        setOpenCoinPaymentDialog(true);
      }
    } catch (error) {
      console.error("Error initiating crypto payment:", error);
    }
    LoaderHide();
  };

  const closePayPalModal = () => {
    setOpenPayPal(false);
  };

  const handlePaymentSubmit = () => {
    LoaderShow();
    if (selectedValue === "Paypal") {
      // setOpenPayPal(true);
      // onClose();
      // LoaderHide();
    } else if (selectedValue === "Stripe") {
      initiateStripePayment();
    } else if (selectedValue === "Crypto") {
      initiateCryptoPayment();
    }
  };

  const createTransaction = async (details) => {
    LoaderShow();
    if (details.message === "completed") {
      // Handle successful transaction here
      setOpenCoinPaymentDialog(false);
      try {
        const user = GetUserDetails();

        const dataToSend = {
          userId: user?.userId || "",
          currency: "USDT",
          amount: balance,
          method: "crypto",
          transectionId: details?.id,
        };
        const token = GetUserDetails().token;

        const response = await postAPI("donation/create", dataToSend, token);
        if (response.statusCode === 200) {
          toast.success(t("toast40"));
          afterPaid();
          onClose();
        }
      } catch (error) {
        // toast.error(error.message)
      }
    }
    LoaderHide();
  };

  const nexPage = (ev) => {
    ev.preventDefault();
    if (balance <= 0) {
      setError(t("amtgreaterThan0"));
      return;
    }
    setShowAmtInput(false);
  };

  return (
    <>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      {openCoinPaymentDialog && (
        <CoinPayments
          open={openCoinPaymentDialog}
          onClose={() => setOpenCoinPaymentDialog(false)}
          coinsPayment={cryptoDetails}
          onComplete={createTransaction}
        />
      )}

      <>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="subscribe-modal-title"
          aria-describedby="subscribe-modal-description"
        >
          {showAmtInput ? (
            <>
              <Box
                className="employeeProjdetails"
                sx={{
                  position: "absolute",
                  width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "white",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 5,
                }}
              >
                <Box className="SubHeader">
                  <Typography
                    variant="h5"
                    id="subscribe-modal-title"
                    sx={{
                      borderBottom: 1,
                      py: 2,
                      pt: 0,
                      borderColor: "#E6E6E6",
                    }}
                    gutterBottom
                  >
                    {t("Payment")}

                    <CloseIcon
                      sx={{ cursor: "pointer", float: "right" }}
                      onClick={onClose}
                    />
                  </Typography>
                </Box>
                <form onSubmit={(ev) => nexPage(ev)}>
                  <FormControl
                    sx={{
                      mt: 3,
                    }}
                    fullWidth
                  >
                    <FormLabel
                      sx={{
                        display: "block",
                        textAlign: "start",
                        // py: 1.4,
                        mt: -1,
                        fontSize: 20,
                      }}
                    >
                      {t("enteramtUSD")}
                    </FormLabel>
                    <TextField
                      required
                      type="number"
                      variant="outlined"
                      min={0.01}
                      sx={{
                        background: "transparent",
                        borderColor: "#E0E0E0",
                        boxShadow: "none",
                      }}
                      fullWidth={true}
                      placeholder="Enter Amount"
                      id="perHourAmount"
                      name="perHourAmount"
                      onChange={(ev) => setBalance(Number(ev.target.value))}
                      value={balance}
                      error={!!error}
                      helperText={error}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    className="SignUpBtn"
                    sx={{
                      mt: 2,
                      textTransform: "capitalize",
                      py: "8px !important",
                      px: "24px !important",
                      width: "auto !important",
                      borderRadius: 12,
                      maxWidth: "auto !important",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t("Next")}
                  </Button>
                </form>
              </Box>
            </>
          ) : (
            <Box
              className="employeeProjdetails"
              sx={{
                position: "absolute",
                width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
              }}
            >
              <Box>
                <Box className="SubHeader">
                  <Typography
                    variant="h5"
                    id="subscribe-modal-title"
                    sx={{
                      borderBottom: 1,
                      py: 2,
                      pt: 0,
                      borderColor: "#E6E6E6",
                    }}
                    gutterBottom
                  >
                    {t("Payment")}

                    <CloseIcon
                      sx={{ cursor: "pointer", float: "right" }}
                      onClick={onClose}
                    />
                  </Typography>
                </Box>
                <Box className="SubHeader">
                  <Typography
                    fontWeight={500}
                    variant="h5"
                    id="subscribe-modal-title"
                    sx={{
                      borderBottom: 1,
                      py: 2,
                      pt: 0,
                      borderColor: "#E6E6E6",
                    }}
                    gutterBottom
                  >
                    {t("Balance")} : ${balance}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    mt: 4,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Paypal")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                            m: 0,
                            p: 0,
                          },
                        }}
                      />
                      <img src={Paypal} alt="Paypal" />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("paypalPaymentDes")}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Stripe")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                          },
                        }}
                      />
                      <img
                        src={Stripe}
                        alt="Stripe"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                      />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("stripePaymentDes")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Crypto")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                          },
                        }}
                      />
                      <img src={Crypto} alt="Crypto" />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("cryptoPaymentDes")}
                    </Typography>
                  </Box>
                </Box>
                {/* You can add more content or form inputs here */}
                <>
                  {selectedValue != "Paypal" ? (
                    <Button
                      variant="contained"
                      fullWidth={true}
                      sx={{
                        textTransform: "capitalize",
                        fontSize: 18,
                        bgcolor: "#0B3013!important",
                        color: "#fff",
                        borderRadius: 2,
                      }}
                      onClick={handlePaymentSubmit}
                    >
                      {t("pay")} ${balance.toFixed(2)}
                    </Button>
                  ) : (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{
                          pointerEvents: "none",
                        }}
                        sx={{
                          position: "relative",
                          zIndex: 10,
                          textTransform: "capitalize",
                          fontSize: 18,
                          bgcolor: "#0B3013!important",
                          color: "#fff",
                          borderRadius: 2,
                        }}
                        onClick={handlePaymentSubmit}
                      >
                        {t("pay")} ${balance.toFixed(2)}
                      </Button>
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          borderRadius: "8px",
                          overflow: "hidden",
                          zIndex: 8,
                        }}
                      >
                        <PayPalScriptProvider
                          options={{
                            "client-id":
                              process.env.REACT_APP_PAYPAL_CLIENT_KEY,
                            currency: "USD",
                          }}
                        >
                          <PayPalButtons
                            fundingSource={FUNDING.PAYPAL}
                            createOrder={(data, actions) => {
                              return actions.order.create({
                                purchase_units: [
                                  {
                                    amount: {
                                      value: balance,
                                    },
                                  },
                                ],
                                application_context: {
                                  // Disable shipping address collection
                                  shipping_preference: "NO_SHIPPING",
                                },
                              });
                            }}
                            onApprove={async (data, actions) => {
                              const details = await actions.order.capture();
                              console.log("details paypal=== --- ", details);

                              if (details.status === "COMPLETED") {
                                // Handle successful transaction here
                                try {
                                  const user = GetUserDetails();

                                  const dataToSend = {
                                    userId: user?.userId || "",
                                    amount: balance,
                                    currency: "USD",
                                    paymentMethod: "paypal",
                                    transactionId: details?.id,
                                  };
                                  console.log(dataToSend);

                                  const response = await postAPI(
                                    "donation/create",
                                    dataToSend,
                                    ""
                                  );
                                  console.log(response);
                                  if (response.statusCode === 200) {
                                    closePayPalModal();
                                    toast.success(t("toast40"));
                                    afterPaid();
                                    onClose();
                                  }
                                } catch (error) {
                                  toast.error(error.message);
                                }
                              }
                            }}
                          />
                        </PayPalScriptProvider>
                      </div>
                    </div>
                  )}
                </>
              </Box>
            </Box>
          )}
        </Modal>
      </>
    </>
  );
};

export default DonationModal;
