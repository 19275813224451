import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Chip,
  InputBase,
  Menu,
  InputAdornment,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormLabel,
  FormControl,
  TextField,
  Modal,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "../../../Assets/Images/resolvedVec.png"; // Import the delete icon

import close from "../../../Assets/Images/cancel-circle-half-dot.png";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import editICO from "../../../Assets/Images/Eye.png";
import tickICO from "../../../Assets/Images/tick.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { deleteAPI, getAPI, updateAPI } from "../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import loader from "../../../Assets/Images/loader.svg";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import AddAdvertisementDialog from "./AddAdvertisementDialog";
import "./AdvertisementManagement.css";
import AdvertisementRow from "./AdvertisementRow";
import { useTheme } from "@emotion/react";
import deleteVec from "../../../Assets/Images/deleteVector.png";

export default function AdvertisementManagement() {
  const [selectedAdvertisement, setSelectedAdvertisement] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editingSequence, setEditingSequence] = useState(false);
  const { t } = useTranslation();
  const [advertisements, setAdvertisements] = useState([]);
  const [dragAdvertisements, setDragAdvertisements] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [timeOutId, setTimeOutId] = useState(false);
  const [isEditing, setIsEditing] = useState(true);

  const [interval, setInterval] = useState("");
  const [error, setError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // useEffect(() => {
  //   if (isEditing) setDragAdvertisements(advertisements);
  // }, [isEditing]);

  const fetchSearchedAdvertisement = async (ev = {}, search = searchText) => {
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const response = await getAPI(`advertisement/getAll`, token);
        if (response.statusCode == 200) {
          setAdvertisements(
            response?.data?.advertisements?.sort(
              (a, b) => a?.order - b?.order
            ) || []
          );
          setDragAdvertisements(
            response?.data?.advertisements?.sort(
              (a, b) => a?.order - b?.order
            ) || []
          );
          setInterval(response?.data?.defaultInterval);
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    }
  };

  const fetchAdvertisement = async (ev = {}, search = searchText) => {
    LoaderShow();
    await fetchSearchedAdvertisement(ev, search);
    LoaderHide();
  };

  useEffect(() => {
    fetchAdvertisement();
  }, []);

  const handleSearchChange = (event) => {
    const txt = event.target.value;
    setTimeOutId(
      setTimeout(async () => {
        if (timeOutId) clearTimeout(timeOutId);
        await fetchSearchedAdvertisement({}, 1, selectedPaymentMethod, txt);
        setSearchText(txt);
      }, 500)
    );
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = async (paymentMethod) => {
    setAnchorEl(null);
    setSelectedPaymentMethod(paymentMethod);
    await fetchAdvertisement({}, 1, paymentMethod);
  };

  const clearFilter = async () => {
    if (selectedPaymentMethod) {
      await fetchAdvertisement({}, 1, "");
      setSelectedPaymentMethod("");
    }
  };

  const moveAdvertisement = (dragIndex, hoverIndex) => {
    setDragAdvertisements((array) => {
      const element = array.splice(dragIndex, 1)[0];
      array.splice(hoverIndex, 0, element);
      return array.map((ele, index) => {
        ele.order = index + 1;
        return ele;
      });
    });
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    // Extract the day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  };

  const undoChanges = () => {
    setDragAdvertisements([]);
  };

  useEffect(() => {
    if (advertisements.length != 0 && dragAdvertisements.length == 0)
      setDragAdvertisements(advertisements);
  }, [advertisements, dragAdvertisements]);

  const onEditClicked = (advertisement) => {
    setSelectedAdvertisement(advertisement);
    setAddModalOpen(true);
  };

  const renderDraggableAdvertisement = useCallback(
    (advertisement, index) => {
      return (
        <AdvertisementRow
          key={advertisement._id}
          advertisement={advertisement}
          index={index}
          length={advertisements.length}
          formatDate={formatDate}
          moveAdvertisement={moveAdvertisement}
          onEditClicked={onEditClicked}
          handleOpen={handleOpen}
        />
      );
    },
    [isEditing]
  );

  const [open, setOpen] = useState(false);

  const handleOpen = (advertisement) => {
    setSelectedAdvertisement(advertisement);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAdvertisement({});
  };

  const handleConfirmDelete = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (!token) return;
      const response = await deleteAPI(
        "advertisement/delete",
        { id: selectedAdvertisement?._id },
        token
      );
      if (response.statusCode == 200) {
        await fetchAdvertisement();
        handleClose();
        toast.success(t(response.message));
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const updateOrder = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const data = {
        orders: dragAdvertisements.map((ele) => ({
          id: ele._id,
          order: ele.order,
        })),
      };
      const res = await updateAPI("advertisement/update-order", data, token);
      if (res.statusCode == 200) {
        setAdvertisements(dragAdvertisements);
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const saveInterval = async () => {
    if (!interval && interval <= 0) {
      setError(t("plzEnterInterval"));
      return;
    }
    setError("");
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const res = await updateAPI(
        "advertisement/update-interval-admin",
        {
          defaultInterval: interval,
        },
        token
      );
      if (res?.statusCode == 200) {
        toast.success(t("toast19"));
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  return (
    <Box>
      <AddAdvertisementDialog
        open={addModalOpen}
        onClose={() => {
          setAddModalOpen(false);
          setSelectedAdvertisement({});
        }}
        save={fetchSearchedAdvertisement}
        orderStart={advertisements.length}
        onSave={fetchAdvertisement}
        advertisement={selectedAdvertisement}
      />
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
            {t("advts")}
          </Typography>
        </div>
        <Box
          sx={{
            mt: 3,
            backgroundColor: "white",
            borderRadius: "10px",
            border: 0,
            padding: 1,
            mb: 2,
            px: 3,
            py: 2.2,
          }}
        >
          <section>
            <Box p={1}>
              <Box
                width={"100%"}
                borderBottom={1}
                borderColor={"#E9E9E9"}
                pb={2}
              >
                <Typography variant="h6" fontWeight={600}>
                  {t("interval")}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                my={3}
                justifyContent={"start"}
              >
                <FormLabel fontFamily={"inherit"}>
                  {t("intervalInSec")}
                </FormLabel>
                <FormControl sx={{ my: 2 }} variant="outlined">
                  <TextField
                    onChange={(ev) => setInterval(ev.target.value)}
                    value={interval}
                    type="number"
                    min={10}
                    id="outlined-adornment-weight"
                    variant="outlined"
                    placeholder={t("intervalInSec")}
                    error={!!error}
                    helperText={error}
                  />
                </FormControl>

                <Button
                  onClick={saveInterval}
                  sx={{
                    display: "flex",
                    fontFamily: "inherit",
                    textTransform: "capitalize",
                    bgcolor: "#0a3114",
                    fontSize: 16,
                    color: "#fff",
                    borderRadius: 10,
                    mt: 4,
                    px: 5,
                    py: 1,
                    maxWidth: "fit-content",
                    "&:hover": {
                      bgcolor: "#0a3114",
                    },
                  }}
                >
                  {t("Save")}
                </Button>
              </Box>
            </Box>
          </section>
        </Box>

        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          {dragAdvertisements != advertisements ? (
            <>
              <Button
                // onClick={() => setDragAdvertisements(advertisements)}
                onClick={undoChanges}
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  ml: 1,
                  backgroundColor: "#0B3013",
                  color: "#fff",
                  borderRadius: "24px",
                  py: "9px",
                  px: "20px",
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                onClick={updateOrder}
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  ml: 1,
                  backgroundColor: "#0B3013",
                  color: "#fff",
                  borderRadius: "24px",
                  py: "9px",
                  px: "20px",
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                {t("Save")}
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setAddModalOpen(true)}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                ml: 1,
                backgroundColor: "#0B3013",
                color: "#fff",
                borderRadius: "24px",
                py: "9px",
                px: "20px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              {t("add")}
            </Button>
          )}
        </div>
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            mt: 4,
          }}
        >
          {advertisements.length > 0 ? (
            <>
              <div style={{ overflow: "auto" }}>
                <Table
                  aria-label="dispute case management table"
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0",
                    marginTop: "0px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {t("priority")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {t("img")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {t("startDate")}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {t("endDate")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {t("link")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {t("Status")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {t("Action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dragAdvertisements.map((advertisement, index) =>
                      renderDraggableAdvertisement(advertisement, index)
                    )}
                  </TableBody>
                </Table>
              </div>
              {/* <Box
                sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}
              >
                {showPagination && (
                  <CustomPagination
                    count={totalPages}
                    onChange={fetchAdvertisement}
                  />
                )}
              </Box> */}
            </>
          ) : (
            <NodataFound />
          )}
        </Box>

        {/* <Typography
          variant="h5"
          sx={{ py: 4, fontWeight: 600, textAlign: "right" }}
        >
          Crypto Donation : ${totalDonation?.cryptoTotalAmount || 0}
        </Typography> */}
      </>

      {/* Delete Confirmation Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 4,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="Close" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={deleteVec} alt="Delete Vector" />
            </Box>
            <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("areYouSure")}
            </Typography>
            <Typography
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {t("actionunDone")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
              onClick={handleConfirmDelete}
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
