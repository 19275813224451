import { useTheme } from "@emotion/react";
import {
  Button,
  FormLabel,
  Grid,
  IconButton,
  Modal,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { postAPI } from "../../Services/Api";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import styled from "@emotion/styled";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import close from "../../Assets/Images/cancel-circle-half-dot.png";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };
  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function ReviewDialog({ open, onClose, dispute, onSubmit }) {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [text, setText] = useState("");
  const [valuerating, setValueRating] = useState("");

  const [errorMessages, setErrorMessages] = useState({
    textReview: "",
    valuerating,
  });

  const handleTextArea = (event) => {
    const value = event.target.value;
    setText(value);
    setErrorMessages({
      ...errorMessages,
      textReview: value.length < 5 ? t("ReviewShouldbe5") : "",
    });
  };

  const handleStar = (event) => {
    const value = event.target.value;
    setValueRating(value);
    // setErrorMessages({
    //   ...errorMessages,
    //   valuerating:
    //     event?.target?.value?.length === 0 ? t("PleaseGiveStar") : "",
    // });
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (text.length < 5) {
      errors.textReview = t("ReviewShouldbe5");
      isValid = false;
    }

    // if (valuerating.length === 0) {
    //   errors.valuerating = t("PleaseGiveStar");
    //   isValid = false;
    // }

    setErrorMessages(errors);
    return isValid;
  };

  const submitReview = async () => {
    LoaderShow();
    if (validateForm()) {
      try {
        const userId = GetUserDetails().userId;
        const token = GetUserDetails().token;

        const dataToSend = {
          userId:
            dispute?.against?._id == userId
              ? dispute?.raisedBy?._id
              : dispute?.against?._id,
          reviewerId: userId,
          projectId: dispute?.projectId?._id,
          rating: valuerating || "0",
          comment: text,
          disputeId: dispute._id,
        };
        const response = await postAPI(
          "dispute/review/create",
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          toast.success(t(response.message));
          onClose();
          onSubmit();
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    LoaderHide();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="finish-modal-title"
      aria-describedby="finish-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 4,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <img src={close} alt="Close" />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 2,
            px: 3,
            alignItems: "center",
            borderBottom: 1,
            borderColor: "#d8d8d8",
          }}
        >
          <Typography variant="h6">{t("LeaveAReview")}</Typography>
          {/* <IconButton onClick={handleCloseRating}>
                <img src={close} alt="db" />
              </IconButton> */}
        </Box>
        <Box sx={{ px: 4 }}>
          <Typography
            variant="p"
            sx={{
              textAlign: "center",
              fontSize: 17,
              color: "#000",
              display: "block",
              py: 2,
            }}
          >
            {t("HowToYouRating")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Rating
            name="simple-controlled"
            value={valuerating}
            onChange={handleStar}
            sx={{ fontSize: "36px" }} // Adjust the font size as needed
          />
        </Box>
        {errorMessages.valuerating && (
          <Typography
            variant="p"
            sx={{ color: "red", textAlign: "center", marginLeft: "32px" }}
          >
            {errorMessages.valuerating}
          </Typography>
        )}
        <Grid container sx={{ px: 4 }}>
          <Grid item xs={12}>
            <Box>
              <FormLabel
                sx={{
                  display: "block",
                  textAlign: "start",
                  py: 2,
                  fontWeight: "600",
                  color: "#000",
                  fontSize: 17,
                }}
              ></FormLabel>
              <Textarea
                value={text}
                onChange={handleTextArea}
                sx={{
                  width: "100%!important",
                  border: 1,
                  borderColor: "#cbcbcb",
                  borderRadius: 1,
                }}
                minRows={6}
                maxRows={6}
                placeholder={t("WriteAReview")}
              />
              {errorMessages.textReview && (
                <Typography variant="p" sx={{ color: "red" }}>
                  {errorMessages.textReview}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            my: 4,
            px: 4,
          }}
        >
          <Button
            fullWidth={true}
            variant="contained"
            onClick={submitReview}
            sx={{
              backgroundColor: "#0B3013!important",
              fontSize: 16,
              borderRadius: 3,
              textTransform: "capitalize",

              py: 1,
            }}
          >
            {t("SubmitReview")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
