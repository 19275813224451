import { IconButton, TableCell, TableRow } from "@mui/material";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import editICO from "../../../Assets/Images/EditIcon.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import { useTranslation } from "react-i18next";

export default function AdvertisementRow({
  advertisement,
  index,
  length,
  formatDate,
  moveAdvertisement,
  onEditClicked,
  handleOpen,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "TableRow",
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveAdvertisement(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "TableRow",
    item: () => {
      return { id: advertisement._id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  const cursor = "move";
  drag(drop(ref));

  return (
    <TableRow style={{ opacity, cursor }} ref={ref} data-handler-id={handlerId}>
      <TableCell
        align="left"
        sx={{
          padding: "24px",
          fontFamily: "inherit",
          fontSize: "16px",
          borderBottom: index === length - 1 ? "0" : "1px solid #e0e0e0",
        }}
      >
        {index + 1 || "-"}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          padding: "24px",
          fontFamily: "inherit",
          fontSize: "16px",
          borderBottom: index === length - 1 ? "0" : "1px solid #e0e0e0",
        }}
      >
        <img
          style={{ height: "150px" }}
          src={advertisement?.url}
          alt={advertisement?.altText}
        />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          padding: "24px",
          fontFamily: "inherit",
          fontSize: "16px",
          borderBottom: index === length - 1 ? "0" : "1px solid #e0e0e0",
        }}
      >
        {formatDate(advertisement?.displayStart)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          padding: "24px",
          fontFamily: "inherit",
          fontSize: "16px",
          borderBottom: index === length - 1 ? "0" : "1px solid #e0e0e0",
        }}
      >
        {formatDate(advertisement?.displayEnd)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          padding: "24px",
          fontFamily: "inherit",
          fontSize: "16px",
          borderBottom: index === length - 1 ? "0" : "1px solid #e0e0e0",
        }}
      >
        {advertisement?.link ? (
          <a href={advertisement?.link} target="_blank">
            {advertisement?.link}
          </a>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          padding: "24px",
          fontFamily: "inherit",
          fontSize: "16px",
          borderBottom: index === length - 1 ? "0" : "1px solid #e0e0e0",
        }}
      >
        {advertisement?.isActive ? t("ActiveOne") : t("Inactive")}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          padding: "24px",
          fontFamily: "inherit",
          fontSize: "16px",
          borderBottom: index === length - 1 ? "0" : "1px solid #e0e0e0",
        }}
      >
        <IconButton
          sx={{
            backgroundColor: "#f7fdf9",
            borderRadius: "4px",
            p: 0.5,
          }}
          color="primary"
          aria-label="edit"
          onClick={() => onEditClicked(advertisement)}
        >
          <img src={editICO} alt="Edit" />
        </IconButton>
        <IconButton
          onClick={(ev) => handleOpen(advertisement)}
          sx={{
            backgroundColor: "#f7fdf9",
            borderRadius: "4px",
            mx: 1,
            p: 0.5,
          }}
          aria-label="delete"
        >
          <img src={deleteICO} alt="Delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
