import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import loader from "../../../Assets/Images/loader.svg";
import { useEffect, useState } from "react";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI, postAPI } from "../../../Services/Api";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery } from "@mui/system";
import { EMPLOYEE_ROLE } from "../../../Helpers/roles";
import editICO from "../../../Assets/Images/Eye.png";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AdminDisputeTable({ setDispute }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [disputeList, setDisputeList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [timeOutId, setTimeOutId] = useState(false);
  const [tab, setTab] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [showPagination, setShowPagination] = useState(true);

  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    getDisputeList();
  }, []);

  const getDisputeList = async (
    ev = {},
    pagenumber = 1,
    filter = tab == 0 ? "raisedBy" : "against",
    search = searchText,
    status = selectedStatus
  ) => {
    LoaderShow();
    await getSearchedDisputeList(ev, pagenumber, filter, search, status);
    LoaderHide();
  };

  const getSearchedDisputeList = async (
    ev = {},
    pagenumber = 1,
    filter = tab == 0 ? "raisedBy" : "against",
    search = searchText,
    status = selectedStatus
  ) => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI(
          `dispute/admin-list?page=${pagenumber}&search=${search}&status=${status}`,
          token
        );
        if (res?.statusCode == 200) {
          setDisputeList(res?.data);
          setTotalPages(res?.pagination?.totalPages || 1);
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
  };

  function formatDateToDDMMYYYY(dt) {
    const date = new Date(dt);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const downloadInvoice = async (pdfUrl) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";
    link.download = "invoice.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
    setShowPagination(false);
    getDisputeList({}, 1, newValue == 0 ? "raisedBy" : "against");
    setShowPagination(true);
  };

  const handleSearchChange = (event) => {
    if (timeOutId) clearTimeout(timeOutId);
    setTimeOutId(
      setTimeout(async () => {
        setShowPagination(false);
        setSearchText(event.target.value);
        await getSearchedDisputeList(
          {},
          1,
          tab == 0 ? "raisedBy" : "against",
          event.target.value
        );
        setShowPagination(true);
      }, 500)
    );
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = async (status) => {
    setAnchorEl(null);
    setSelectedStatus(status);
    setShowPagination(false);
    // await getDisputeList();
    await getDisputeList(
      {},
      1,
      tab == 0 ? "raisedBy" : "against",
      searchText,
      status
    );
    setShowPagination(true);
  };

  const clearFilter = async () => {
    if (selectedStatus) {
      setShowPagination(false);
      // await fetchDonations({}, 1, "");
      await getDisputeList(
        {},
        1,
        tab == 0 ? "raisedBy" : "against",
        searchText,
        ""
      );
      setSelectedStatus("");
      setShowPagination(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "10px",
          alignItems: "center",
          my: 3,
          justifyContent: "end",
        }}
      >
        <InputBase
          placeholder={t("SearchText")}
          onChange={handleSearchChange}
          sx={{
            maxWidth: "400px",
            ml: 1,
            flex: 1,
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: "24px",
            py: "8px",
            px: "20px",
            justifyContent: "flex-end",
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#000" }} />
            </InputAdornment>
          }
        />
        <IconButton
          aria-label="filter"
          onClick={handleMenuOpen}
          sx={{
            textTransform: "capitalize",
            fontSize: "16px",
            ml: 2,
            backgroundColor: "#0B3013",
            color: "#fff",
            borderRadius: "24px",
            py: "9px",
            px: "20px",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#0B3013", // Keep the same color as default
            },
          }}
        >
          {selectedStatus ? t(selectedStatus) : t("Sort")}
          <ExpandMoreIcon sx={{ ml: 1 }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {["Under monitoring", "Resolved by admin", "Rejected by admin"].map(
            (ele, index) => (
              <MenuItem
                style={{ paddingLeft: "38px", paddingRight: "38px" }}
                onClick={(ev) => handleFilterClick(ele)}
              >
                {t(ele)}
              </MenuItem>
            )
          )}
        </Menu>
        <Button
          onClick={clearFilter}
          sx={{
            textTransform: "none",
            fontSize: "16px",
            ml: 1,
            backgroundColor: "#0B3013",
            color: "#fff",
            borderRadius: "24px",
            py: "9px",
            px: "20px",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#0B3013", // Keep the same color as default
            },
          }}
        >
          {t("Clear")}
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        {/* <Tabs
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : "off"}
          allowScrollButtonsMobile={isMobile}
          textColor="#222222"
          sx={{
            pt: 1,
            mx: 2,
            borderBottom: 1,
            borderColor: "#dbdbdb",
            "& .MuiTabs-indicator": {
              backgroundColor: "#000",
            },
          }}
          value={tab}
          onChange={handleChange}
          // variant="scrollable"
          // scrollButtons="auto"
        >
          <Tab
            sx={{
              textTransform: "capitalize",
              pl: 0,
              px: 1,
              mx: 1,
              fontSize: 17,
              fontFamily: "inherit",
            }}
            label="Dispute raised by you"
          />
          <Tab
            sx={{
              textTransform: "capitalize",
              pl: 0,
              px: 1,
              mx: 1,
              fontSize: 17,
              fontFamily: "inherit",
            }}
            label="Dispute against you"
          />
        </Tabs> */}
        {disputeList.length > 0 && (
          <div style={{ padding: "10px 20px 0px 20px", overflow: "auto" }}>
            <Table
              aria-label="user management table"
              sx={{ borderCollapse: "separate", borderSpacing: "0" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("TitleContact")}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("compName")}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("defName")}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("ProjectName")}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("MileStoneName")}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("amt")}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("Status")}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {t("Action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {disputeList.map((dispute, index) => (
                  <TableRow
                    sx={{ cursor: "pointer" }}
                    onClick={() => setDispute(dispute)}
                    key={dispute._id}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {dispute?.title || "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {dispute?.raisedBy?.firstName|| "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {dispute?.against?.firstName || "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {dispute?.projectId?.title || "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {dispute?.milestoneId?.title || "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      ${dispute?.amount || "0"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {dispute?.status ? (
                        <Chip
                          label={
                            dispute?.[
                              i18n.language == "en"
                                ? "status"
                                : `status_${i18n.language}`
                            ]
                          }
                          sx={{
                            cursor: "default",
                            textTransform: "capitalize",
                            py: 1.1,
                            px: 1,
                            backgroundColor: "#2ab95f",
                            color: "#fff",
                            borderRadius: "30px",
                            fontSize: "14px",
                          }}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === disputeList.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "#f7fdf9",
                          borderRadius: "4px",
                          mx: 1,
                          p: 0.5,
                        }}
                        color="primary"
                        aria-label="view"
                        onClick={() => setDispute(dispute)} // Opens the DisputeCaseView
                      >
                        <img src={editICO} alt="View" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        {disputeList.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}>
            {showPagination && (
              <CustomPagination count={totalPages} onChange={getDisputeList} />
            )}
          </Box>
        )}
        {disputeList.length <= 0 && <NodataFound />}
      </TableContainer>
    </>
  );
}
