import React, { useState, useEffect } from "react";
import "./BrowseJobsEmployee.css";
import BrowseJobsBanner from "../../../Assets/Images/BrowseJobsBanner.png";
import {
  Box,
  Button,
  Container,
  Grid,
  useMediaQuery,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Rating,
  IconButton,
  InputLabel,
  Select,
} from "@mui/material";
import Markdown from "markdown-to-jsx";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { getAPI, postAPI } from "../../../Services/Api.js";
import StarIcon from "@mui/icons-material/Star";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon properly
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { Link, useSearchParams } from "react-router-dom";
import loader from "../../../Assets/Images/loader.svg";
import { useNavigate } from "react-router-dom";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import NodataFound from "../../../Components/NodataFound/NodataFound.jsx";
import Dropdown from "../../../Components/Dropdown/Dropdown.jsx";
import { countries } from "../../../Helpers/common_constants.js";
import Signup from "../../../Auth/Signup/Signup.jsx";
import Signin from "../../../Auth/Signin/Signin.jsx";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none", // Remove box shadow from accordion
});
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "#F7F7F7",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function BrowseJobsEmployee() {
  const { t, i18n } = useTranslation();

  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("is_signup")) {
      setJoinModalOpen(true);
    }
    if (searchParams.get("is_signin")) {
      setLoginModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (joinModalOpen) {
      setSearchParams({ is_signup: true });
    } else if (searchParams.get("is_signup")) {
      searchParams.delete("is_signup");
      setSearchParams(searchParams);
    }
  }, [joinModalOpen]);

  useEffect(() => {
    if (loginModalOpen) {
      setSearchParams({ is_signin: true });
    } else if (searchParams.get("is_signin")) {
      searchParams.delete("is_signin");
      setSearchParams(searchParams);
    }
  }, [loginModalOpen]);

  const [expanded, setExpanded] = useState("panel1"); // Set the first panel to be expanded by default
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [rateType, setRateType] = useState("hourly"); // Define rateType state
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectCategory = (category) => {
    setAnchorEl(null);
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (selectedCategory) {
      getFilteredEmployeeList({}, "categories");
    }
  }, [selectedCategory]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleRateTypeChange = (event) => {
    setRateType(event.target.value);
    getFilteredEmployeeList(event.target.value, "paymentType");
  };
  // const handleBookmarkClick = () => {
  //   setIsBookmarked(!isBookmarked);
  // };

  const [formData, setFormData] = useState({
    budget: "",
    location: "",
  });

  const handleBudget = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      budget: value,
    }));

    getFilteredEmployeeList(value, "estimatedBudget");
  };

  const handleLocation = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: value,
    }));

    getFilteredEmployeeList(value, "location");
  };

  const budgetValues = [100, 200, 300, 400, 500, 600, 700, 800, 900];

  useEffect(() => {
    LoaderShow();
    getAllProjectListing();
    getProjectData();
  }, []);

  const [dropValues, setDropValues] = useState();

  const getProjectData = async () => {
    try {
      LoaderShow();
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setDropValues(response?.data);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      console.log("error===", error);
    }
  };

  const navigate = useNavigate();

  const getAllProjectListing = async (pageNumber) => {
    LoaderShow();
    try {
      LoaderShow();
      const dataToSend = { keyword: "" };
      const response = await postAPI(`project/search-projects`, dataToSend);
      if (response.statusCode === 200) {
        LoaderHide();
        setProjectList(response.data);
        setTotalPages(response?.pagination?.totalPages);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getAllProjectListing(value);
  };

  // const handleFavoriteClick = () => {
  //   setIsFavorite(!isFavorite);
  // };

  const navigateToPraposeEmployer = (user) => {
    navigate("/reviewratings", {
      state: {
        user,
      },
    });
  };

  const [query, setQuery] = useState("");

  const handleSearch = async () => {
    // if (!query.trim()) {
    //   toast.error("Please add text");
    //   return;
    // }

    try {
      // if (query === "") {
      //   toast.error("Please add text")
      // } else {
      LoaderShow();

      const dataToSend = { keyword: query };
      const response = await postAPI(`project/search-projects`, dataToSend);
      if (response.statusCode === 200) {
        setProjectList(response?.data);
        setTotalPages(response?.pagination?.totalPages);
        LoaderHide();
        setQuery(""); // Reset the query state
        document.getElementById("search-query").value = "";
        if (response?.data?.length === 0) {
          // getAllProjectListing()
        }
      }
      // }
    } catch (error) {
      LoaderHide();
      console.error("Error fetching data: ", error);
    }
  };

  const getFilteredEmployeeList = async (filters, filterType) => {
    try {
      LoaderShow();
      let dataToSend;

      if (filterType === "paymentType") {
        dataToSend = {
          paymentType: filters,
        };
      } else if (filterType === "estimatedBudget") {
        dataToSend = {
          estimatedBudget: filters,
        };
      } else if (filterType === "location") {
        dataToSend = {
          location: filters,
        };
      } else if (filterType == "categories") {
        dataToSend = {
          category: selectedCategory._id,
        };
      }

      const response = await postAPI("project/filter?page=1", dataToSend);
      if (response.statusCode === 200) {
        LoaderHide();
        setProjectList(response.data);
        setTotalPages(response?.pagination?.totalPages);
        // handleFilterModalClose()
      }
    } catch (error) {
      LoaderHide();
      setProjectList([]);
      // handleFilterModalClose()
      toast.error(error.message);
      // Handle error (e.g., show a toast message)
      console.error("Error fetching filtered employees:==", error);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [bookmarkedProjects, setBookmarkedProjects] = useState([]);

  useEffect(() => {
    // Assuming each project has an `isBookmarked` property to initialize the state
    const initialBookmarks = projectList?.map(
      (project) => project.isBookmarked
    );
    setBookmarkedProjects(initialBookmarks);
  }, [projectList]);

  const handleBookmarkClick = async (project, index) => {
    LoaderShow();

    const isBookmarked = bookmarkedProjects[index];
    const updatedBookmarks = [...bookmarkedProjects];
    updatedBookmarks[index] = !isBookmarked;
    setBookmarkedProjects(updatedBookmarks);

    const apiEndpoint = isBookmarked
      ? "project/removebookmark"
      : "project/bookmark";

    LoaderShow();

    try {
      const token = GetUserDetails().token;
      const dataToSend = { projectId: project?._id };
      const response = await postAPI(apiEndpoint, dataToSend, token);
      if (response.statusCode === 200) {
        toast.success(t(response.message));
        LoaderHide();
      } else {
        toast.error(t(response.message));
        LoaderHide();
      }
    } catch (error) {
      toast.error(error.message);
      LoaderHide();
    }
    LoaderHide();
  };

  const sendProjectDetails = (project) => {
    // navigate("/projectdetails", {
    //   state: {
    //     project,
    //   },
    // });
    setLoginModalOpen(true);
  };

  const openSignupModal = () => {
    setJoinModalOpen(true);
    setLoginModalOpen(false);
  };

  const openSigninModal = () => {
    setJoinModalOpen(false);
    setLoginModalOpen(true);
  };

  const handleCloseJoinModal = () => {
    setJoinModalOpen(false);
  };

  const handleCloseLoginModal = () => {
    setLoginModalOpen(false);
  };

  return (
    <section className="BrowseJobsEmployee">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Container maxWidth="xl">
        <Box className="padBotZero catBtnTop padTopZero" sx={{ py: 10 }}>
          <Box sx={{ position: "relative" }}>
            <img
              src={BrowseJobsBanner}
              className="bannerImageSize"
              alt="BrowseJobsBanner"
            />
            <Box
              className="DesignAbs"
              sx={{
                position: "absolute",
                top: "55%",
                width: "100%",
                maxWidth: "50%",
                left: 0,
                transform: "translateY(-50%)",
                textAlign: "right",
                color: "white",
                paddingLeft: 6, // Add padding to separate text from the image
              }}
            >
              <Typography
                sx={{
                  textAlign: "start",
                  py: 2,
                  typography: { xl: "h3", lg: "h3", md: "h3", xs: "h8" },
                }}
                className="designBigHead"
              >
                {t("DesignCreative")}
              </Typography>
              <Typography
                className="dNoneRespo"
                variant="p"
                sx={{
                  color: "#fff",
                  textAlign: "start",
                  fontSize: 18,
                  fontWeight: 300,
                  display: "block",
                }}
              >
                {t("Give")}
              </Typography>
              <Box
                sx={{
                  display: { xs: "none", md: "block" }, // Hide on extra-small screens
                }}
                className="mainCustomCat"
              >
                <Grid container>
                  <Grid
                    md={5}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <SearchIcon sx={{ color: "#000", fontSize: 36 }} />{" "}
                    {/* Render the SearchIcon */}
                    <input
                      id="search-query"
                      type="text"
                      className="catTextBox"
                      placeholder={t("WhatLook")}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    md={4}
                    lg={4}
                    xl={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          open ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        disableElevation
                        onClick={handleClick}
                        sx={{
                          textTransform: "capitalize",
                          color: "#222222",
                          fontSize: 16,
                          marginLeft: 0,
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {selectedCategory?.[
                          i18n.language == "en"
                            ? "name"
                            : `name_${i18n.language}`
                        ] || t("Choose")}
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock // Add disableScrollLock prop here
                      >
                        {(dropValues?.categories || []).map((ele) => (
                          <MenuItem
                            key={ele._id}
                            onClick={(ev) => selectCategory(ele)}
                            disableRipple
                          >
                            {
                              ele?.[
                                i18n.language == "en"
                                  ? "name"
                                  : `name_${i18n.language}`
                              ]
                            }
                          </MenuItem>
                        ))}
                      </StyledMenu>
                    </div>
                  </Grid>
                  <Grid
                    md={3}
                    lg={2}
                    xl={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={handleSearch} className="SearchBtnCat">
                      {t("SearchText")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ pb: 10 }}>
          <Grid container spacing={3} className="filterSec">
            {" "}
            {/* Add spacing prop here */}
            <Grid item md={8} lg={4} xl={4}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 2 }}>
                  <Typography
                    variant="h6"
                    className="langText"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {t("Filter")}
                  </Typography>
                </Box>
                <Box>
                  <div>
                    <StyledAccordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography
                          variant="p"
                          sx={{ fontWeight: 500, color: "#000" }}
                        >
                          {t("ProjectType")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="rate-type"
                            name="rate-type"
                            value={rateType}
                            onChange={handleRateTypeChange}
                          >
                            <FormControlLabel
                              value="Hourly Rate"
                              control={
                                <Radio sx={{ color: "#0B3013!important" }} />
                              }
                              label={t("Hourly")}
                            />
                            <FormControlLabel
                              value="Fixed Price"
                              control={
                                <Radio sx={{ color: "#0B3013!important" }} />
                              }
                              label={t("Fixed")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </AccordionDetails>
                    </StyledAccordion>

                    <StyledAccordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography variant="p" sx={{ color: "#000" }}>
                          {t("BudgetOne")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Dropdown
                          value={formData.budget}
                          onChange={handleBudget}
                          options={dropValues?.estimatEnumValues?.map(
                            (value) => ({
                              value,
                              label: `${value?.name}`,
                            })
                          )}
                          label="Price"
                        />
                      </AccordionDetails>
                    </StyledAccordion>
                    <StyledAccordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        <Typography variant="p" sx={{ color: "#000" }}>
                          {t("Location")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl fullWidth>
                          <InputLabel id="gender-select-label">
                            {t("Country")}
                          </InputLabel>
                          <Select
                            labelId="gender-select-label"
                            id="gender-select"
                            value={formData?.location}
                            label="Country"
                            onChange={handleLocation}
                          >
                            {countries?.map((option) => (
                              <MenuItem key={option.label} value={option.label}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </AccordionDetails>
                    </StyledAccordion>

                    <Box sx={{ my: 3 }}>
                      {/* <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          textTransform: "capitalize",
                          bgcolor: "#0a3114",
                          fontSize: 16,
                          color: "#fff",
                          borderRadius: 10,
                          px: 5,
                          py: 1,
                          "&:hover": {
                            bgcolor: "#0a3114",
                          },
                        }}
                        endIcon={<ArrowOutwardIcon color="#fff" />}
                      >
                        Clear
                      </Button>{" "} */}
                    </Box>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} lg={8} xl={8}>
              <Box>
                <Box>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      py: 3,
                      borderRadius: 10,
                      border: 1,
                      borderColor: "#e3e3e3",
                    }}
                  >
                    <Box
                      className="filterTop"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // borderBottom: 1,
                        // borderColor: "#dadbda",
                        p: 2.5,
                        pt: 0,

                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          className="langText  "
                          sx={{ fontWeight: 500, color: "#000" }}
                        >
                          {t("Top")}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          className="langText "
                          variant="p"
                          sx={{
                            color: "#000",
                            fontSize: 17,
                            fontWeight: 500,
                            display: "block",
                            px: 2,
                          }}
                        >
                          {/* Sort by */}
                        </Typography>
                        {/* <div>
                          <Button
                            className="filterDrop"
                            sx={{
                              backgroundColor: "#F7F7F7!important",
                              color: "#000",
                              fontSize: 17,
                              textTransform: "capitalize",
                              borderRadius: 12,
                            }}
                            id="demo-customized-button1"
                            aria-controls={
                              open ? "demo-customized-menu1" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Latest
                          </Button>
                          <StyledMenu
                            id="demo-customized-menu1"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button1",
                            }}
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={handleClose} disableRipple>
                              Default
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                              Duplicate
                            </MenuItem>
                          </StyledMenu>
                        </div> */}
                      </Box>
                    </Box>

                    {/* <Box
                      className="employeeMainHead flexColumnRespo"
                      sx={{
                        borderTop: 1,
                        borderColor: "#dadbda",
                        p: 2.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Link to="/projectdetails">
                          <Typography
                            className="employeeHead"
                            variant="h5"
                            sx={{
                              color: "#000",
                              fontWeight: 500,
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Food Delivery Mobile App
                          </Typography>
                        </Link>
                      </Box>
                      <Box
                        className="priceHeadEmployee flexColumnRespo"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className="catBtnTop"
                          variant="p"
                          sx={{ color: "#222" }}
                        >
                          6 bids
                        </Typography>
                        <Typography
                          className="priceHeadEmployee catBtnTop"
                          variant="h5"
                          sx={{ color: "#000", px: 2, fontWeight: 500 }}
                        >
                          $100 - $150
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ p: 2.5, pt: 0 }}>
                      <Typography
                        className="budgetHeadEmployee"
                        variant="p"
                        sx={{ fontWeight: 500, color: "#000", fontSize: 17 }}
                      >
                        Budget $30 - 250 USD
                      </Typography>
                    </Box>

                    <Box sx={{ p: 2.5, pt: 0 }}>
                      <Typography
                        className="priceHeadEmployee"
                        variant="p"
                        sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
                      >
                        I'm seeking an experienced and efficient app developer
                        to create an innovative social networking application
                        for iOS platform. Details about the target audience are
                        currently confidential. Since the completion I'm seeking
                        an experienced and efficient app developer to create
                        more....
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
                      <Box
                        className="priceHeadEmployee"
                        sx={{
                          width: "auto",
                          maxWidth: "fit-content",
                          backgroundColor: "#F7FDF9",
                          color: "#0B3013",
                          p: 1.4,
                          px: 3,
                          borderRadius: 20,
                        }}
                      >
                        Figma
                      </Box>
                      <Box
                        className="priceHeadEmployee"
                        sx={{
                          width: "auto",
                          maxWidth: "fit-content",
                          backgroundColor: "#F7FDF9",
                          color: "#0B3013",
                          p: 1.4,
                          px: 3,
                          borderRadius: 20,
                        }}
                      >
                        Sketch
                      </Box>
                      <Box
                        className="priceHeadEmployee"
                        sx={{
                          width: "auto",
                          maxWidth: "fit-content",
                          backgroundColor: "#F7FDF9",
                          color: "#0B3013",
                          p: 1.4,
                          px: 3,
                          borderRadius: 20,
                        }}
                      >
                        HTML5
                      </Box>
                    </Box>

                    <Box
                      className="flexColumnRespo"
                      sx={{
                        p: 3,
                        pt: 0,
                        pb: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Rating
                          name="hover-feedback"
                          value={value}
                          precision={0.5}
                          getLabelText={getLabelText}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                          onChangeActive={(event, newHover) => {
                            setHover(newHover);
                          }}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        {value !== null && (
                          <Box sx={{ ml: 2 }}>
                            {labels[hover !== -1 ? hover : value]}
                          </Box>
                        )}
                      </Box>

                      <Box className="catBtnTop">
                        <Typography variant="p" sx={{ color: "#0B3013" }}>
                          32 minutes ago
                        </Typography>

                        <IconButton onClick={handleBookmarkClick}>
                          {isBookmarked ? (
                            <BookmarkIcon sx={{ color: "#FFB800" }} /> // Fill color when bookmarked
                          ) : (
                            <BookmarkBorderIcon sx={{ color: "#0B3013" }} /> // Default color when not bookmarked
                          )}
                        </IconButton>
                      </Box>
                    </Box> */}

                    {/* New Project Details Starts */}

                    {projectList?.length === 0 ? (
                      <Box
                        sx={{
                          py: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <NodataFound />
                      </Box>
                    ) : (
                      projectList?.map((project, index) => (
                        <>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => sendProjectDetails(project)}
                          >
                            <Box
                              className="employeeMainHead flexColumnRespo"
                              sx={{
                                borderTop: 1,
                                borderColor: "#dadbda",
                                p: 2.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Button>
                                  <Typography
                                    className="employeeHead"
                                    variant="h5"
                                    sx={{
                                      color: "#000",
                                      fontWeight: 500,
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {project?.title}
                                  </Typography>
                                </Button>
                              </Box>
                              <Box
                                className="priceHeadEmployee flexColumnRespo"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  className="catBtnTop"
                                  variant="p"
                                  sx={{ color: "#222" }}
                                >
                                  {/* 6 bids */}
                                </Typography>
                                <Typography
                                  className="priceHeadEmployee catBtnTop"
                                  variant="h5"
                                  sx={{ color: "#000", px: 2, fontWeight: 500 }}
                                >
                                  ${project?.budget?.min} - $
                                  {project?.budget?.max}
                                </Typography>
                              </Box>
                            </Box>

                            {/* <Box sx={{ p: 2.5, pt: 0 }}>
                              <Typography
                                className="budgetHeadEmployee"
                                variant="p"
                                sx={{
                                  fontWeight: 500,
                                  color: "#000",
                                  fontSize: 17,
                                }}
                              >
                                Budget:  {project?.currency}
                              </Typography>
                            </Box> */}

                            <Box sx={{ p: 2.5, pt: 0 }}>
                              <Typography
                                className="priceHeadEmployee markdown"
                                variant="p"
                                sx={{
                                  fontWeight: 400,
                                  color: "#222",
                                  fontSize: 16,
                                }}
                              >
                                <Markdown>{project?.description}</Markdown>
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                p: 2.5,
                                pt: 0,
                              }}
                            >
                              {project?.skillsRequired?.map((skill, index) => (
                                <Box
                                  className="priceHeadEmployee"
                                  sx={{
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    backgroundColor: "#F7FDF9",
                                    color: "#0B3013",
                                    p: 1.4,
                                    my: 1,
                                    px: 3,
                                    borderRadius: 20,
                                  }}
                                >
                                  {skill?.name}
                                </Box>
                              ))}
                            </Box>
                          </div>

                          <Box
                            className="flexColumnRespo"
                            sx={{
                              p: 3,
                              pt: 0,
                              pb: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Rating
                                disabled
                                name="hover-feedback"
                                value={value}
                                precision={0.5}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                              {value !== null && (
                                <Box sx={{ ml: 2 }}>
                                  {labels[hover !== -1 ? hover : value]}
                                </Box>
                              )}
                            </Box> */}

                            {/* <Box className="catBtnTop">
                              <Typography variant="p" sx={{ color: "#0B3013" }}>
                              </Typography>

                              <IconButton
                                onClick={() =>
                                  handleBookmarkClick(project, index)
                                }
                              >
                                {bookmarkedProjects[index] ? (
                                  <BookmarkIcon sx={{ color: "#FFB800" }} /> // Fill color when bookmarked
                                ) : (
                                  <BookmarkBorderIcon
                                    sx={{ color: "#0B3013" }}
                                  /> // Default color when not bookmarked
                                )}
                              </IconButton>
                            </Box> */}
                          </Box>
                        </>
                      ))
                    )}
                    {/* New Project Details Ends */}
                  </Box>
                </Box>
              </Box>
              {GetUserDetails() ? (
                <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                  {projectList?.length > 0 ? (
                    <CustomPagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                <Button
                  sx={{
                    fontFamily: "inherit",
                    margin: "auto",
                    marginTop: "40px",
                    px: 4,
                    fontSize: "16px !important",
                    pb: 1,
                    fontWeight: 400,
                    borderRadius: 10,
                    backgroundColor: "#0B3013",
                    width: "auto",
                    maxWidth: "fit-content",
                    textTransform: "capitalize",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center", // Align icon vertically with text
                    "&:hover": {
                      backgroundColor: "#0B3013", // Keep the same color as default
                    },
                  }}
                  onClick={(ev) => setLoginModalOpen(true)}
                >
                  {t("LoadMore")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Signup
          open={joinModalOpen}
          handleClose={handleCloseJoinModal}
          toggleSignUpModal={openSigninModal}
        />
        <Signin
          open={loginModalOpen}
          handleClose={handleCloseLoginModal}
          toggleSignUpModal={openSignupModal}
        />
      </Container>
    </section>
  );
}
