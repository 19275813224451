import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  FormControl,
  FormLabel,
  TextField,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import Paypal from "../../../../Assets/Images/Paypal.png";
import Stripe from "../../../../Assets/Images/Stripe.svg";
import Crypto from "../../../../Assets/Images/Crypto.png";
import { loadStripe } from "@stripe/stripe-js";
import loader from "../../../../Assets/Images/loader.svg";
import { getAPI, postAPI } from "../../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import { Form, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import CoinPayments from "../../HomeMembership/CoinPayments/CoinPayments";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../../Components/NodataFound/NodataFound";
import editICO from "../../../../Assets/Images/EditIcon.png";
import deleteICO from "../../../../Assets/Images/DeleteICon.png";

const PayoutModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  const [selectedCase, setSelectedCase] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [donations, setDonations] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [showPagination, setShowPagination] = useState(true);
  const [timeOutId, setTimeOutId] = useState(false);

  const [totalDonation, setTotalDonation] = useState({});

  const handleAddOpen = () => {
    setSelectedPayout({});
    setEditModalOpen(true);
  };

  const handleEditOpen = (donation) => {
    setSelectedPayout(donation);
    setEditModalOpen(true);
  };

  const handleDeleteOpen = (donation) => {
    setSelectedPayout(donation);
    setDeleteModalOpen(true);
  };

  const handleClose = () => {
    setSelectedPayout(null);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  const fetchSearchedDonations = async (
    ev = {},
    pageNumber = 1,
    paymentMethod = selectedPaymentMethod,
    search = searchText
  ) => {
    // const token = GetUserDetails()?.token;
    // if (token) {
    try {
      const response = await getAPI(
        `donation-investments/list?page=${pageNumber}`,
        ""
      );
      if (response?.statusCode == 200) {
        setTotalPages(response?.totalPages || 1);
        setDonations(response?.data || []);
        setTotalDonation({
          totalAmountOfAllDonations: response?.totalInvestmentAmount,
          totalAmountForMethod: response?.difference,
        });
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    // }
  };

  const fetchDonations = async (
    ev = {},
    pageNumber = 1,
    paymentMethod = selectedPaymentMethod,
    search = searchText
  ) => {
    LoaderShow();
    await fetchSearchedDonations(ev, pageNumber, paymentMethod, search);
    LoaderHide();
  };

  useEffect(() => {
    if (open) fetchDonations();
    else setDonations([]);
  }, [open]);

  const handleViewCase = (disputeCase) => {
    setSelectedCase(disputeCase);
  };

  const clearFilter = async () => {
    if (selectedPaymentMethod) {
      setShowPagination(false);
      await fetchDonations({}, 1, "");
      setSelectedPaymentMethod("");
      setShowPagination(true);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    // Extract the day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="subscribe-modal-title"
        aria-describedby="subscribe-modal-description"
      >
        <Box
          className="employeeProjdetails"
          sx={{
            position: "absolute",
            maxWidth: "90%",
            maxHeight: "90%",
            width: "700px", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            px: 4,
            py: 2,
            borderRadius: 5,
            overflow: "auto",
          }}
        >
          <Box className="SubHeader" style={{ position: "relative" }}>
            <Typography
              variant="h5"
              id="subscribe-modal-title"
              sx={{ borderBottom: 1, py: 2, pt: 0, borderColor: "#E6E6E6" }}
              gutterBottom
            >
              {t("donationPayout")}
            </Typography>
            <CloseIcon
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={onClose}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            {donations.length > 0 ? (
              <>
                <div style={{ overflow: "auto" }}>
                  <Table
                    aria-label="dispute case management table"
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0",
                      marginTop: "0px",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                            fontSize: "16px",
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        >
                          {t("orgName")}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                            fontSize: "16px",
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        >
                          {t("dontionamt")}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                            fontSize: "16px",
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        >
                          {t("date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {donations.map((donation, index) => (
                        <TableRow key={donation.id}>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom:
                                index === donations.length - 1
                                  ? "0"
                                  : "1px solid #e0e0e0",
                            }}
                          >
                            {donation?.organizationName || "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom:
                                index === donations.length - 1
                                  ? "0"
                                  : "1px solid #e0e0e0",
                            }}
                          >
                            ${donation.amount || 0}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom:
                                index === donations.length - 1
                                  ? "0"
                                  : "1px solid #e0e0e0",
                            }}
                          >
                            {formatDate(donation.date)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <Box
                  sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}
                >
                  {showPagination && (
                    <CustomPagination
                      count={totalPages}
                      onChange={fetchDonations}
                    />
                  )}
                </Box>
              </>
            ) : (
              <NodataFound />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PayoutModal;
