import { Box, Typography, Tabs, Tab } from "@mui/material";
import React, { useEffect } from "react";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI, updateAPI } from "../../../Services/Api";
import FlatFees from "./FlateFees/FlatFees";
import WithdrawMoney from "./WithdrawMoney/WithdrawMoney";
import PayCrypto from "./PayCrypto/PayCrypto";
import PayCryptoStripe from "./PayCryptoStripe/PayCryptoStripe";
import PayCryptoPaypal from "./PayCryptoPaypal/PayCryptoPaypal";
import loader from "../../../Assets/Images/loader.svg";
import { useTranslation } from "react-i18next";

export default function PayFeesManage() {
  const [value, setValue] = React.useState(0);
  const [selectedData, setSelectedData] = React.useState({});
  const [data, setData] = React.useState({});

  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (value == 0) {
      setSelectedData(data?.employer || {});
    } else {
      setSelectedData(data?.employee || {});
    }
  }, [value]);

  const fetchData = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData({
            employee: res.data.find((ele) => ele.type == "employee"),
            employer: res.data.find((ele) => ele.type == "employer"),
          });
          setSelectedData(res.data.find((ele) => ele.type == "employer"));
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const saveData = async (data, dataId) => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await updateAPI(`fee/update/${dataId}`, data, token);
        if (res.statusCode == 200) {
          setData((data) => {
            if (value == 0) data.employer = res.data;
            else data.employee = res.data;
            return data;
          });
          toast.success(t("toast21"));
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  return (
    <section>
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("payFeeMange")}
        </Typography>
      </Box>

      {/* Tabs Section */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "30px",
          border: 0,
          padding: 1,
          mb: 2,
          px: 3,
          py: 2.2,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="payment tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0B3013", // Active tab indicator color
            },
          }}
        >
          <Tab
            label={t("Employer")}
            sx={{
              fontFamily: "inherit",
              fontSize: 16,
              textTransform: "capitalize",
              color: value === 0 ? "#0B3013" : "#000",
              fontWeight: value === 0 ? "bold" : "normal",
              "&.Mui-selected": {
                color: "#0B3013", // Active tab label color
              },
            }}
          />
          <Tab
            label={t("Employee")}
            sx={{
              fontFamily: "inherit",
              fontSize: 16,
              textTransform: "capitalize",
              color: value === 1 ? "#0B3013" : "#000",
              fontWeight: value === 1 ? "bold" : "normal",
              "&.Mui-selected": {
                color: "#0B3013", // Active tab label color
              },
            }}
          />
        </Tabs>
      </Box>

      {/* Tab Content Section */}
      <Box>
        {/* <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "30px",
            border: 0,
            padding: 1,
            mb: 2,
            px: 3,
            py: 2.2,
          }}
        >
          <FlatFees data={selectedData} saveData={saveData} />
        </Box> */}
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "30px",
            border: 0,
            padding: 1,
            mb: 2,
            px: 3,
            py: 2.2,
          }}
        >
          <WithdrawMoney data={selectedData} saveData={saveData} />
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "30px",
            border: 0,
            padding: 1,
            mb: 2,
            px: 3,
            py: 2.2,
          }}
        >
          <PayCrypto data={selectedData} saveData={saveData} />
        </Box>
        {value == 0 && (
          <>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "30px",
                border: 0,
                padding: 1,
                mb: 2,
                px: 3,
                py: 2.2,
              }}
            >
              <PayCryptoStripe data={selectedData} saveData={saveData} />
            </Box>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "30px",
                border: 0,
                padding: 1,
                mb: 2,
                px: 3,
                py: 2.2,
              }}
            >
              <PayCryptoPaypal data={selectedData} saveData={saveData} />
            </Box>
          </>
        )}
      </Box>
    </section>
  );
}
