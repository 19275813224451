import React from "react";
import { Chip } from "@mui/material";

export const getStatusChip = (status, t) => {
  switch (status) {
    case "Activated":
      return <Chip label={t("ActiveOne")} color="success" />;
    case "Suspended":
      return <Chip label={t("SusP")} color="default" />;
    case "Inactive":
      return <Chip label={t("Inactive")} color="error" />;
    default:
      return <Chip label="Unknown" />;
  }
};
