import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button, FormLabel } from "@mui/material";
import OtpInput from "react-otp-input";
// import "./OTPVerficationEmployee.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { postAPI } from "../../../../../Services/Api";
import { toast } from "react-toastify";
import { LoaderHide, LoaderShow } from "../../../../../Helpers/Utility";
import loader from "../../../../../Assets/Images/loader.svg";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import LockIcon from "@material-ui/icons/Lock"; // Add this line
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../Firebase/firebase";

// rest of your code

export default function OTPVerficationEmployer(props) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const { t } = useTranslation();
  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const [resendDisabled, setResendDisabled] = useState(true); // Resend button disabled state
  const appVerifier = useMemo(() => props.appVerifier, [props]);
  // const [appVerifier, setAppVerifier] = useState(false);

  useEffect(() => {
    LoaderHide();
  }, []);

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false); // Enable resend button when timer reaches 0
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  const handleResendOtp = async (event) => {
    try {
      LoaderShow();
      const phoneNumber =
        props.employerUserData.registrationDataEmployer.phoneNumber;
      try {
        let newAppVerifier;
        if (!appVerifier) {
          newAppVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved - will proceed with submit function
            },
            "expired-callback": () => {
              // Response expired. Ask user to solve reCAPTCHA again.
            },
          });
          // setAppVerifier(newAppVerifier);
        }

        const response = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          appVerifier || newAppVerifier
        );
        if (response !== undefined) {
          setTimer(120);
          setResendDisabled(true);
        }
        LoaderHide();
      } catch (error) {
        if (error.message.includes("auth/too")) {
          toast.error(t("toast10"));
        } else if (error.message.includes("invalid")) {
          toast.error(t("toast8"));
        } else if (error.message.includes("Firebase")) {
          toast.error(t("toast9"));
        } else {
          toast.error(error.message);
        }
        LoaderHide();
      }
    } catch (error) {
      console.error("Failed to post data:===", error.message);
      LoaderHide();
    }
  };

  // const handleResendOtp = async () => {
  //   const phoneNumber = localStorage.getItem("phoneNumber");
  //   const dataToSend = {
  //     phoneNumber: `+${phoneNumber}`,
  //   };

  //   try {
  //     const response = await postAPI("auth/send-otp", dataToSend);
  //     console.log("Post response:===", response.otp);

  //     if (response.statusCode === 200) {
  //       localStorage.setItem("mobile", response.otp);
  //       localStorage.setItem("phoneNumber", phoneNumber);
  //       // Your logic to resend OTP
  //       setResendDisabled(true); // Disable resend button when clicked
  //       setTimer(120); // Reset timer to 30 seconds
  //       toast.success(t(response.message), {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     } else {
  //       toast.error(t(response.message), {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   } catch (error) {
  //     toast.error(error.message, {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };

  const handleVerify = async () => {
    LoaderShow();
    // Validate OTP here
    // const validOtp = localStorage.getItem("mobile")
    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!otp) {
      setOtpError("Please enter the OTP");
    } else if (otp.length !== 6) {
      setOtpError("OTP should be 6 digits");
    } else {
      setOtpError("");
      const dataToSend = {
        // otp: otp,
        phoneNumber: `+${phoneNumber}`,
        userOTP: otp,
      };

      try {
        if (
          props?.employerUserData?.registrationDataEmployer?.response !=
          undefined
        ) {
          LoaderShow();

          const response =
            await props?.employerUserData?.registrationDataEmployer?.response?.confirm(
              otp
            );

          if (response?.user !== undefined) {
            dataToSend.response = response;
            props.SetEmployerUserDataObject(
              "otpVerificationDataEmployer",
              dataToSend
            );
            props.handleNextStepEmployer(); // Proceed to the next step
            LoaderHide();
          }

          // await props?.employerUserData?.registrationDataEmployer?.response?.confirm(otp)
          //   .then((result) => {
          //     // Handle successful verification
          //     const user = result.user;
          //     console.log('Phone number verified successfully!');
          //     console.log('User:', user);

          //     // Save the user object or perform other actions as needed
          //   })
          //   .catch((error) => {
          //     // Handle verification errors
          //     console.error('Error verifying OTP:', error);
          //     alert('Invalid OTP. Please try again.');
          //   });
        }

        // const response = await postAPI('auth/verify-otp', dataToSend);
        // console.log('Post response:===', response);
        // if (response.statusCode === 200) {
        //   console.log("otp sent successfully=======")
        //   localStorage.removeItem("phoneNumber")
        //   props.SetEmployerUserDataObject('otpVerificationDataEmployer', dataToSend);
        //   props.handleNextStepEmployer(); // Proceed to the next step
        //   toast.success(t(response.message), {
        //     position: 'top-right',
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // } else {
        //   toast.error("Invalid OTP", {
        //     position: 'top-right',
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        //   console.log("login failed====")
        // }

        LoaderHide();
      } catch (error) {
        LoaderHide();
        if (error.message.includes("invalid")) {
          toast.error(t("toast13"));
          return;
        }
        if (error.message.includes("code-expired")) {
          toast.error(t("toast14"));
          return;
        }
        toast.error(error.message);
      }
      // props.SetEmployerUserDataObject('otpVerificationDataEmployer', dataToSend);
      // props.handleNextStepEmployer(); // Proceed to the next step
      LoaderHide();
    }
    LoaderHide();
  };

  return (
    <Box sx={{ p: 8, pl: 4 }}>
      {/* <div id="hideloding" className="loding-display">
        <img src={loader} alt='loader-img' />
      </div> */}
      {/* <div id="recaptcha-container"></div> */}
      <Box>
        <Typography
          variant="h5"
          sx={{ textAlign: "start", fontWeight: 700, pb: 1 }}
        >
          {t("OTP")}
        </Typography>
        <p className="beginText">{t("We")} </p>
      </Box>
      {/* 
      <Box sx={{ pt: 3 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '360px', mx: 'auto' }}>
    <OtpInput
      containerStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      inputStyle={{
        border: "2px solid #0B3013",
        borderRadius: "8px",
        width: "54px",
        height: "54px",
        fontSize: "18px",
        color: "#000",
        fontWeight: "400",
        caretColor: "blue",
      }}
      focusStyle={{
        border: "1px solid #CFD3DB",
        outline: "none",
      }}
      value={otp}
      onChange={setOtp}
      numInputs={6}
      inputType="password"
      renderSeparator={null}
      renderInput={(props, idx) => <input key={idx} {...props} className="otpInput" />}
    />
  </Box>
  {otpError && (
    <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
      {otpError}
    </Typography>
  )}
</Box> */}

      <Box sx={{ pt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <OtpInput
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            inputStyle={{
              border: "2px solid #0B3013",
              borderRadius: "8px",
              width: "54px",
              height: "54px",
              fontSize: "18px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
            focusStyle={{
              border: "1px solid #CFD3DB",
              outline: "none",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputType="password"
            renderSeparator={null}
            renderInput={(props, idx) => (
              <input key={idx} {...props} className="otpInput" />
            )}
          />
        </Box>
        {otpError && (
          <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
            {otpError}
          </Typography>
        )}
      </Box>

      <Box sx={{ pt: 5 }}>
        <span className="timerText">{`${
          timer < 10 ? "0" : ""
        }${timer} Sec`}</span>

        <br />
        <br />
        <span className="timerText">
          {t("DidntOne")}
          &nbsp;
          <span
            className={`resend ${resendDisabled ? "disabled" : ""}`}
            onClick={!resendDisabled ? handleResendOtp : undefined}
          >
            {t("resendOTP")}
          </span>
        </span>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button
          onClick={handleVerify}
          // onClick={props.handleNextStepEmployer}
          className="SignUpBtn"
          sx={{ textTransform: "capitalize", width: 100, color: "white" }}
        >
          {t("Verify")}
        </Button>
      </Box>
      <Box sx={{ my: 1 }}>
        <FormLabel
          sx={{
            display: "flex",
            textAlign: "center",
            py: 1,
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
        >
          <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
          {t("YourInfo")}
        </FormLabel>
      </Box>
    </Box>
  );
}
