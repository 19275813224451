import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import loader from "../../../Assets/Images/loader.svg";
import { useEffect, useState } from "react";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI, postAPI } from "../../../Services/Api";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminDisputeTable from "./AdminDisputeTable";
import AddDispute from "./AddDispute";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon

export default function AdminDispute() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedDispute, setSelectedDispute] = useState(null);

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        {selectedDispute && (
          <IconButton
            onClick={(ev) => {
              navigate(pathname);
              setSelectedDispute(null);
            }}
            sx={{ mr: 2, mb: 1 }}
          >
            <ArrowBackIcon sx={{ color: "#000" }} />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("disCaseMange")}
        </Typography>
      </Box>
      {!selectedDispute && (
        <AdminDisputeTable
          setDispute={(dispute) => {
            setSelectedDispute(dispute);
          }}
        />
      )}
      {selectedDispute && (
        <AddDispute
          project={state?.project}
          path={pathname}
          dispute={selectedDispute}
          onBack={(ev) => {
            navigate(pathname);
            setSelectedDispute(null);
          }}
        />
      )}
    </section>
  );
}
