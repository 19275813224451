import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button, FormLabel } from "@mui/material";
import OtpInput from "react-otp-input";
import "./OTPVerficationEmployee.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { postAPI } from "../../../../../Services/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import {
  getCoutryFromPhoneCode,
  LoaderHide,
  LoaderShow,
} from "../../../../../Helpers/Utility";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../Firebase/firebase";

export default function OTPVerficationEmployee(props) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const [resendDisabled, setResendDisabled] = useState(true); // Resend button disabled state
  const appVerifier = useMemo(() => props.appVerifier, [props]);
  // const [appVerifier, setAppVerifier] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false); // Enable resend button when timer reaches 0
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  const handleResendOtp = async (event) => {
    try {
      LoaderShow();
      const phoneNumber =
        props.employeeUserData.mobileVerificationDataEmployee.phoneNumber;
      try {
        let newAppVerifier;
        if (!appVerifier) {
          newAppVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved - will proceed with submit function
            },
            "expired-callback": () => {
              // Response expired. Ask user to solve reCAPTCHA again.
            },
          });
          // setAppVerifier(newAppVerifier);
        }

        const response = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          appVerifier || newAppVerifier
        );
        if (response !== undefined) {
          setTimer(120);
          setResendDisabled(true);
        }
        LoaderHide();
      } catch (error) {
        if (error.message.includes("auth/too")) {
          toast.error(t("toast10"));
        } else if (error.message.includes("invalid")) {
          toast.error(t("toast8"));
        } else if (error.message.includes("Firebase")) {
          toast.error(t("toast9"));
        } else {
          toast.error(error.message);
        }
        LoaderHide();
      }
    } catch (error) {
      console.error("Failed to post data:===", error.message);
      LoaderHide();
    }
  };

  const handleVerify = async () => {
    // Validate OTP here
    const validOtp = localStorage.getItem("mobile");
    const phoneNumber = localStorage.getItem("phoneNumber");

    if (!otp) {
      setOtpError("Please enter the OTP");
    } else if (otp.length !== 6) {
      setOtpError("OTP should be 6 digits");
    } else {
      setOtpError("");
      const dataToSend = {
        // otp: otp,
        phoneNumber: `+${phoneNumber}`,
        userOTP: otp,
        location: getCoutryFromPhoneCode(`+${phoneNumber}`),
      };
      LoaderShow();
      try {
        // firebase auth verify
        if (
          props?.employeeUserData?.mobileVerificationDataEmployee?.response !=
          undefined
        ) {
          const response =
            await props?.employeeUserData?.mobileVerificationDataEmployee?.response?.confirm(
              otp
            );

          if (response?.user !== undefined) {
            localStorage.removeItem("phoneNumber");
            toast.success(t(response.message));
            dataToSend.response = response;
            props.SetEmployeeUserDataObject(
              "otpVerificationDataEmployee",
              dataToSend
            );
            props.handleNextStepEmployee();
          }
        }

        // simple auth verify
        // const response = await postAPI('auth/verify-otp', dataToSend);
        // console.log('Post response:===', response);
        // if (response.statusCode === 200) {
        //   localStorage.removeItem("phoneNumber")
        //   console.log("otp sent successfully=======")

        //   toast.success(t(response.message), );
        //   props.SetEmployeeUserDataObject('otpVerificationDataEmployee', dataToSend);
        //   props.handleNextStepEmployee();
        // } else {
        //   toast.error("Invalid OTP");
        // }
      } catch (error) {
        if (error.message.includes("invalid")) {
          toast.error(t("toast13"));
          return;
        }
        if (error.message.includes("code-expired")) {
          toast.error(t("toast14"));
          return;
        }
        toast.error(error.message);
      }
      LoaderHide();
    }
  };

  return (
    <Box sx={{ p: 8, pl: 4 }}>
      {/* <div id="recaptcha-container"></div> */}
      <Box>
        <Typography variant="h5" sx={{ textAlign: "start", fontWeight: 700 }}>
          {t("OTP")}
        </Typography>
        <p className="beginText">{t("WeOTP")}</p>
      </Box>

      <Box sx={{ pt: 3 }}>
        <OtpInput
          inputStyle={{
            border: "2px solid #0B3013",
            borderRadius: "8px",
            width: "54px",
            height: "54px",
            fontSize: "18px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="password"
          renderSeparator={
            <span style={{ paddingLeft: 5, paddingRight: 5 }}></span>
          }
          renderInput={(props) => <input {...props} className="otpInput" />}
        />
        {otpError && (
          <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
            {otpError}
          </Typography>
        )}
      </Box>

      <Box sx={{ pt: 5 }}>
        <span className="timerText">{`${
          timer < 10 ? "0" : ""
        }${timer} Sec`}</span>
        <br />
        <br />
        <span className="timerText">
          {t("DidntOne")}{" "}
          <span
            className={`resend SigninTextJoin  ${
              resendDisabled ? "disabled" : ""
            }`}
            onClick={!resendDisabled ? handleResendOtp : undefined}
          >
            {t("resendOTP")}
          </span>
        </span>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button
          // onClick={props.handleNextStepEmployee}
          onClick={handleVerify}
          className="SignUpBtn"
          sx={{ textTransform: "capitalize", width: 100, color: "white" }}
        >
          {t("Verify")}
        </Button>
      </Box>
      <Box sx={{ my: 1 }}>
        <FormLabel
          sx={{
            display: "flex",
            textAlign: "center",
            py: 1,
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
        >
          <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
          {t("YourInfo")}
        </FormLabel>
      </Box>
    </Box>
  );
}
