import {
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import styled from "@emotion/styled";
import arrowCross from "../../../Assets/Images/arrow-up-right-01-round.png";
// import doc from "../../../Assets/Images/document-attachment.png";
import delete1 from "../../../Assets/Images/delet.png";
import { DropzoneArea } from "material-ui-dropzone";
import "./EmployeerEditProject.css";
import { useLocation } from "react-router-dom";
import loader from "../../../Assets/Images/loader.svg";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { deleteAPI, getAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { countries } from "../../../Helpers/common_constants";
// import { TextareaAutosize } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import CloseIcon from "@mui/icons-material/Close";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 4px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid #cbcbcb;
    box-shadow: none
      };
  
  
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

export default function EmployeerEditProject() {
  const { t, i18n } = useTranslation();

  const { state } = useLocation();

  const [projectDetails, setProjectDetails] = useState(state.project);

  const navigate = useNavigate();

  const [isFocused, setIsFocused] = useState(false);
  const [formData, setFormData] = useState({
    category: projectDetails.category?.length
      ? projectDetails.category.map((ele) => ele._id)
      : [], // Initialize with projectDetails values
    projectTitle: projectDetails.title || "",
    projectDetail: projectDetails.description || "",
    type: projectDetails.paymentType || "",
    skills: projectDetails.skillsRequired.map((skill) => skill._id) || [],
    location: projectDetails.location || "",
    requiredEmployee: "",
    payBy: projectDetails.paymentType,
    projectDuration: projectDetails?.duration?._id,
    price: projectDetails.currency,
    minBudget: projectDetails?.budget?.min,
    maxBudget: projectDetails?.budget?.max,
    budget: projectDetails?.estimatedBudget?._id,
  });
  const [errorMessages, setErrorMessages] = useState({
    minBudget: "",
    maxBudget: "",
    specialization: "",
    gender: "",
    skills: "",
    location: "",
    requiredEmployee: "",
    payBy: "",
    projectDuration: "",
    price: "",
    projectTitle: "",
    projectDetail: "",
  });

  const [skills, setSkills] = useState([]);

  useEffect(() => {
    getAllSkills();
    getProjectData();
    // LoaderHide()
    //eslint-disable-next-line
  }, []);

  const [dropValues, setDropValues] = useState();
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false); // State for category menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu open/close

  const isDropdownSelected = (value) => value !== "";

  const isNotEmpty = (value) => value.trim() !== "";

  const getAllSkills = async () => {
    try {
      LoaderShow();
      const response = await getAPI("skills/all");
      if (response.statusCode === 200) {
        setSkills(response?.data);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      console.log("error===", error);
    }
  };

  const getProjectData = async () => {
    try {
      LoaderShow();
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setDropValues(response?.data);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      console.log("error===", error);
    }
  };

  const dropdownValues = dropValues?.categories;
  const dropdownProjectDuration = dropValues?.durationEnumValues;
  const dropdownEstimateValues = dropValues?.estimatEnumValues;
  const dropdownMaxValues = [100, 200, 300, 400, 500, 600, 700, 800, 900];

  const defaultValue = formData;

  const [selectedValue, setSelectedValue] = useState([]);
  const [dropProjectDuration, setDropProjectDuration] = useState(
    defaultValue?.projectDuration
  );
  const [dropEstimateValues, setDropEstimateValues] = useState(
    defaultValue?.budget
  );

  const filteredSkills = useMemo(() => {
    if (!dropValues?.categories || !formData?.category?.length) return [];
    let arr = [];
    formData.category.map((category) => {
      const categ = dropValues.categories.find((ele) => ele._id == category);
      if (categ && categ?.skills?.length) arr = [...arr, ...categ.skills];
    });
    return arr;
  }, [formData, dropValues]);

  const allSkills = useMemo(() => {
    if (!dropValues?.categories) return [];
    let arr = [];
    dropValues.categories.map((category) => {
      if (category?.skills?.length) arr = [...arr, ...category.skills];
    });
    return arr;
  }, [dropValues]);

  // Handle change
  const handleChange = (event) => {
    const value = event.target.value.filter((ele) => ele);
    setFormData((dt) => {
      return { ...dt, category: value };
    });
    setErrorMessages({
      ...errorMessages,
      category: value > 0 ? "" : <>{t("PleaseSelectCategory")}</>,
      skills: value > 0 ? "" : t("selectCategoryFirst"),
    });
  };

  const handleMinBudget = (event) => {
    // const value = parseFloat(event.target.value);
    // console.log("value======", value)
    // if (value <= formData.price.name) {
    //   setFormData({ ...formData, minBudget: value });
    //   setErrorMessages({ ...errorMessages, minBudget: "" });
    // }
    // else {
    //   setErrorMessages({ ...errorMessages, minBudget: "Min Budget should not be greater than the selected Estimate Budget" });
    // }

    const value = event.target.value;
    if (value === "") {
      // Allow clearing the input field
      setFormData({ ...formData, minBudget: value });
      setErrorMessages({ ...errorMessages, minBudget: "" });
    } else {
      const numericValue = parseFloat(value);
      if (
        !isNaN(numericValue) &&
        numericValue <=
          dropValues?.estimatEnumValues?.find(
            (ele) => ele._id == formData.budget
          )?.name
      ) {
        setFormData({ ...formData, minBudget: numericValue });
        setErrorMessages({ ...errorMessages, minBudget: "" });
      } else {
        setErrorMessages({
          ...errorMessages,
          minBudget: <>{t("MinBudgetShouldBe")}</>,
        });
      }
    }
  };

  const handleProjectDurations = (event) => {
    setDropProjectDuration(event.target.value);
    setErrorMessages({
      ...errorMessages,
      projectDuration: isDropdownSelected(event.target.value) ? (
        ""
      ) : (
        <>{t("PleaseSelectProjectType")}</>
      ),
    });
  };

  const handlEstimateBudgetValues = (event) => {
    const value = dropValues?.estimatEnumValues.find(
      (ele) => ele._id == event.target.value
    );
    setDropEstimateValues(event.target.value);
    console.log(value);
    const minBudget = value.name - 200 < 100 ? 100 : value.name - 200;
    setFormData((dt) => {
      return {
        ...dt,
        maxBudget: Number(value.name) + 200,
        minBudget: minBudget,
      };
    });
  };

  const handleMaxBudget = (event) => {
    // const value = event.target.value
    // if (value <= formData.price.name) {
    //   setFormData({ ...formData, maxBudget: value });
    //   setErrorMessages({ ...errorMessages, maxBudget: "" });
    // } else {
    //   setErrorMessages({ ...errorMessages, maxBudget: "Max Budget should not be greater than the selected Estimate Budget" });
    // }

    const value = event.target.value;
    const values = event.target.value;
    setFormData((dt) => {
      return {
        ...dt,
        maxBudget: value,
      };
    });
    if (value === "") {
      setErrorMessages({ ...errorMessages, maxBudget: "" });
    } else {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue > Number(
        dropValues.estimatEnumValues.find((ele) => ele._id == formData.budget)
          ?.name
      )) {
        setErrorMessages({ ...errorMessages, maxBudget: "" });
      } else if (isNaN(numericValue)) {
        setErrorMessages({
          ...errorMessages,
          maxBudget: t("EnterValidNumber"),
        });
      } else if (numericValue < Number(
        dropValues.estimatEnumValues.find((ele) => ele._id == formData.budget)
          ?.name
      )) {
        setErrorMessages({
          ...errorMessages,
          maxBudget: <>{t("MaxBudgetGreater")}</>,
        });
      } else {
        setErrorMessages({
          ...errorMessages,
          maxBudget: <>{t("MaxBudgetShould")}</>,
        });
      }
    }
  };

  const hourlyOptions = [
    { value: "Hourly Rate", label: "Hourly Rate" },
    { value: "Fixed Price", label: "Fixed Price" },
  ];

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // const handleCategory = (event) => {
  //     const value = event.target.value;
  //     setFormData({ ...formData, category: value });
  //     setErrorMessages({ ...errorMessages, gender: isDropdownSelected(value) ? "" : "Please select category." });
  // };

  const handleProjectType = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, type: value });
    setErrorMessages({
      ...errorMessages,
      type: isDropdownSelected(value) ? "" : <>{t("ProjectType")}</>,
    });
  };

  const handleSkills = (event) => {
    const value = event.target.value.filter((ele) => ele);
    setFormData({ ...formData, skills: value });
    setErrorMessages({
      ...errorMessages,
      skills: value.length ? "" : <>{t("PleaseSelectSkills")}</>,
    });
  };

  const handleLocation = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, location: value });
    setErrorMessages({
      ...errorMessages,
      location: isDropdownSelected(value) ? (
        ""
      ) : (
        <>{t("PleaseSelectLocation")}</>
      ),
    });
  };

  // const handlemaxBudget = (event) => {
  //     const value = event.target.value;
  //     if (value <= formData.price) {
  //         setFormData({ ...formData, maxBudget: value });
  //         setErrorMessages({ ...errorMessages, maxBudget: isDropdownSelected(value) ? "" : "Please select max budget." });
  //     } else {
  //         setErrorMessages((prevErrorMessages) => ({
  //             ...prevErrorMessages,
  //             maxBudget: "Max Budget should not be less than the selected Estimate Budget",
  //         }));
  //     }
  // };

  // const handleRequiredEmployee = (event) => {
  //     const value = event.target.value;
  //     setFormData({ ...formData, requiredEmployee: value });
  //     setErrorMessages({ ...errorMessages, requiredEmployee: isDropdownSelected(value) ? "" : "Please select required employee." });
  // };

  // const handlePayBy = (event) => {
  //     const value = event.target.value;
  //     setFormData({ ...formData, payBy: value });
  //     setErrorMessages({ ...errorMessages, payBy: isDropdownSelected(value) ? "" : "Please select payby." });
  // };

  // const handleProjectDuration = (event) => {
  //     const value = event.target.value;
  //     setFormData({ ...formData, projectDuration: value });
  //     setErrorMessages({ ...errorMessages, projectDuration: isDropdownSelected(value) ? "" : "Please select project duration." });
  // };

  // const handlePrice = (event) => {
  //     const value = event.target.value;
  //     setFormData({ ...formData, price: value });
  //     setErrorMessages({ ...errorMessages, price: isDropdownSelected(value) ? "" : "Please select price." });
  // };

  const handleProjectTitle = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, projectTitle: value });
    setErrorMessages({
      ...errorMessages,
      projectTitle:
        formData.projectTitle.length < 3 ? <>{t("ProjectTitle3")}</> : "",
    });
  };

  const handleProjectDetail = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, projectDetail: value });
    setErrorMessages({
      ...errorMessages,
      projectDetail:
        formData.projectDetail.length < 25 ? <>{t("ProjectDetail25")}</> : "",
    });
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (
      !isNotEmpty(formData.projectTitle) ||
      formData.projectTitle.length < 3
    ) {
      errors.projectTitle = <>{t("ProjectTitle3")}</>;
      isValid = false;
    }

    if (
      !isNotEmpty(formData.projectDetail) ||
      formData.projectDetail.length < 25
    ) {
      errors.projectDetail = t("ProjectDetail25");
      isValid = false;
    }

    if (!isDropdownSelected(formData.category)) {
      errors.category = t("PleaseSelectCategory");
      isValid = false;
    }

    if (!isDropdownSelected(selectedValue)) {
      errors.category = t("PleaseSelectCategory");
      isValid = false;
    }

    if (!isDropdownSelected(dropProjectDuration)) {
      errors.projectDuration = t("PleaseSelectDuration");
      isValid = false;
    }

    if (!isDropdownSelected(formData.type)) {
      errors.type = t("PleaseSelectProjectType");
      isValid = false;
    }

    if (formData.skills.length === 0) {
      errors.skills = t("PleaseSelectSkills");
      isValid = false;
    }

    if (!isDropdownSelected(formData.location)) {
      errors.location = t("PleaseSelectLocation");
      isValid = false;
    }

    // if (!isDropdownSelected(formData.requiredEmployee)) {
    //     errors.requiredEmployee = "Please select required employee";
    //     isValid = false;
    // }

    // if (!isDropdownSelected(formData.payBy)) {
    //     errors.payBy = "Please select payby";
    //     isValid = false;
    // }

    if (!isDropdownSelected(formData.projectDuration)) {
      errors.projectDuration = t("PleaseSelectDuration");
      isValid = false;
    }

    if (!isDropdownSelected(formData.price)) {
      errors.price = t("PleaseSelectEstimateBudget");
      isValid = false;
    }

    if (!formData.maxBudget) {
      errors.maxBudget = t("PleaseSelectMaxBudget");
      isValid = false;
    } else if (
      Number(formData.maxBudget) <
      Number(
        dropValues.estimatEnumValues.find((ele) => ele._id == formData.budget)
          ?.name
      )
    ) {
      errors.maxBudget = t("MaxBudgetGreater");
      isValid = false;
    }
    setErrorMessages(errors);
    return isValid;
  };
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleFileChange = (newFiles) => {
    if (
      [...files, ...newFiles].reduce((val, ele) => ele?.size + val, 0) >
      1_048_576
    ) {
      toast.warn(t("toast16"));
      return;
    }
    setFiles((dt) => [...dt, newFiles[newFiles.length - 1]]);
    // const names = newFiles.map((file) => file.name);
    if (newFiles[newFiles.length - 1]?.name)
      setFileNames((dt) => [...dt, newFiles[newFiles.length - 1]?.name]);
  };

  const handleSaveButtonClick = async () => {
    if (validateForm()) {
      try {
        LoaderShow();
        const token = GetUserDetails().token;
        const dataToSend = new FormData();

        // dataToSend.append("category", formData.category);
        formData.category.forEach((name, index) => {
          dataToSend.append(`category[${index}]`, name);
        });
        dataToSend.append("title", formData.projectTitle);
        dataToSend.append("description", formData.projectDetail);
        formData.skills.forEach((name, index) => {
          dataToSend.append(`skillsRequired[${index}]`, name);
        });
        dataToSend.append("paymentType", formData.type);
        dataToSend.append("location", formData.location);
        dataToSend.append("duration", dropProjectDuration);
        dataToSend.append("estimatedBudget", dropEstimateValues);
        dataToSend.append("budget", `${formData.minBudget}-${formData.maxBudget}`);
        dataToSend.append("proposalId", "");
        dataToSend.append("projectId", projectDetails?._id);
        dataToSend.append("currency", "USD");
        // dataToSend.append('skillRequired', formData.skills);
        // dataToSend.append('budget', formData.price);
        // dataToSend.append('currency', formData.price);
        // dataToSend.append('category', formData.category);
        // dataToSend.append('duration', formData.projectDuration);
        // dataToSend.append('paymentType', formData.payBy);
        // dataToSend.append('skillRequired', skillSelected);

        files.forEach((file, index) => {
          dataToSend.append("documents", file);
        });
        const response = await updateAPI(`project/update`, dataToSend, token);
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(t(response.message));
          navigate("/dashboard/employer/myprojects");
          // setFormData({
          //     projectTitle: "", projectDetail: "", skills: "", price: "", gender: "", projectDuration: "", type: "", location: "", payBy: ""
          // })
        }
      } catch (error) {
        LoaderHide();
        toast.error(error.message);
      }
    } else {
      LoaderHide();
    }
  };

  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [fileError, setFileError] = useState("");

  // const handleFileChange = (files) => {
  //     setSelectedFiles(files);
  //     setFileError("");
  // };

  // const validateFiles = () => {
  //     if (selectedFiles.length === 0) {
  //         setFileError("Please upload at least one file.");
  //         return false;
  //     }

  //     // Additional validation logic if needed

  //     return true;
  // };

  // const handleUploadFile = () => {
  //     if (validateFiles()) {
  //         // Proceed with saving the files or other actions
  //         console.log("Files are valid. Proceeding with saving data:===", selectedFiles);
  //     } else {
  //         console.log("File validation failed.===");
  //     }
  // };

  const handleDeleteFile = (index) => {
    setFiles((fLt) => fLt.filter((_, i) => i !== index));
    setFileNames((flt) => flt.filter((_, i) => i !== index));
  };

  const [documents, setDocuments] = useState(projectDetails.documents || []);

  const handleDeleteUploadedFile = (index) => {
    try {
      const token = GetUserDetails()?.token;
      deleteAPI(
        "project/delete-document",
        {
          projectId: projectDetails._id,
          documentIndex: index,
        },
        token
      );
      setDocuments((dt) => dt.filter((ele, i) => i != index));
      navigate("/dashboard/employer/editproject", {
        state: {
          project: {
            ...projectDetails,
            documents: projectDetails.documents.filter((ele, i) => i != index),
          },
        },
      });
    } catch (err) {}
  };

  return (
    <section className="EmployeerCreateProject">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box>
        <Typography
          className="employeeHead1"
          variant="h4"
          sx={{ pb: 1, fontWeight: 600 }}
        >
          {t("EditProject")}
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          p: 5,
          py: 3.5,
          my: 4,
          borderRadius: 7.5,
          border: 1,
          borderColor: "#d6d6d6",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#000",
            fontWeight: 600,
            pb: 2,
            borderBottom: 1,
            borderColor: "#d6d6d6",
            px: 0,
          }}
        >
          {t("BasicInformation")}
        </Typography>
        <Box sx={{ py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel>{t("Category")}</InputLabel>
                <Select
                  multiple
                  value={formData.category}
                  onChange={handleChange}
                  input={<OutlinedInput label={t("SkillsOne")} />}
                  renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {selected?.map((value) => (
                        // <Chip key={value} label={"React"} />
                        <Chip
                          key={value}
                          label={
                            dropValues?.categories?.find(
                              (skill) => skill?._id === value
                            )?.[i18n.language == "en" ? "name" : "name_fa"] ||
                            ""
                          }
                        />
                      ))}
                    </Stack>
                  )}
                  onClose={() => setIsCategoryMenuOpen(false)} // Close menu on select
                  onOpen={() => setIsCategoryMenuOpen(true)} // Open menu on select
                  open={isCategoryMenuOpen} // Controlled open state
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      borderColor: "",
                      mx: 1,
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => setIsCategoryMenuOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {dropValues?.categories?.map((skill) => (
                    <MenuItem key={skill._id} value={skill._id}>
                      {skill?.[i18n.language == "en" ? "name" : "name_fa"]}
                    </MenuItem>
                  ))}
                </Select>
                {errorMessages.categories && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.categories}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            {/* <Grid item xs={12}>
                            <Box>
                                <FormLabel
                                    sx={{
                                        display: "block",
                                        textAlign: "start",
                                        py: 1.4,
                                        fontSize: 14,
                                    }}
                                >
                                    Category
                                </FormLabel>
                                <Dropdown
                                    value={formData.category}
                                    onChange={handleCategory}
                                    options={categoryOptions}
                                    label="Category"
                                />

                                <FormControl fullWidth>
                                    <Select
                                        labelId="dropdown-select-label"
                                        value={selectedValue}
                                        onChange={handleChange}
                                        label="Select Option"
                                    >
                                        {dropdownValues?.map((option) => (
                                            <MenuItem key={option._id} value={option._id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {errorMessages.selectedValue && (
                                    <Typography variant="p" sx={{ color: "red" }}>
                                        {errorMessages.selectedValue}
                                    </Typography>
                                )}
                            </Box>
                        </Grid> */}

            <Grid item xs={12}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectTitle")}
                </FormLabel>
                <TextField
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                    boxShadow: isFocused
                      ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                      : "none",
                  }}
                  fullWidth={true}
                  placeholder={t("ProjectTitle")}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={formData.projectTitle}
                  onChange={handleProjectTitle}
                />
                {errorMessages.projectTitle && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.projectTitle}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ my: 1 }}>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectDescription")}
                </FormLabel>
                <Textarea
                  sx={{
                    width: "100%",
                    border: 1,
                    borderColor: "#cbcbcb",
                    borderRadius: 1,
                  }}
                  minRows={6}
                  maxRows={6}
                  placeholder={t("ProjectDescription")}
                  value={formData.projectDetail}
                  onChange={handleProjectDetail}
                />
                {errorMessages.projectDetail && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.projectDetail}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel>{t("skl")}</InputLabel>
                <Select
                  multiple
                  value={formData.skills}
                  onChange={handleSkills}
                  input={<OutlinedInput label={t("SkillsOne")} />}
                  renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {selected?.map((value) => (
                        // <Chip key={value} label={"React"} />
                        <Chip
                          key={value}
                          label={
                            allSkills?.find((skill) => skill?._id === value)
                              ?.name || ""
                          }
                        />
                      ))}
                    </Stack>
                  )}
                  onClose={() => setIsMenuOpen(false)} // Close menu on select
                  onOpen={() => setIsMenuOpen(true)} // Open menu on select
                  open={isMenuOpen} // Controlled open state
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      borderColor: "",
                      mx: 1,
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {filteredSkills?.map((skill) => (
                    <MenuItem key={skill._id} value={skill._id}>
                      {skill.name}
                    </MenuItem>
                  ))}
                </Select>
                {errorMessages.skills && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.skills}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            {/* <Box>
                            <FormLabel
                                sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1.4,
                                    fontSize: 14,
                                }}
                            >
                                Skills
                            </FormLabel>
                            <Dropdown
                                value={formData.skills}
                                onChange={handleSkills}
                                options={renderSkillOptions()}
                                label="Skills"
                            />

                            <FormControl fullWidth>
                                <Select
                                    labelId="dropdown-select-label"
                                    value={skillSelected}
                                    onChange={handleSkillChange}
                                    label="Select Option"
                                >
                                    {dropdownSkillValues?.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {errorMessages.skills && (
                                <Typography variant="p" sx={{ color: "red" }}>
                                    {errorMessages.skills}
                                </Typography>
                            )}
                        </Box> */}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectType")}
                </FormLabel>
                <Dropdown
                  value={formData.type}
                  onChange={handleProjectType}
                  options={dropValues?.paymentTypeEnumValues?.map((value) => ({
                    value: value.value,
                    label: `${
                      value?.[
                        i18n.language == "en"
                          ? "value"
                          : `value_${i18n.language}`
                      ]
                    }`,
                  }))}
                  label={t("ProjectType")}
                />
                {errorMessages.type && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.type}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("Location")}
                </FormLabel>
                <Dropdown
                  value={formData?.location}
                  onChange={handleLocation}
                  options={countries.map((ele) => {
                    return { ...ele, value: ele.label };
                  })}
                  label={t("Location")}
                />
                {errorMessages.location && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.location}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Box>
                                <FormLabel
                                    sx={{
                                        display: "block",
                                        textAlign: "start",
                                        py: 1.4,
                                        fontSize: 14,
                                    }}
                                >
                                    Project Duration
                                </FormLabel>
                                <Dropdown
                                    value={dropProjectDuration}
                                    onChange={handleProjectDuration}
                                    options={projectDuration}
                                    label="ProjectDuration"
                                />
                                {errorMessages.projectDuration && (
                                    <Typography variant="p" sx={{ color: "red" }}>
                                        {errorMessages.projectDuration}
                                    </Typography>
                                )}
                            </Box> */}

              {/* <FormControl style={{ marginTop: "45px" }} fullWidth>
                                <InputLabel id="dropdown-select-label">Project Duration</InputLabel>
                                <Select
                                    labelId="dropdown-select-label"
                                    value={dropProjectDuration}
                                    onChange={handleProjectDurations}
                                    label="Select Option"
                                >
                                    {dropdownProjectDuration?.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectDuration")}
                </FormLabel>
                <Dropdown
                  value={dropProjectDuration}
                  onChange={handleProjectDurations}
                  options={dropValues?.durationEnumValues?.map((value) => ({
                    value: value._id,
                    label: `${
                      value?.[
                        i18n.language == "en" ? "name" : `name_${i18n.language}`
                      ]
                    }`,
                  }))}
                  label={t("ProjectDuration")}
                />
                {errorMessages.projectDuration && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.projectDuration}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              {/* <Box>
                                <FormLabel
                                    sx={{
                                        display: "block",
                                        textAlign: "start",
                                        py: 1.4,
                                        fontSize: 14,
                                    }}
                                >
                                    Estimate Budget
                                </FormLabel>
                                <Dropdown
                                    value={formData.budget}
                                    onChange={handlePrice}
                                    options={projectData?.estimatEnumValues?.map(value => ({
                                        value,
                                        label: `${value?.name}`
                                    }))}
                                    label="Price"
                                />
                                {errorMessages.price && (
                                    <Typography variant="p" sx={{ color: "red" }}>
                                        {errorMessages.price}
                                    </Typography>
                                )}
                            </Box> */}
              <FormControl style={{ marginTop: "45px" }} fullWidth>
                <InputLabel id="dropdown-select-label">
                  {t("EstimateBudget")}
                </InputLabel>
                <Select
                  labelId="dropdown-select-label"
                  value={dropEstimateValues}
                  onChange={handlEstimateBudgetValues}
                  label={t("EstimateBudget")}
                >
                  {dropdownEstimateValues?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("MinBudegte")}
                </FormLabel>
                <TextField
                  placeholder={t("MinBudegte")}
                  onChange={handleMinBudget}
                  value={formData.minBudget}
                  variant="outlined"
                  fullWidth
                />

                {errorMessages.minBudget && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.minBudget}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("MaxBudget")}
                </FormLabel>
                <TextField
                  placeholder={t("MaxBudget")}
                  type="number"
                  value={formData.maxBudget}
                  onChange={handleMaxBudget}
                  variant="outlined"
                  fullWidth
                />

                {errorMessages.maxBudget && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.maxBudget}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          p: 5,
          py: 3.5,
          my: 4,
          borderRadius: 7.5,
          border: 1,
          borderColor: "#d6d6d6",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#000",
            fontWeight: 600,
            pb: 2,
            borderBottom: 1,
            borderColor: "#d6d6d6",
            px: 0,
          }}
        >
          {t("UploadAttachments")}
        </Typography>
        <Box
          className="uploadSec"
          sx={{
            py: 5,
          }}
        >
          <h3 style={{ marginBottom: "12px", marginLeft: "10px" }}>
            {t("Files")} :
          </h3>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "15px",
            }}
          >
            {documents.map((e, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="p" sx={{ color: "#000", px: 3 }}>
                  {t("File")} :{" "}
                  <a
                    href={e}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {e}
                  </a>
                  {/* {file.name} ({file.size / 1024}kB) */}
                </Typography>
                <img
                  src={delete1}
                  alt="Delete"
                  onClick={() => handleDeleteUploadedFile(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    height: "18px",
                  }}
                />
              </Box>
            ))}
          </Box>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            {fileNames.map((file, index) => (
              <Typography
                key={index}
                variant="p"
                sx={{ color: "#000", px: 3 }}
              >
                {t("FileName")} : {file}

              </Typography>
            ))}
          </Box> */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {fileNames.map((file, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="p" sx={{ color: "#000", px: 3 }}>
                  File : {file}
                  {/* {file.name} ({file.size / 1024}kB) */}
                </Typography>
                <img
                  src={delete1}
                  alt="Delete"
                  onClick={() => handleDeleteFile(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    height: "18px",
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box sx={{ py: 4 }}>
            <DropzoneArea
              acceptedFiles={["image/*", ".pdf", ".txt"]}
              dropzoneText={t("DragandDrop")}
              onChange={handleFileChange}
              showPreviewsInDropzone={false}
              showAlerts={false}
              dropzoneclassName="custom-dropzone"
              filesLimit={1}
            />
            {/* {fileError && (
                            <Typography variant="p" sx={{ color: "red", mt: 2 }}>
                                {fileError}
                            </Typography>
                        )} */}
          </Box>
          <Box
            sx={{
              my: 2,
              mb: 0,
              display: "flex",
              justifyContent: "start",
              gap: 2,
            }}
          >
            <Button
              className="SignUpBtn"
              sx={{
                textTransform: "capitalize",
                py: "8px !important",
                px: "24px !important",
                width: "auto !important",
                borderRadius: 12,
                maxWidth: "auto !important",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
              endIcon={
                <Box
                  component="img"
                  src={arrowCross}
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
              }
              // onClick={handleUploadFile}
              onClick={handleSaveButtonClick}
            >
              {t("Save")}
            </Button>
            <Button
              className="SignUpBtn"
              sx={{
                textTransform: "capitalize",
                py: "8px !important",
                px: "24px !important",
                width: "auto !important",
                borderRadius: 12,
                maxWidth: "auto !important",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
              onClick={(ev) => navigate("/dashboard/employer/myprojects")}
              endIcon={
                <Box
                  component="img"
                  src={arrowCross}
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
              }
            >
              {t("close")}
            </Button>
          </Box>
        </Box>
      </Box>
    </section>
  );
}
