// MainLayout.jsx
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

export default function MainLayout() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  return (
    <div className="MainLayout">
      <Header />
      <div className="Content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
