import { Box, Typography, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import TotalUserProjectChart from "./TotalUserProjectChart/TotalUserProjectChart";
import TotalInquires from "./TotalInquires/TotalInquires";
import HelpSupportCard from "./HelpSupportCard/HelpSupportCard";
import LatestProject from "./LatestProject/LatestProject";
import DisputeCase from "./DisputeCase/DisputeCase";
import loader from "../../../Assets/Images/loader.svg";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI } from "../../../Services/Api";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";

export default function AdminDashboard() {
  // Example state to determine the trend (up or down)
  const [trend, setTrend] = useState("up"); // "up" or "down"
  const [data, setData] = useState({});

  const { t } = useTranslation();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const response = await getAPI("dashboard/dashboard-data", token);
        if (response) setData(response);
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  function formatNumber(num) {
    if (Math.abs(num) >= 1e9) {
      return (num / 1e9).toFixed(2) + "B";
    } else if (Math.abs(num) >= 1e6) {
      return (num / 1e6).toFixed(2) + "M";
    } else if (Math.abs(num) >= 1e3) {
      return (num / 1e3).toFixed(2) + "K";
    } else {
      return num.toFixed(2);
    }
  }

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("Dashboard")}
        </Typography>
      </Box>
      {data?.charts && data?.table && data?.card ? (
        <Grid container spacing={4} pb={4}>
          {data?.card && (
            <>
              {/* First Row: 4 Cards */}
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    borderRadius: "15px",
                    boxShadow: "none",
                    py: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 1 }, // Responsive padding y-axis
                    px: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 2 }, // Responsive padding x-axis
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: 14, sm: 16 }, // Responsive font size
                      pb: 1,
                      color: "#626467",
                    }}
                  >
                    {t("totUser")}
                  </Typography>
                  <Grid container spacing={1}>
                    {/* Row 1: Two columns */}
                    <Grid item xs={6} py={"10px!important"}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 600,
                          fontSize: {
                            xxl: 36,
                            xl: 36,
                            lg: 26,
                            md: 26,
                            sm: 20,
                            xs: 20,
                          }, // Responsive font size
                          color: "#0B3013",
                        }}
                      >
                        {data?.card?.users?.total || 0}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      p={0}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      <Box
                        bgcolor={"#e4e5ff"}
                        mt={"-12px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        px={1}
                        py={1}
                        width={40}
                        height={40}
                        borderRadius={4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-users"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="#8280FF"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                      </Box>
                    </Grid>
                    {/* Row 2: Single column */}
                    <Grid item xs={12} pt={"0px !important"}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: 14, sm: 16 }, // Responsive font size
                          pb: 0,
                          color: "#626467",
                        }}
                      >
                        {data?.card?.users?.inDec > 0 ? (
                          <Box display={"flex"} alignItems={"center"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-trending-up"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#00B69B"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M3 17l6 -6l4 4l8 -8" />
                              <path d="M14 7l7 0l0 7" />
                            </svg>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 600,
                                fontSize: 12, // Responsive font size
                                mx: 1,
                                color: "#00B69B",
                              }}
                            >
                              {Math.abs(data?.card?.users?.inDec).toFixed(2)}%
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 600,
                                fontSize: 12, // Responsive font size
                                color: "#606060",
                              }}
                            >
                              {t("upfromLstMonth")}
                            </Typography>
                          </Box>
                        ) : (
                          <Box display={"flex"} alignItems={"center"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-trending-down"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#F93C65"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M3 7l6 6l4 -4l8 8" />
                              <path d="M21 10l0 7l-7 0" />
                            </svg>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 600,
                                fontSize: 12, // Responsive font size
                                mx: 1,
                                color: "#F93C65",
                              }}
                            >
                              {Math.abs(data?.card?.users?.inDec).toFixed(2)}%
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 600,
                                fontSize: 12, // Responsive font size
                                color: "#606060",
                              }}
                            >
                              {data?.card?.users?.inDec < 0
                                ? t("dewnFrmPastMonth")
                                : t("noChngLstmonth")}{" "}
                            </Typography>
                          </Box>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Remaining Cards */}
              {/* Card 2: Total Donation */}
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    borderRadius: "15px",
                    boxShadow: "none",
                    py: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 1 }, // Responsive padding y-axis
                    px: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 2 }, // Responsive padding x-axis
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: 14, sm: 16 }, // Responsive font size
                      pb: 1,
                      color: "#626467!important",
                    }}
                  >
                    {t("totDonation")}
                  </Typography>
                  <Grid container spacing={1}>
                    {/* Row 1: Two columns */}
                    <Grid item xs={6} py={"10px!important"}>
                      <Typography
                        sx={{
                          fontSize: {
                            xxl: 36,
                            xl: 36,
                            lg: 26,
                            md: 26,
                            sm: 20,
                            xs: 20,
                          },
                        }}
                        variant="h4"
                        fontWeight={600}
                        color={"#0B3013"}
                      >
                        ${data?.card?.donation?.total || 0}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      p={"0px !important"}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      <Box
                        bgcolor={"#fef2d6"}
                        mt={"-12px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        px={1}
                        py={1}
                        width={40}
                        height={40}
                        borderRadius={4}
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.9427 16.8354C20.2864 12.8866 18.2432 9.94613 16.467 8.219C15.9501 7.71642 15.6917 7.46513 15.1208 7.23257C14.5499 7 14.0592 7 13.0778 7H10.9222C9.94081 7 9.4501 7 8.87922 7.23257C8.30834 7.46513 8.04991 7.71642 7.53304 8.219C5.75682 9.94613 3.71361 12.8866 3.05727 16.8354C2.56893 19.7734 5.27927 22 8.30832 22H15.6917C18.7207 22 21.4311 19.7734 20.9427 16.8354Z"
                            stroke="#FEC53D"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M12.663 11C12.663 10.5858 12.3273 10.25 11.913 10.25C11.4988 10.25 11.163 10.5858 11.163 11L12.663 11ZM11.163 18C11.163 18.4142 11.4988 18.75 11.913 18.75C12.3273 18.75 12.663 18.4142 12.663 18H11.163ZM12.9857 12.9539C13.2363 13.2836 13.7068 13.3477 14.0366 13.0971C14.3664 12.8464 14.4305 12.3759 14.1798 12.0461L12.9857 12.9539ZM10.7472 16.1022C10.4385 15.826 9.9644 15.8523 9.68816 16.1609C9.41193 16.4696 9.43821 16.9437 9.74687 17.22L10.7472 16.1022ZM11.913 13.6493C11.2657 13.6493 10.971 13.5437 10.8538 13.4647C10.8 13.4285 10.75 13.3874 10.75 13.1895H9.25C9.25 13.771 9.46084 14.3349 10.0158 14.7088C10.5072 15.0398 11.169 15.1493 11.913 15.1493V13.6493ZM10.75 13.1895C10.75 13.094 10.8008 12.946 11.0056 12.7949C11.2101 12.6439 11.5285 12.5279 11.913 12.5279V11.0279C11.241 11.0279 10.6029 11.2279 10.115 11.5879C9.62743 11.9476 9.25 12.5054 9.25 13.1895H10.75ZM13.25 15.8109C13.25 16.0328 13.1711 16.1505 13.0026 16.2518C12.7885 16.3804 12.4212 16.4724 11.913 16.4724V17.9724C12.5575 17.9724 13.2337 17.8628 13.7749 17.5376C14.3617 17.1852 14.75 16.5971 14.75 15.8109H13.25ZM11.913 15.1493C12.5664 15.1493 12.9071 15.2499 13.0685 15.3552C13.1626 15.4165 13.25 15.5063 13.25 15.8109H14.75C14.75 15.1072 14.4896 14.4912 13.888 14.0988C13.3538 13.7504 12.651 13.6493 11.913 13.6493V15.1493ZM12.663 11.7779L12.663 11L11.163 11L11.163 11.7779L12.663 11.7779ZM11.163 17.2224V18H12.663V17.2224H11.163ZM11.913 12.5279C12.4479 12.5279 12.8287 12.7474 12.9857 12.9539L14.1798 12.0461C13.6817 11.3908 12.8126 11.0279 11.913 11.0279V12.5279ZM11.913 16.4724C11.3796 16.4724 10.9651 16.2972 10.7472 16.1022L9.74687 17.22C10.2909 17.7068 11.0853 17.9724 11.913 17.9724V16.4724Z"
                            fill="#FEC53D"
                          />
                          <path
                            d="M7.25662 4.44287C7.05031 4.14258 6.75128 3.73499 7.36899 3.64205C8.00392 3.54651 8.66321 3.98114 9.30855 3.97221C9.89237 3.96413 10.1898 3.70519 10.5089 3.33548C10.8449 2.94617 11.3652 2 12 2C12.6348 2 13.1551 2.94617 13.4911 3.33548C13.8102 3.70519 14.1076 3.96413 14.6914 3.97221C15.3368 3.98114 15.9961 3.54651 16.631 3.64205C17.2487 3.73499 16.9497 4.14258 16.7434 4.44287L15.8105 5.80064C15.4115 6.38146 15.212 6.67187 14.7944 6.83594C14.3769 7 13.8373 7 12.7582 7H11.2418C10.1627 7 9.6231 7 9.20556 6.83594C8.78802 6.67187 8.5885 6.38146 8.18945 5.80064L7.25662 4.44287Z"
                            stroke="#FEC53D"
                            stroke-width="1.5"
                          />
                        </svg>
                      </Box>
                    </Grid>
                    {/* Row 2: Single column */}
                    <Grid item xs={12} pt={"0px !important"}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: 14, sm: 16 }, // Responsive font size
                          pb: 0,
                          color: "#626467!important",
                        }}
                      >
                        {data?.card?.donation?.inDec > 0 ? (
                          <>
                            <Box display={"flex"} alignItems={"center"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trending-up"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#00B69B"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M3 17l6 -6l4 4l8 -8" />
                                <path d="M14 7l7 0l0 7" />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  mx: 1,
                                  color: "#00B69B!important",
                                }}
                              >
                                {Math.abs(data?.card?.donation?.inDec)?.toFixed(
                                  2
                                )}
                                %
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  color: "#606060!important",
                                }}
                              >
                                {t("upfromLstMonth")}
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box display={"flex"} alignItems={"center"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trending-down"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#F93C65"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M3 7l6 6l4 -4l8 8" />
                                <path d="M21 10l0 7l-7 0" />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  mx: 1,
                                  color: "#F93C65!important",
                                }}
                              >
                                {Math.abs(data?.card?.donation?.inDec)?.toFixed(
                                  2
                                )}
                                %
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  color: "#606060!important",
                                }}
                              >
                                {data?.card?.donation?.inDec < 0
                                  ? t("dewnFrmPastMonth")
                                  : t("noChngLstmonth")}{" "}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Card 3: Total Balance */}
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    borderRadius: "15px",
                    boxShadow: "none",
                    py: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 1 }, // Responsive padding y-axis
                    px: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 2 }, // Responsive padding x-axis
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: 14, sm: 16 }, // Responsive font size

                      pb: 1,
                      color: "#626467!important",
                    }}
                  >
                    {t("totBal")}
                  </Typography>
                  <Grid container spacing={1}>
                    {/* Row 1: Two columns */}
                    <Grid item xs={6} py={"10px!important"}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: {
                            xxl: 36,
                            xl: 36,
                            lg: 26,
                            md: 26,
                            sm: 20,
                            xs: 20,
                          },
                        }}
                        fontWeight={600}
                        color={"#0B3013"}
                      >
                        $
                        {!isNaN(data?.card?.balance?.totalBalance)
                          ? formatNumber(data?.card?.balance?.totalBalance)
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      p={"0px !important"}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      <Box
                        bgcolor={"#D9F7E8"}
                        mt={"-12px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        px={1}
                        py={1}
                        width={40}
                        height={40}
                        borderRadius={4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-currency-dollar"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#4AD991"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />
                          <path d="M12 3v3m0 12v3" />
                        </svg>
                      </Box>
                    </Grid>
                    {/* Row 2: Single column */}
                    <Grid item xs={12} pt={"0px !important"}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: 14, sm: 16 }, // Responsive font size

                          pb: 0,
                          color: "#626467!important",
                        }}
                      >
                        {data?.card?.balance?.growthOrLoss > 0 ? (
                          <>
                            <Box display={"flex"} alignItems={"center"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trending-up"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#00B69B"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M3 17l6 -6l4 4l8 -8" />
                                <path d="M14 7l7 0l0 7" />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  mx: 1,
                                  color: "#00B69B!important",
                                }}
                              >
                                {Math.abs(
                                  data?.card?.balance?.growthOrLoss
                                ).toFixed(2)}
                                %
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  color: "#606060!important",
                                }}
                              >
                                {t("upfromLstMonth")}
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box display={"flex"} alignItems={"center"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trending-down"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#F93C65"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M3 7l6 6l4 -4l8 8" />
                                <path d="M21 10l0 7l-7 0" />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  mx: 1,
                                  color: "#F93C65!important",
                                }}
                              >
                                {Math.abs(
                                  data?.card?.balance?.growthOrLoss
                                ).toFixed(2)}
                                %
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  color: "#606060!important",
                                }}
                              >
                                {data?.card?.balance?.growthOrLoss < 0
                                  ? t("dewnFrmPastMonth")
                                  : t("noChngLstmonth")}{" "}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Card 4: Total Projects */}
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    borderRadius: "15px",
                    boxShadow: "none",
                    py: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 1 }, // Responsive padding y-axis
                    px: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 2, xs: 2 }, // Responsive padding x-axis
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: 14, sm: 16 }, // Responsive font size
                      pb: 1,
                      color: "#626467!important",
                    }}
                  >
                    {t("totProjects")}
                  </Typography>
                  <Grid container spacing={1}>
                    {/* Row 1: Two columns */}
                    <Grid item xs={6} py={"10px!important"}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: {
                            xxl: 36,
                            xl: 36,
                            lg: 26,
                            md: 26,
                            sm: 20,
                            xs: 20,
                          }, // Responsive font size
                        }}
                        variant="h4"
                        fontWeight={600}
                        color={"#0B3013"}
                      >
                        {data?.card?.projects?.total || 0}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      p={"0px !important"}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      <Box
                        bgcolor={"#FFE8DF"}
                        mt={"-12px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        px={1}
                        py={1}
                        width={40}
                        height={40}
                        borderRadius={4}
                      >
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.50012 11.9999C2.50012 7.52157 2.50012 5.28239 3.89136 3.89115C5.28261 2.49991 7.52178 2.49991 12.0001 2.49991C16.4785 2.49991 18.7176 2.49991 20.1089 3.89115C21.5001 5.28239 21.5001 7.52157 21.5001 11.9999C21.5001 16.4783 21.5001 18.7174 20.1089 20.1087C18.7176 21.4999 16.4785 21.4999 12.0001 21.4999C7.52178 21.4999 5.28261 21.4999 3.89136 20.1087C2.50012 18.7174 2.50012 16.4783 2.50012 11.9999Z"
                            stroke="#E9855F"
                            stroke-width="1.5"
                          />
                          <path
                            d="M2.5 8H21.5"
                            stroke="#E9855F"
                            stroke-width="1.5"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11 17H17M7 17H8"
                            stroke="#E9855F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11 13H17M7 13H8"
                            stroke="#E9855F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Box>
                    </Grid>
                    {/* Row 2: Single column */}
                    <Grid item xs={12} pt={"0px !important"}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: 14, sm: 16 }, // Responsive font size
                          pb: 0,
                          color: "#626467!important",
                        }}
                      >
                        {data?.card?.projects?.inDec > 0 ? (
                          <>
                            <Box display={"flex"} alignItems={"center"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trending-up"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#00B69B"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M3 17l6 -6l4 4l8 -8" />
                                <path d="M14 7l7 0l0 7" />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  mx: 1,
                                  color: "#00B69B!important",
                                }}
                              >
                                {Math.abs(data?.card?.projects?.inDec).toFixed(
                                  2
                                )}
                                %
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  color: "#606060!important",
                                }}
                              >
                                {t("upfromLstMonth")}
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box display={"flex"} alignItems={"center"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trending-down"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#F93C65"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M3 7l6 6l4 -4l8 8" />
                                <path d="M21 10l0 7l-7 0" />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  mx: 1,
                                  color: "#F93C65!important",
                                }}
                              >
                                {Math.abs(data?.card?.projects?.inDec).toFixed(
                                  2
                                )}
                                %
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: 12, // Responsive font size

                                  color: "#606060!important",
                                }}
                              >
                                {data?.card?.projects?.inDec < 0
                                  ? t("dewnFrmPastMonth")
                                  : t("noChngLstmonth")}{" "}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}

          {data?.charts && (
            <>
              {/* Second Row */}
              <Grid item xs={12} sm={8}>
                <Paper
                  sx={{ padding: 2, borderRadius: "15px", boxShadow: "none" }}
                >
                  <TotalUserProjectChart chartData={data?.charts} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper
                  sx={{
                    padding: 2,
                    borderRadius: "15px",
                    boxShadow: "none",
                    height: "90%",
                  }}
                >
                  <TotalInquires chartData={data?.charts?.inquiry || []} />
                </Paper>
              </Grid>
            </>
          )}

          {data?.table && (
            <>
              {/* Third Row: 3 Cards */}
              <Grid item xs={12} sm={4}>
                <Paper
                  sx={{ padding: 0, borderRadius: "15px", boxShadow: "none" }}
                >
                  <HelpSupportCard data={data?.table?.helpSupport || []} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper
                  sx={{ padding: 0, borderRadius: "15px", boxShadow: "none" }}
                >
                  <DisputeCase data={data?.table?.dispute || []} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper
                  sx={{ padding: 0, borderRadius: "15px", boxShadow: "none" }}
                >
                  <LatestProject data={data?.table?.project || []} />
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <NodataFound />
      )}
    </section>
  );
}
