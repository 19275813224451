// api.js

import axios from "axios";

// const BASE_URL = 'http://68.183.192.148'; // Website url fronend
// const BASE_URL = 'http://localhost:3001'; // Your API base URL

// const BASE_URL = 'http://68.183.192.148:3000'; // Your API base URL
const BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const BASE_URL = "https://dev.api.workwavehub.com";
// const BASE_URL = "http://localhost:8000";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

// GET request
export const getAPI = async (endpoint, token = "") => {
  setAuthToken(token); // Set the token in the headers
  try {
    const response = await axios.get(`${BASE_URL}/${endpoint}`, token);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode == 401) {
      localStorage.removeItem("loginData");
      localStorage.removeItem("notif-token");
      window.location.pathname = "/";
    }
    if (error.response) {
      if (error?.response?.data?.statusCode == 200) {
        return error?.response?.data;
      }
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage || "Failed to fetch data");
    } else {
      throw new Error("Failed to fetch data");
    }
  }
};

// POST request
export const postAPI = async (endpoint, data, token = "") => {
  setAuthToken(token); // Set the token in the headers
  try {
    const response = await axios.post(`${BASE_URL}/${endpoint}`, data, token);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode == 401) {
      localStorage.removeItem("loginData");
      localStorage.removeItem("notif-token");
      window.location.pathname = "/";
    }
    if (error?.response) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage || "Failed to add data");
    } else {
      throw new Error("Failed to add data");
    }
  }
};

// DELETE request
export const deleteAPI = async (endpoint, data, token = "") => {
  try {
    const response = await axios.delete(`${BASE_URL}/${endpoint}`, {
      headers: { Authorization: `Bearer ${token}` },
      data: data, // Send data in the request body
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode == 401) {
      localStorage.removeItem("loginData");
      localStorage.removeItem("notif-token");
      window.location.pathname = "/";
    }
    if (error?.response) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage || "Failed to delete data");
    } else {
      throw new Error("Failed to delete data");
    }
  }
};

// UPDATE request (PUT or PATCH depending on your API)
export const updateAPI = async (endpoint, data, token = "") => {
  try {
    const response = await axios.put(`${BASE_URL}/${endpoint}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode == 401) {
      localStorage.removeItem("loginData");
      localStorage.removeItem("notif-token");
      window.location.pathname = "/";
    }
    if (error?.response) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage || "Failed to update data");
    } else {
      throw new Error("Failed to update data");
    }
  }
};
