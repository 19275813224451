import React from "react";
import { Box, Typography } from "@mui/material";
import second from "../../Assets/Images/Nodata-bro.png";
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";

export default function NodataFound() {
  const { t } = useTranslation();

  return (
    <Box sx={{ textAlign: "center !important", py: 5 }}>
      <Box
        component="img"
        sx={{
          height: { xs: 233, md: 167, lg: 300, md: 300, xl: 500 },
          width: { xs: 350, md: 250, xl: 500, lg: 300, md: 300, xl: 500 },
          margin: "auto",
        }}
        alt="No data found illustration"
        src={second}
      />
      <Typography
        variant="h4"
        sx={{ marginTop: 2, textAlign: "center !important" }}
      >
        {t("NoDataFound")}
      </Typography>
    </Box>
  );
}
