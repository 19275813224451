import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormLabel,
  Button,
  TextField,
} from "@mui/material";
import arrowCross from "../../../Assets/Images/arrow-up-right-01-round.png";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
  isValidEmail,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import loader from "../../../Assets/Images/loader.svg";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import OTPInput from "react-otp-input";
import vecNoimag from "../../../Assets/Images/App.png";
export default function EmployeerVerifyEmail() {
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState({});

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState("sendOTP"); // 'sendOTP' or 'verifyOTP'
  const [errorMessage, setErrorMessage] = useState("");
  const [otpError, setOtpError] = useState("");

    const handleOnchangeEmail = (ev) => {
      const newErrors = {};
      setEmail(ev.target.value);
      if (!ev.target.value.trim()) {
        newErrors.email = t("EmailIsRequired");
      } else if (!isValidEmail(ev.target.value)) {
        newErrors.email = t("InvalidEmailFormat");
      }
      setErrorMessage(newErrors);
    };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setUserDetails(response.data);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const handleSendOTP = async () => {
    const newErrors = {};

    if (!email.trim()) {
      newErrors.email = t("EmailIsRequired");
    } else if (!isValidEmail(email)) {
      newErrors.email = t("InvalidEmailFormat");
    }

    setErrorMessage(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        LoaderShow();
        const response = await postAPI(`auth/send-email-otp`, { email });
        if (response.statusCode === 200) {
          LoaderHide();
          setStep("verifyOTP");
          toast.success(t(response?.message));
        } else {
          LoaderHide();
          setErrorMessage("Failed to send OTP.");
          toast.success(t(response?.message));
        }
      } catch (error) {
        LoaderHide();
        setErrorMessage("Error sending OTP. Please try again.");
        toast.error(error?.message);
      }
    }
  };

  const handleVerifyOTP = async () => {
    if (!otp) {
      setOtpError(t("PleaseEnterOpt"));
    } else if (otp.length !== 6) {
      setOtpError(t("OTP6Digit"));
    } else {
      try {
        LoaderShow();
        const response = await postAPI(`auth/verify-email-otp`, {
          email,
          emailOTP: otp,
        });
        if (response.statusCode === 200) {
          toast.success(t(response?.message));
          LoaderHide();
          window.location.reload(true);
        } else {
          LoaderHide();
          setErrorMessage(t("toast13"));
          toast.error(t(response?.message));
        }
      } catch (error) {
        LoaderHide();
        setErrorMessage("Error verifying OTP. Please try again.");
        if (error.message.includes("invalid")) {
          toast.error(t("toast13"));
          return;
        }
        if (error.message.includes("code-expired")) {
          toast.error(t("toast14"));
          return;
        }
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      {false ? (
        <div>{t("EmailAlreadyVerified")}</div>
      ) : userDetails?.isEmailVerified ? (
        <Box
          sx={{ background: "#fff", borderRadius: 5, px: 4, py: 10, my: 3 }}
          xs={12}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={vecNoimag} alt="dhn" />
          </Box>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", color: "#0B3013" }}
          >
            {t("EmailAlreadyVerified")}
          </Typography>
        </Box>
      ) : (
        <>
          <Box>
            <Box>
              <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
                {t("VerifyEmail")}
              </Typography>
              {/* <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
                {step === 'sendOTP' ? <div>{t("SendOTP")}</div> : <div>{t("VerifyEmail")}</div>}
              </Typography> */}
              <Box>
                <Grid container>
                  <Grid
                    sx={{
                      background: "#fff",
                      borderRadius: 5,
                      px: 4,
                      py: 2,
                      my: 3,
                    }}
                    xs={12}
                  >
                    <Box>
                      {/* <Box
                        sx={{ borderBottom: 1, borderColor: "#E9E9E9", py: 1 }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        </Typography>
                      </Box> */}
                      {step === "sendOTP" ? (
                        <Box sx={{ py: 2 }}>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1,
                              fontSize: 14,
                            }}
                          >
                            {t("Email")}
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            sx={{ width: 500, maxWidth: 400 }}
                            placeholder={t("EnterEmailAdd")}
                            type="email"
                            value={email}
                            onChange={handleOnchangeEmail}
                            // error={!!errorMessage.email}
                            // helperText={errorMessage.email}
                          />
                          {errorMessage.email && (
                            <Typography
                              variant="body2"
                              sx={{ color: "red", mt: 1 }}
                            >
                              {errorMessage.email}
                            </Typography>
                          )}
                          <Button
                            onClick={handleSendOTP}
                            className="SignUpBtn"
                            sx={{
                              mt: 4,
                              textTransform: "capitalize",
                              color: "white",
                            }}
                          >
                            {t("SendOTP")}
                          </Button>
                        </Box>
                      ) : (
                        <Box sx={{ p: 8, pl: 4 }}>
                          <Box>
                            <Typography
                              variant="h5"
                              sx={{ textAlign: "start", fontWeight: 700 }}
                            >
                              {t("OTP")}{" "}
                            </Typography>
                            <p className="beginText">{t("SentOPT")} </p>
                          </Box>

                          <Box sx={{ pt: 3 }}>
                            <OTPInput
                              inputStyle={{
                                border: "2px solid #0B3013",
                                borderRadius: "8px",
                                width: "54px",
                                height: "54px",
                                fontSize: "18px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue",
                              }}
                              focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none",
                              }}
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              inputType="password"
                              renderSeparator={
                                <span
                                  style={{ paddingLeft: 5, paddingRight: 5 }}
                                ></span>
                              }
                              renderInput={(props) => (
                                <input {...props} className="otpInput" />
                              )}
                            />
                            {otpError && (
                              <Typography
                                variant="body2"
                                sx={{ color: "red", mt: 1 }}
                              >
                                {otpError}
                              </Typography>
                            )}
                          </Box>

                          <Box sx={{ pt: 5 }}></Box>
                          <Box sx={{ mt: 4 }}>
                            <Button
                              onClick={handleVerifyOTP}
                              className="SignUpBtn"
                              sx={{
                                textTransform: "capitalize",
                                width: 100,
                                color: "white",
                              }}
                            >
                              {t("Verify")}
                            </Button>
                          </Box>
                          {/* <Button
                            onClick={() => setStep("sendOTP")}
                            sx={{ mt: 2, ml: 2 }}
                          >
                            {t("BackButton")}
                          </Button> */}
                          <Box sx={{ my: 1 }}>
                            <FormLabel
                              sx={{
                                display: "flex",
                                textAlign: "center",
                                py: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: 14,
                              }}
                            >
                              <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
                              {t("YourInfo")}
                            </FormLabel>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
