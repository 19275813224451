import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  TextField,
  Grid,
  Paper,
  Typography,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Button,
  InputAdornment,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import OtpInput from "react-otp-input";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CssBaseline from "@mui/material/CssBaseline";
import ModalAuthBanner from "../../Assets/Images/signinauthBanner.png";
import Employee from "../../Assets/Images/Employee.png";
import Employer from "../../Assets/Images/Employeer.png";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import {
  LoaderHide,
  LoaderShow,
  hasSpecialCharacter,
  isValidEmail,
} from "../../Helpers/Utility";
import { useNavigate, useSearchParams } from "react-router-dom";
import { postAPI } from "../../Services/Api";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "../../Helpers/Utility";
import axios from "axios";
import BrowseEmployer from "../Signup/SignupSteps/Employer/BrowseEmployer/BrowseEmployer";
import ModalBannerText3 from "../Signup/ModalBannerText/ModalBannerText3";
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
import CloseIcon from "@mui/icons-material/Close";
import { auth, db, messaging } from "../Firebase/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithCredential,
} from "firebase/auth";

import loader from "../../Assets/Images/loader.svg";
import { getToken } from "firebase/messaging";
import {
  ACCOUNTANT_ROLE,
  ADMIN_ROLE,
  CHAT_ROLE,
  EMPLOYEE_ROLE,
  EMPLOYER_ROLE,
} from "../../Helpers/roles";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Signin({
  open,
  handleClose: close,
  handleOpenSignupModal,
  toggleSignUpModal,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [browseModalOpen, setBrowseModalOpen] = useState(false);
  const [errors, setErrors] = React.useState({});
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedTab, setSelectedTab] = useState("employer");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isRegistering, setIsRegistering] = useState(false); // State for registration section visibility
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [appVerifier, setAppVerifier] = useState(false);
  const navigate = useNavigate();

  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const [resendDisabled, setResendDisabled] = useState(true); // Resend button disabled state
  const [confirmationResult, setConfirmationResult] = useState(null);
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("change_password_token")) setForgotPasswordOpen(true);
  }, []);

  useEffect(() => {
    LoaderHide();
  }, []);

  const handleClose = () => {
    close();
    setIsRegistering(false);
    setTimer(120);
    setForgotPasswordOpen(false);
  };
  useEffect(() => {
    if (isRegistering) {
      let intervalId;
      if (timer > 0) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        setResendDisabled(false); // Enable resend button when timer reaches 0
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }
  }, [timer, isRegistering]);

  const handleResendOtp = async () => {
    await handleSignInEmployer();
    setTimer(120);
    setResendDisabled(true);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleTermsCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const handleForgotPasswordClick = () => {
    handleClose();
    setForgotPasswordOpen(true);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneNumberError("");
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleBrowseModalClose = () => {
    setBrowseModalOpen(false);
  };

  const getNotifToken = async () => {
    try {
      const permission = await Notification.requestPermission();
      let token;
      if (permission === "granted") {
        token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        return token;
      } else {
        toast.info(t("toast3"), {
          position: "top-right",
          autoClose: false, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        sessionStorage.setItem("notif-shown", true);
      }
      return false;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignInEmployee = async () => {
    LoaderShow();
    const newErrors = {};

    if (!email.trim()) {
      newErrors.email = t("EmailIsRequired");
    } else if (!isValidEmail(email)) {
      newErrors.email = t("InvalidEmailFormat");
    }

    if (!password.trim()) {
      newErrors.password = t("PasswordIsRequired");
    } else if (password.length < 8 || !hasSpecialCharacter(password)) {
      newErrors.password = t("PassWordMustBe8Characters");
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const dataToSend = {
          email: email,
          password: password,
        };
        const token = await getNotifToken();
        if (token) dataToSend.web_token = token;
        const response = await postAPI("user/employee/login", dataToSend);
        LoaderShow();
        if (response.statusCode === 200) {
          LoaderHide();
          if (token) localStorage.setItem("notif-token", token);
          toast.success(t(response.message));
          sessionStorage.removeItem("hasNavigated");
          var loginDetails = response.data;
          var objLoginData = {
            userId: loginDetails._id,
            firstName: loginDetails.firstName,
            token: response.token,
            role: loginDetails.role,
            userProfile: loginDetails.profile,
            email: loginDetails.email,
          };
          localStorage.setItem("loginData", JSON.stringify(objLoginData));
          if (loginDetails.email == process.env.REACT_APP_ADMIN_EMAIL) {
            navigate("/dashboard/employee/payments");
          } else {
            navigate("/dashboard/employee");
          }

          handleClose();
        }
      } catch (error) {
        LoaderHide();
        toast.error(error.message);
      }
    }
    LoaderHide();
  };

  const renderInput = ({ onChange, onBlur, onFocus }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PhoneInput
        placeholder="0000000000"
        country={"us"}
        value={phoneNumber}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        inputStyle={{
          background: isFocused ? "#FFFFFF" : "transparent",
          borderColor: isFocused ? "#0B3013" : "#E0E0E0",
          boxShadow: isFocused ? "0px 4px 10px 3px rgba(0,0,0,0.11)" : "none",
          width: "100%",
          padding: "12px 16px",
          borderRadius: "4px",
          outline: "none",
          boxSizing: "border-box",
          fontSize: "16px",
        }}
        containerStyle={{ width: "100%" }}
        inputProps={{ autoFocus: false }}
      />
      <span style={{ marginLeft: "5px", fontSize: "16px" }}>
        {phoneNumber ? `(+${phoneNumber.replace(/\D/g, "")})` : ""}
      </span>
    </div>
  );

  const getPhoneNumberExistance = async () => {
    LoaderShow();

    if (!phoneNumber) {
      setPhoneNumberError(t("PleaseEnterPhoneNumber"));
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError(t("PleaseEnterValidNumber"));
    } else {
      const dataToSend = {
        phoneNumber: `+${phoneNumber}`,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/checkExistence`,
          dataToSend
        );
        LoaderHide();
        return response;
      } catch (error) {
        if (error.response) {
          LoaderHide();
          return error.response.data;
        } else if (error.request) {
          console.error("No response received from the server");
        } else {
          console.error("Error:", error.message);
        }
      }
    }
  };

  const handleSignInEmployer = async () => {
    LoaderShow();

    const pnoneNumberExists = await getPhoneNumberExistance();

    if (pnoneNumberExists?.userStatus == "Suspended") {
      toast.error(t("toast6"));
      return;
    }
    if (pnoneNumberExists?.userStatus == "Inactive") {
      toast.error(t("toast7"));
      return;
    }
    if (
      pnoneNumberExists?.message === "Phone number already exists" ||
      pnoneNumberExists?.message ===
        "User already registered with a different role"
    ) {
      LoaderShow();
      if (!phoneNumber) {
        setPhoneNumberError(t("PleaseEnterPhoneNumber"));
      } else if (!isValidPhoneNumber(phoneNumber)) {
        setPhoneNumberError(t("PleaseEnterValidNumber"));
      } else {
        const dataToSend = {
          phoneNumber: `+${phoneNumber}`,
          role: "employer",
        };

        try {
          // before sigin login code
          // const response = await postAPI('auth/send-otp', dataToSend);

          // if (response.statusCode === 200) {
          //   LoaderHide()
          //   localStorage.setItem("mobile", response.otp)
          //   localStorage.setItem("phoneNumber", phoneNumber)
          //   setIsRegistering(true); // Toggle to display registration section
          //   toast.success(t(response.message));
          //   setPhoneNumberError('');

          // } else {
          //   toast.error(t(response.message));
          //   LoaderHide()

          // }

          // firebase login
          LoaderShow();
          let newAppVerifier;
          if (!appVerifier) {
            newAppVerifier = new RecaptchaVerifier(
              auth,
              "recaptcha-container",
              {
                size: "invisible",
                callback: (response) => {
                  // reCAPTCHA solved - will proceed with submit function
                },
                "expired-callback": () => {
                  // Response expired. Ask user to solve reCAPTCHA again.
                },
              }
            );
            setAppVerifier(newAppVerifier);
          }
          LoaderShow();

          // Old Promise.race method

          // const timeoutPromise = new Promise((_, reject) => {
          //   setTimeout(() => {
          //     reject(new Error("Timeout: OTP request took too long."));
          //   }, 10000); // 10 seconds timeout
          // });

          // const response = await signInWithPhoneNumber(auth, "+" + phoneNumber, appVerifier)
          // const response = await Promise.race([
          //   signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier),
          //   timeoutPromise,
          // ]);
          const response = await signInWithPhoneNumber(
            auth,
            `+${phoneNumber}`,
            appVerifier || newAppVerifier
          );

          // console.log("response======", response);
          LoaderShow();
          if (response !== undefined) {
            LoaderHide();
            localStorage.setItem("mobile", response.otp);
            localStorage.setItem("phoneNumber", phoneNumber);
            setTimer(120);
            setResendDisabled(true);
            setIsRegistering(true); // Toggle to display registration section
            // toast.success(t(response.message));
            setPhoneNumberError("");
            setConfirmationResult(response);
          }
        } catch (error) {
          if (error.message.includes("auth/too")) {
            toast.error(t("toast7"));
          } else if (error.message.includes("invalid")) {
            toast.error(t("toast8"));
          } else if (error.message.includes("Firebase")) {
            toast.error(t("toast9"));
          } else {
            toast.error(error.message);
          }
          LoaderHide();
        }
      }
    } else {
      toast.error(t("toast11"));
      LoaderHide();
    }
    LoaderHide();
  };

  const handleVerifyOTP = async () => {
    LoaderShow();
    // Validate OTP here
    // const validOtp = localStorage.getItem("mobile")
    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!otp) {
      setOtpError(t("PleaseEnterOpt"));
    } else if (otp.length !== 6) {
      setOtpError(t("OTP6Digit"));
    } else {
      setOtpError("");
      const dataToSend = {
        // otp: otp,
        phoneNumber: `+${phoneNumber}`,
        userOTP: otp,
      };

      try {
        LoaderShow();

        // const response = await postAPI('auth/verify-otp', dataToSend);

        // if (response.statusCode === 200) {

        //   const data = {
        //     phoneNumber: `+${phoneNumber}`,
        //   }

        //   const responselogin = await postAPI("user/employer/login", data);
        //   console.log("Post response:", response);
        //   if (responselogin.statusCode === 200) {
        //     LoaderHide()
        //     toast.success(responselogin.message);
        //     setBrowseModalOpen(true);
        //     handleClose();
        //     // navigate("/services");
        //     // window.location.reload(true)
        //     var loginDetails = responselogin.data;
        //     var objLoginData = {
        //       userId: loginDetails._id,
        //       token: responselogin.token,
        //       role: loginDetails.role,
        //       userProfile: loginDetails.profile,
        //       firstName: loginDetails.firstName,
        //       phoneNumber: loginDetails.phoneNumber,
        //     };
        //     localStorage.setItem("loginData", JSON.stringify(objLoginData));
        //     handleClose();
        //   } else {
        //     toast.error("login failed");
        //     LoaderHide()
        //   }
        // } else {
        //   toast.error("Invalid OTP",);
        //   LoaderHide()
        // }
        const response = await confirmationResult?.confirm(otp);

        if (response?.user !== undefined) {
          const data = {
            phoneNumber: `+${phoneNumber}`,
            languagePreference: i18n.language,
          };
          const token = await getNotifToken();
          if (token) data.web_token = token;
          const responselogin = await postAPI("user/employer/login", data);
          if (responselogin.statusCode === 200) {
            LoaderHide();
            if (token) localStorage.setItem("notif-token", token);
            toast.success(t("toast52"));
            handleClose();
            // navigate("/services");
            sessionStorage.removeItem("hasNavigated");
            // window.location.reload(true)
            var loginDetails = responselogin.data;
            var objLoginData = {
              userId: loginDetails._id,
              token: responselogin.token,
              role: loginDetails.role.map((ele) => ele._id),
              userProfile: loginDetails.profile,
              firstName: loginDetails.firstName,
              phoneNumber: loginDetails.phoneNumber,
              email: loginDetails.email,
            };
            localStorage.setItem("loginData", JSON.stringify(objLoginData));
            // if (loginDetails?.languagePreference)
            //   localStorage.setItem(
            //     "lang",
            //     loginDetails?.languagePreference || "en"
            //   );
            sessionStorage.setItem("hasNavigated", true);
            try {
              if (response?.user?.uid) {
                setDoc(
                  doc(db, "users", response?.user?.uid),
                  {
                    firstName: objLoginData?.firstName || "",
                    lastName: loginDetails?.lastName || "",
                    imageUrl: loginDetails.profile || "",
                    createdAt: Timestamp.now(),
                    lastSeen: Timestamp.now(),
                    metadata: null,
                    role: null,
                    updatedAt: Timestamp.now(),
                    fcm_token: token,
                  },
                  { merge: true }
                );
              }
            } catch (err) {
              console.log(t(err.message));
            }
            if (objLoginData.role.includes(ADMIN_ROLE)) {
              window.location.replace("/dashboard/admin/dashboard");
              handleClose();
              // window.location.replace();
              return;
            }

            if (objLoginData.role.includes(EMPLOYER_ROLE)) {
              setBrowseModalOpen(true);
              return;
            }
            if (objLoginData.role.includes(EMPLOYEE_ROLE)) {
              window.location.replace("/dashboard/employee");
            }
            if (objLoginData.role.includes(CHAT_ROLE)) {
              window.location.replace("/dashboard/admin/chat");
            }
            if (objLoginData.role.includes(ACCOUNTANT_ROLE)) {
              window.location.replace("/dashboard/accountant/payments");
            }
            handleClose();
            // window.location.reload();
          } else {
            toast.error(t("toast12"));
            LoaderHide();
          }
        } else {
          toast.error(t("toast13"));
          LoaderHide();
        }
      } catch (error) {
        LoaderHide();
        if (error.message.includes("invalid")) {
          toast.error(t("toast13"));
          return;
        }
        if (error.message.includes("expired")) {
          toast.error(t("toast14"));
          return;
        }
        if (error.message.includes("Firebase")) {
          toast.error(t("toast9"));
          return;
        }
        toast.error(error.message);
      }
    }
    LoaderHide();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="signin-modal-title"
        aria-describedby="signin-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 1200,
            backgroundColor: "#f8f9f8 !important",
            boxShadow: 24,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <CloseIcon
            onClick={(ev) => handleClose()}
            style={{
              position: "absolute",
              zIndex: 500,
              right: 10,
              top: 10,
              cursor: " pointer",
              borderRadius: "100%",
              paddingRight: "4px",
            }}
          />
          <div
            id="hideloding"
            className="loding-display"
            style={{ display: "none" }}
          >
            <img src={loader} alt="loader-img" />
          </div>
          <Grid
            container
            component="main"
            sx={{ height: "100vh", maxHeight: "90vh" }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "block" }, // Hide on extra-small screens
                backgroundImage: `url(${ModalAuthBanner})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "top",
                position: "relative", // Add relative positioning for the text container
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white", // Adjust text color based on background
                  textAlign: "center",
                  padding: "16px",
                  width: "100%",
                  mt: 4,
                  // backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for text readability
                  borderRadius: "8px",
                }}
              >
                <ModalBannerText3 />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              component={Paper}
              sx={{
                backgroundColor: "#f8f9f8 !important",
                overflowY: "auto",
                maxHeight: "calc(100vh - 64px)",
              }}
              elevation={6}
              square
            >
              <Box sx={{ p: 9, pt: 5 }} className="sideBg1">
                {/* <Tabs
                  scrollButtons
                  allowScrollButtonsMobile
                  value={selectedTab}
                  onChange={(event, newValue) => setSelectedTab(newValue)}
                  indicatorColor="#0B3013"
                  textColor="primary"
                  centered
                  variant="fullWidth"
                  sx={{ my: 3 }}
                >
                  <Tab
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Employer}
                          alt="Employer Icon"
                          height={30}
                          width={30}
                          style={{
                            marginRight: 8,
                            filter:
                              selectedTab === "employee" ? "invert(1)" : "none",
                          }}
                        />
                        {t("Employee")}
                      </div>
                    }
                    value="employee"
                    sx={{
                      textTransform: "capitalize",
                      color: "#000",
                      backgroundColor: "#F7FDF9",
                      border: 1,
                      borderColor: "#0B3013",
                      borderRadius: 2,
                      fontFamily: "inherit!important",
                      mx: 1,
                      ml: 0,
                      "&.Mui-selected": {
                        backgroundColor: "#0B3013",
                        color: "#fff",
                        border: 0,
                        fontFamily: "inherit",
                        textTransform: "capitalize",
                      },
                    }}
                  />
                  <Tab
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Employee}
                          alt="Employer Icon"
                          height={30}
                          width={30}
                          style={{
                            marginRight: 8,
                            filter:
                              selectedTab === "employer" ? "invert(1)" : "none",
                          }}
                        />
                        {t("Employer")}
                      </div>
                    }
                    value="employer"
                    sx={{
                      textTransform: "capitalize",
                      fontFamily: "inherit!important",
                      borderRadius: 2,
                      backgroundColor: "#F7FDF9",
                      border: 1,
                      borderColor: "#0B3013",
                      color: "#000",
                      mx: 1,
                      mr: 0,
                      "&.Mui-selected": {
                        backgroundColor: "#0B3013",
                        color: "#fff",
                        fontFamily: "inherit!important",
                      },
                    }}
                  />
                </Tabs> */}
                <div id="recaptcha-container"></div>
                {selectedTab === "employee" && (
                  <div>
                    <Box sx={{ paddingBottom: 4 }}>
                      <Box sx={{ width: "100%", marginTop: 5 }}>
                        <div>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1,
                              fontSize: 14,
                            }}
                          >
                            {t("Email")}
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            sx={{
                              background: isFocused ? "#FFFFFF" : "transparent",
                              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                              boxShadow: isFocused
                                ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                                : "none",
                            }}
                            fullWidth={true}
                            placeholder={t("EnterEmailAdd")}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!!errors.email}
                            helperText={errors.email}
                          />
                        </div>
                        <Box sx={{ my: 1 }}>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1,
                              fontSize: 14,
                            }}
                          >
                            {t("password")}
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            sx={{
                              background: isFocused ? "#FFFFFF" : "transparent",
                              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                              boxShadow: isFocused
                                ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                                : "none",
                            }}
                            fullWidth={true}
                            placeholder={t("Enter")}
                            type={showPassword ? "text" : "password"}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!errors.password}
                            helperText={errors.password}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            my: 2,
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          {/* <FormControlLabel
                            sx={{ fontSize: 14 }}
                            className=""
                            control={
                              <Checkbox
                                checked={termsChecked}
                                onChange={handleTermsCheckboxChange}
                              />
                            }
                            label={t("Remember")}
                          /> */}
                          <Typography
                            variant="h8"
                            sx={{
                              textAlign: "center",
                              fontWeight: 700,
                              fontFamily: "inherit",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            className=""
                            onClick={handleForgotPasswordClick}
                          >
                            {t("Forgot")}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            my: 2,
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Button
                            onClick={handleSignInEmployee}
                            className="SignUpBtn"
                            sx={{
                              textTransform: "capitalize",
                              width: 100,
                              color: "white",
                            }}
                          >
                            {t("LoginEmployee")}{" "}
                          </Button>
                        </Box>
                        <Box sx={{ my: 1 }}>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "center",
                              py: 1,
                              fontSize: 14,
                              cursor: "pointer",
                            }}
                            onClick={toggleSignUpModal}
                          >
                            {t("DontHavAccount")}{" "}
                            <span
                              className="SigninTextJoin"
                              // onClick={() => setSelectedTab("employer")}
                            >
                              {t("SignUpEmployee")}
                            </span>
                          </FormLabel>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                )}

                {selectedTab === "employer" && (
                  <>
                    {!isRegistering && (
                      <>
                        <Box className="padTopZero">
                          {/* <div className="signinText">
                    <span>Not Register yet?</span>{" "}
                    <span className="SigninTextJoin" onClick={toggleSignUpModal}>Create an Account</span>
                  </div> */}
                          <Box sx={{ py: 6, pb: 0 }} className="">
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: "start",
                                fontWeight: 700,
                                fontFamily: "inherit",
                                cursor: "pointer",
                              }}
                              className=""
                            >
                              {t("Welcome")}
                            </Typography>
                            <p className="beginText">{t("BeginJourney")}</p>
                          </Box>
                        </Box>
                        <Box sx={{ marginTop: 5 }}>
                          <div>
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1,
                                fontSize: 14,
                              }}
                            >
                              {t("Phone")}
                            </FormLabel>
                            <PhoneInput
                              country={"us"}
                              value={phoneNumber}
                              onChange={handlePhoneNumberChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              inputComponent={renderInput}
                            />
                            {phoneNumberError && (
                              <Typography variant="body2" sx={{ color: "red" }}>
                                {phoneNumberError}
                              </Typography>
                            )}
                          </div>
                          <Button
                            onClick={handleSignInEmployer} // Toggle to display registration section
                            className="SignUpBtn"
                            sx={{
                              my: 4,
                              textTransform: "capitalize",
                              width: 100,
                              color: "white",
                            }}
                          >
                            {t("LoginEmployee")}
                          </Button>
                          <Box
                            sx={{
                              my: 2,
                              display: "flex",
                              justifyContent: "start",
                            }}
                          ></Box>
                          <Box sx={{ my: 1 }}>
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "center",
                                py: 1,
                                fontSize: 14,
                                cursor: "pointer",
                              }}
                              onClick={toggleSignUpModal}
                            >
                              {t("DontHavAccount")}{" "}
                              <span
                                className="SigninTextJoin"
                                // onClick={() => setSelectedTab("employer")}
                              >
                                {t("SignUpEmployee")}
                              </span>
                            </FormLabel>
                          </Box>
                        </Box>
                      </>
                    )}

                    {isRegistering && ( // Conditionally render the registration section based on state
                      <Box sx={{ width: "100%", marginTop: 8 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <Box>
                            <div
                              style={{
                                display: "flex",
                                gap: "7px",
                                alignItems: "center",
                              }}
                            >
                              <ArrowBackIcon
                                onClick={(ev) => setIsRegistering(false)}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                              <Typography
                                variant="h5"
                                sx={{ textAlign: "start", fontWeight: 700 }}
                              >
                                {t("OTP")}{" "}
                              </Typography>
                            </div>
                            <p className="beginText">{t("WeOTP")}</p>
                          </Box>

                          <Box sx={{ pt: 3 }}>
                            <OtpInput
                              inputStyle={{
                                border: "2px solid #0B3013",
                                borderRadius: "8px",
                                width: "54px",
                                height: "54px",
                                fontSize: "18px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue",
                              }}
                              focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none",
                              }}
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              inputType="password"
                              renderSeparator={
                                <span
                                  style={{ paddingLeft: 5, paddingRight: 5 }}
                                ></span>
                              }
                              renderInput={(props) => (
                                <input {...props} className="otpInput" />
                              )}
                              containerStyle="otpContainer" // Apply the custom CSS class
                            />
                            {otpError && (
                              <Typography
                                variant="body2"
                                sx={{ color: "red", mt: 1 }}
                              >
                                {otpError}
                              </Typography>
                            )}
                          </Box>

                          <Box
                            sx={{
                              pt: 5,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span className="timerText">{`${
                              timer < 10 ? "0" : ""
                            }${timer} Sec`}</span>
                            <span
                              style={{
                                cursor: resendDisabled ? "disabled" : "pointer",
                              }}
                              className={`resend ${
                                resendDisabled ? "disabled" : ""
                              }`}
                              onClick={
                                !resendDisabled ? handleResendOtp : undefined
                              }
                            >
                              {t("resendOTP")}
                            </span>
                          </Box>

                          <Box
                            sx={{
                              mt: 4,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              onClick={handleVerifyOTP}
                              className="SignUpBtn"
                              sx={{
                                textTransform: "capitalize",
                                width: 100,
                                color: "white",
                              }}
                            >
                              {t("Verify")}
                            </Button>
                          </Box>

                          <Box
                            sx={{
                              my: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FormLabel
                              sx={{
                                display: "flex",
                                textAlign: "center",
                                py: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: 14,
                              }}
                            >
                              <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
                              {t("YourInfo")}
                            </FormLabel>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ForgotPassword
        open={forgotPasswordOpen}
        handleClose={handleForgotPasswordClose}
      />

      <BrowseEmployer
        open={browseModalOpen}
        handleClose={handleBrowseModalClose}
      />
    </>
  );
}
