import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  FormLabel,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import { DropzoneArea } from "material-ui-dropzone";
import del from "../../../Assets/Images/delet.png";
import docsx from "../../../Assets/Images/document-attachment.png";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  GetUserDetails,
  isValidEmail,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { deleteAPI, getAPI, postAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../Auth/Firebase/firebase";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import deleteVec from "../../../Assets/Images/deleteVector.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const EditSupportManagemnt = ({ user, onBackClick, ticket }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isFocused, setIsFocused] = useState(false);

  // Default values for dropdowns
  const [name, setName] = useState(""); // Default to 'active'
  const [email, setEmail] = useState(""); // Default to 'active'
  const [role, setRole] = useState(""); // Default to 'user'
  const [gender, setGender] = useState("Male"); // Default to 'male'
  const [status, setStatus] = useState("Activated"); // Default to 'active'
  const [message, setMessage] = useState(""); // Default to 'user'
  const [replyMessage, setReplyMessage] = useState(""); // Default to 'user'
  const [title, setTitle] = useState(""); // Default to 'male'

  const [roles, setRoles] = useState([]);
  const [error, setError] = useState({}); // Default to 'male'

  const { t } = useTranslation();

  useEffect(() => {
    if (ticket) {
      setName(ticket.firstName);
      setEmail(ticket.email);
      setMessage(ticket.message);
      setTitle(ticket.title);
    }
  }, [ticket]);

  const validateForm = () => {
    let isValid = true;
    let err = {};
    if (!replyMessage.trim()) {
      err = { ...err, replyMessage: t("plzEnterMsg") };
      isValid = false;
    }
    setError(err);
    return isValid;
  };

  // Handle input focus
  const handleFocus = () => {
    // validateForm();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (user) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendReply = async (ev) => {
    ev.preventDefault();
    if (!validateForm()) return;
    if (!ticket?.email) {
      toast.error(t("toast49"));
      return;
    }
    LoaderShow();
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const dataToSend = new FormData();
        dataToSend.append("id", ticket._id);
        dataToSend.append("replyMessage", replyMessage);
        files.forEach((file, index) => {
          dataToSend.append(`replyDocuments`, file);
        });
        const response = await postAPI(`contactus/reply`, dataToSend, token);
        if (response.statusCode === 200) {
          toast.success(t("toast23"));
          onBackClick();
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    }
    LoaderHide();
  };

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleFileChange = (newFiles) => {
    if (
      [...files, ...newFiles].reduce((val, ele) => ele?.size + val, 0) >
      1_048_576
    ) {
      toast.warn(t("toast16"));
      return;
    }
    setFiles((dt) => [...dt, ...newFiles]);
    const names = newFiles.map((file) => file.name);
    setFileNames((dt) => [...dt, ...names]);
  };

  const handleDeleteFile = (file, index) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => index !== i));
    setFileNames((prevFiles) => prevFiles.filter((f, i) => index !== i));
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "30px",
          padding: 4,
          position: "relative",
        }}
      >
        <Box
          sx={{ borderBottom: "1px solid #E9E9E9", py: 1.7, mb: 2, pt: 0.6 }}
        >
          <Typography
            variant="span"
            sx={{
              fontSize: 18,
              fontWeight: "semibold",
              fontFamilly: "inherit",
            }}
          >
            {t("query")}
          </Typography>
        </Box>
        <Box component="form">
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              {!window.location.pathname.includes("support-management") ? (
                <Box sx={{ flex: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      mb: 1,
                      color: "black",
                      fontSize: 14,
                    }}
                  >
                    {t("name")}
                  </FormLabel>
                  <TextField
                    value={name}
                    variant="outlined"
                    sx={{
                      background: isFocused ? "#FFFFFF" : "transparent",
                      borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                    }}
                    fullWidth
                    placeholder={t("name")}
                    disabled
                    // onFocus={handleFocus}
                    // onBlur={handleFocus}
                    // error={!!error.name}
                    // helperText={error.name}
                    // required
                  />
                </Box>
              ) : (
                <Box sx={{ flex: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      mb: 1,
                      color: "black",
                      fontSize: 14,
                    }}
                  >
                    {t("TitleContact")}
                  </FormLabel>
                  <TextField
                    disabled
                    value={title}
                    variant="outlined"
                    sx={{
                      background: isFocused ? "#FFFFFF" : "transparent",
                      borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                    }}
                    fullWidth
                    placeholder={t("TitleContact")}
                  />
                </Box>
              )}
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("Email")}
                </FormLabel>
                <TextField
                  disabled
                  value={email}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder={t("Email")}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                my: 2,
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("Message")}
                </FormLabel>
                <TextField
                  disabled
                  // onChange={(ev) => setMessage(ev.target.value)}
                  value={message}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder={t("Message")}
                  multiline
                  rows={4}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "30px",
          padding: 4,
          position: "relative",
          my: 4,
        }}
      >
        <Box
          sx={{ borderBottom: "1px solid #E9E9E9", py: 1.7, mb: 2, pt: 0.6 }}
        >
          <Typography
            variant="span"
            sx={{
              fontSize: 18,
              fontWeight: "semibold",
              fontFamilly: "inherit",
            }}
          >
            {t("reply")}
          </Typography>
        </Box>
        <Box component="form">
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                my: 2,
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("Message")}
                </FormLabel>
                <TextField
                  onChange={(ev) => setReplyMessage(ev.target.value)}
                  value={replyMessage}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder={t("Message")}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  error={!!error.replyMessage}
                  helperText={error.replyMessage}
                  multiline
                  rows={4}
                  required
                />
              </Box>
            </Box>

            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  pb: 0,
                  fontSize: 18,
                  px: 0,
                }}
              >
                {t("UploadAttachments")}
              </Typography>
              <Box
                className="uploadSec"
                sx={{
                  py: 3,
                  pb: 0,
                }}
              >
                {fileNames.map((file, index) => (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                      <img
                        src={docsx}
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                      />

                      <Typography
                        variant="p"
                        sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
                      >
                        {t("FileName")}: {file}
                      </Typography>
                      <img
                        src={del}
                        onClick={() => handleDeleteFile(file, index)}
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </>
                ))}
                <Box sx={{ py: 4, pb: 0 }}>
                  <DropzoneArea
                    dropzoneText={t("DragandDrop")}
                    acceptedFiles={["image/*", ".pdf", ".txt"]} // Define accepted file types
                    onChange={handleFileChange}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    dropzoneclassName="custom-dropzone"
                    filesLimit={1} // Custom CSS class for DropzoneArea
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "", mt: 4 }}>
            <Button
              onClick={sendReply}
              sx={{
                textTransform: "capitalize",
                my: 2,
                bgcolor: "#0B3013",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 4,
                py: 1,
                fontWeight: 400,
                "&:hover": {
                  bgcolor: "#0B3013",
                },
              }}
            >
              {t("Submite")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EditSupportManagemnt;
