import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  useMediaQuery,
  Button,
  Grid,
  Rating,
  Avatar,
  // Select,
  // MenuItem,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Link, useSearchParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import AvatarJob from "../../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../../Assets/Images/ChatIco.png";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import { postAPI } from "../../../../Services/Api";
import {
  getCommision,
  getCountryCode,
  GetUserDetails,
  getUserDetailsFromAPI,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import { useNavigate } from "react-router-dom";
import NodataFound from "../../../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";
import {
  addDoc,
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../../../Auth/Firebase/firebase";
import "../../../../Localization/i18n";
import Markdown from "markdown-to-jsx";

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function PostedProjectDetails({
  project,
  onBack,
  updateProject,
}) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  // const [value, setValue] = React.useState(2);
  // const [hover, setHover] = React.useState(-1);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [praposals, setPraposals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getAllPraposals();
    //eslint-disable-next-line
  }, []);

  const getAllPraposals = async (pageNumber) => {
    try {
      const token = GetUserDetails().token;
      LoaderShow();

      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      const dataToSend = {
        projectId: project._id,
      };
      const response = await postAPI(
        `proposal/project-Proposals?page=${pn}`,
        dataToSend,
        token
      );

      if (response.statusCode === 200) {
        if (searchParams.get("tab") && searchParams.get("tab") == "proposal") {
          setTabValue(1);
          searchParams.delete("tab");
          setSearchParams(searchParams);
        }
        LoaderHide();
        setPraposals(response.data);
        setTotalPages(response.pagination.totalPages);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getAllPraposals(value);
  };

  const redirectToCheckout = async (ev, projectData, userData) => {
    ev.stopPropagation();
    // LoaderShow();
    // try {
    //   const token = GetUserDetails()?.token;
    //   const data = {
    //     email: userData?.email,
    //     projectId: projectData?._id,
    //     employeeId: userData?._id,
    //   };
    //   const res = await postAPI("award/projects/award", data, token);
    //   if (res.statusCode == 200) {
    //     toast.success("Award appointed successfully.");
    //     project.isAwarded = true;
    //     updateProject(projectData?._id, true);
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
    // LoaderHide();
    navigate("/dashboard/employer/sendanoffer", {
      state: {
        projectData,
        userData,
      },
    });
  };

  const createRoom = async (ev, user) => {
    ev.stopPropagation();
    LoaderShow();
    try {
      const token = GetUserDetails().token;

      const userResponse = await getUserDetailsFromAPI();
      if (
        userResponse.statusCode == 200 &&
        userResponse?.data?.firebaseUID &&
        user?.firebaseUID
      ) {
        const roomRef = collection(db, "rooms");
        const roomQuery = query(
          roomRef,
          where(
            "userIds",
            "==",
            [userResponse.data.firebaseUID, user.firebaseUID].sort()
          )
        );
        const response = await getDocs(roomQuery);
        if (response.docs.length > 0) {
          navigate(
            `/dashboard/employer/message?room_id=${response.docs[0].id}`
          );
        } else {
          const data = await addDoc(collection(db, "rooms"), {
            userIds: [userResponse.data.firebaseUID, user.firebaseUID].sort(),
            createdAt: Timestamp.now(),
            imageUrl: null,
            metadata: null,
            name: null,
            type: "direct",
            updatedAt: Timestamp.now(),
            userRoles: null,
          });
          navigate(`/dashboard/employer/message?room_id=${data?.id}`);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openProfile = (proposal) => {
    navigate("/dashboard/employer/reviewratings", {
      state: {
        user: proposal?.user,
      },
    });
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
        <Box
          sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0, display: "flex" }}
          className="plZero prZero"
        >
          <Tabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
            textColor="#222222"
            sx={{
              borderBottom: 1,
              borderColor: "#dbdbdb",
              "& .MuiTabs-indicator": {
                backgroundColor: "#000",
              },
              flex: "1",
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="project details tabs"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("ProjectDetails")}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("AllPop")}
            />
          </Tabs>
          {tabValue == 1 && (
            <Button
              onClick={getAllPraposals}
              sx={{ borderBottom: "1px solid #dbdbdb", borderRadius: "0" }}
            >
              <RefreshIcon sx={{ color: "#000" }} />
            </Button>
          )}
        </Box>
        {tabValue === 0 && (
          <Box sx={{ px: 4, py: 3 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              className="flexColumnRespo"
            >
              <Typography variant="h5" sx={{ color: "#000" }}>
                {project?.title}
              </Typography>
              <Typography variant="h6" sx={{ color: "#000" }}>
                {project?.budget?.min < project?.budget?.max
                  ? `$${Number(project?.budget?.min)}-$${Number(
                      project?.budget?.max
                    )}`
                  : `$${Number(project?.budget?.max)}`}
              </Typography>
            </Box>
            <Box sx={{ py: 4 }}>
              <Typography
                className="markdown"
                variant="p"
                sx={{ color: "#000", fontSize: 17 }}
              >
                <Markdown>{project?.description}</Markdown>
              </Typography>
              {/* <Typography
                variant="p"
                sx={{ color: "#000", fontSize: 17, py: 2, display: "block" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions.
              </Typography> */}
            </Box>

            <Box sx={{ py: 4 }}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                {t("SkillReq")}
              </Typography>
              <Box
                sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
              >
                {project?.skillsRequired?.map((skill, index) => (
                  <Button
                    sx={{
                      fontFamily: "inherit",
                      px: 4,
                      mx: 1,
                      fontSize: "16px !important",
                      borderRadius: 10,
                      pb: 1,
                      backgroundColor: "#0B3013",
                      width: "auto",
                      maxWidth: "fit-content",
                      textTransform: "capitalize",
                      color: "#fff",
                      display: "flex",
                      fontWeight: "400",

                      "&:hover": {
                        backgroundColor: "#0B3013", // Keep the same color as default
                      },
                    }}
                  >
                    {skill?.name}
                  </Button>
                ))}
                {/* <Button
                  onClick={onBack}
                  sx={{
                    fontFamily: "inherit",
                    px: 4,
                    mx: 1,
                    fontSize: "16px !important",
                    borderRadius: 10,
                    pb: 1,
                    backgroundColor: "#0B3013",
                    width: "auto",
                    maxWidth: "fit-content",
                    textTransform: "capitalize",
                    color: "#fff",
                    display: "flex",
                    fontWeight: "400",

                    "&:hover": {
                      backgroundColor: "#0B3013", // Keep the same color as default
                    },
                  }}
                >
                  Graphic Design
                </Button>
                <Button
                  onClick={onBack}
                  sx={{
                    fontFamily: "inherit",
                    px: 4,
                    mx: 1,
                    fontSize: "16px !important",
                    borderRadius: 10,
                    pb: 1,
                    backgroundColor: "#0B3013",
                    width: "auto",
                    maxWidth: "fit-content",
                    textTransform: "capitalize",
                    color: "#fff",
                    display: "flex",
                    fontWeight: "400",

                    "&:hover": {
                      backgroundColor: "#0B3013", // Keep the same color as default
                    },
                  }}
                >
                  Figma
                </Button>
                <Button
                  onClick={onBack}
                  sx={{
                    fontFamily: "inherit",
                    px: 4,
                    mx: 1,
                    fontSize: "16px !important",
                    borderRadius: 10,
                    pb: 1,
                    backgroundColor: "#0B3013",
                    width: "auto",
                    maxWidth: "fit-content",
                    textTransform: "capitalize",
                    color: "#fff",
                    display: "flex",
                    fontWeight: "400",

                    "&:hover": {
                      backgroundColor: "#0B3013", // Keep the same color as default
                    },
                  }}
                >
                  Adobe XD
                </Button> */}
              </Box>
            </Box>
            <Box sx={{}}>
              <Typography
                variant="h6"
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  pb: 0,
                  fontSize: 18,
                  px: 0,
                }}
              >
                {t("Attachments")}
              </Typography>
              <Box
                className="uploadSec"
                sx={{
                  py: 3,
                  pb: 0,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <img
                    src={doc}
                    alt="Delete"
                    style={{ cursor: "pointer" }}
                  /> */}

                  <Typography
                    variant="p"
                    sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
                  >
                    {/* 84c110f299f31bad53eee9731d43cbca.jpg (154kB) */}
                    {project?.documents?.map((url, index) => (
                      <div key={index}>
                        <h3>
                          {t("Document")} {index + 1}
                        </h3>
                        <Link target="_blank" to={url}>
                          {url}
                        </Link>
                        {/* <img
                            src={del}
                            alt="Delete"
                            style={{ cursor: "pointer", marginLeft : "5px" }}
                          /> */}
                      </div>
                    ))}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ my: 2 }}>
              <Button
                onClick={onBack}
                sx={{
                  fontFamily: "inherit",
                  px: 4,
                  fontSize: "16px !important",
                  pb: 1,
                  backgroundColor: "#0B3013",
                  width: "auto",
                  maxWidth: "fit-content",
                  textTransform: "capitalize",
                  color: "#fff",
                  display: "flex",
                  ml: "auto",
                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                {t("BackButton")}
              </Button>
            </Box>
          </Box>
        )}
        {tabValue === 1 && (
          <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
            {praposals && praposals?.length > 0 ? (
              praposals?.map((praposal, index) => (
                <Box
                  onClick={(ev) => openProfile(praposal)}
                  sx={{
                    backgroundColor: "#fff",
                    py: 1,
                    borderBottom: 1,
                    borderColor: "#e3e3e3",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    className="flexColumnRespo"
                    sx={{
                      p: 2.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ display: "flex" }}>
                        <Avatar
                          src={praposal?.user?.profile}
                          className="responsive-image avatarImg"
                        />
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", px: 2 }}
                      >
                        <Box
                          sx={{ display: "flex", textDecoration: "none" }}
                          // component={Link} to="/reviewratings" // Specify the target path
                        >
                          <Typography
                            variant="h5"
                            className="userNameText"
                            sx={{ color: "#000", fontWeight: 500 }}
                          >
                            {praposal?.user?.firstName}
                          </Typography>
                          <Typography
                            variant="h5"
                            className="userNameText"
                            sx={{ color: "#222", fontWeight: 400, px: 2 }}
                          >
                            {praposal?.user?.email}
                          </Typography>
                        </Box>
                        <Box
                          className="flexWrapResp"
                          sx={{ display: "flex", py: 1, alignItems: "center" }}
                        >
                          <Rating
                            className="catBtnTop"
                            name="hover-feedback"
                            value={praposal?.user?.averageRating}
                            precision={0.5}
                            getLabelText={getLabelText}
                            // onChange={(event, newValue) => {
                            //   setValue(newValue);
                            // }}
                            // onChangeActive={(event, newHover) => {
                            //   setHover(newHover);
                            // }}
                            disabled
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          {/* {value !== null && (
                              <Box className="catBtnTop" sx={{ mx: 2 }}>
                                {labels[hover !== -1 ? hover : value]}
                              </Box>
                            )} */}
                          {praposal?.user?.averageRating !== null && (
                            <Box className="catBtnTop" sx={{ mx: 2 }}>
                              {praposal?.user?.averageRating}
                            </Box>
                          )}

                          <Box
                            className="catBtnTop"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={chatIco} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              {praposal?.user?.totalComments}
                            </Typography>
                          </Box>
                          {/* <Box
                            className="catBtnTop"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={dollarIco} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              8.7
                            </Typography>
                          </Box> */}
                          {/* <Box
                            className="catBtnTop"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={pieChart} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              98%
                            </Typography>
                          </Box> */}
                          <Box
                            className="catBtnTop"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {praposal?.user?.location &&
                            getCountryCode(praposal?.user?.location) ? (
                              <img
                                width={25}
                                height={25}
                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                                  praposal?.user?.location
                                )}.svg`}
                                alt="chat"
                              />
                            ) : (
                              ""
                            )}
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              {praposal?.user?.location}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", py: 0.5 }}
                        >
                          {praposal?.user?.skills?.map((skill, index) => (
                            <Typography
                              className="langText skillText"
                              variant="h6"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                borderRight: 1,
                                fontWeight: 500,
                                px: 2,
                                pl: 0,
                                mr: 2,
                                my: 1,
                              }}
                            >
                              {skill?.name}
                            </Typography>
                          ))}

                          {/* <Typography
                              className="langText skillText"
  
                              variant="h6"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                borderRight: 1,
                                fontWeight: 500,
                                px: 2,
                              }}
                            >
                              Illustrations Designer
                            </Typography>
                            <Typography
                              variant="h6"
                              className="langText skillText"
  
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                fontWeight: 500,
                                px: 2,
                              }}
                            >
                              Figma Expert
                            </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className="flexDirectionRowResp catBtnTop"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#000",
                          px: 2,
                          fontWeight: 500,
                          width: "350px",
                          maxWidth: "min-content",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "block",
                        }}
                      >
                        ${praposal?.maxAmount || 0}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ color: "#222", px: 2, fontWeight: 500 }}
                      >
                        {praposal?.deliveryTime?.name || "One month"}
                      </Typography>
                    </Box>
                  </Box>

                  <Grid
                    container
                    spacing={2}
                    className=" catBtnTop flexColumnRespo"
                  >
                    <Grid itemxs={12} xl={8} lg={8} md={8}>
                      <Box
                        sx={{
                          p: 2.5,
                          pt: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="p"
                          className="markdown"
                          sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
                        >
                          <Markdown>{praposal?.description}</Markdown>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        ml: "auto",
                      }}
                    >
                      <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
                        <Button
                          onClick={(ev) => createRoom(ev, praposal?.user)}
                          sx={{
                            display: "flex",
                            mx: 1,
                            textTransform: "capitalize",
                            border: 1,
                            borderColor: "#0a3114",
                            fontSize: 16,
                            color: "#000",
                            borderRadius: 10,

                            px: 5,
                            py: 1,
                            "&:hover": {
                              bgcolor: "#0a3114",
                              color: "#fff",
                            },
                          }}
                        >
                          {t("Chat")}
                        </Button>
                        <Button
                          // component={Link} // Use Link component
                          // to="/reviewratings" // Specify the target path
                          sx={{
                            display: "flex",
                            fontFamily: "inherit",
                            textTransform: "capitalize",
                            bgcolor: "#0a3114",
                            fontSize: 16,
                            color: "#fff",
                            borderRadius: 10,
                            px: 5,
                            py: 1,
                            "&:hover": {
                              bgcolor: "#0a3114",
                            },
                            "&:disabled": {
                              color: "#e7e7e7",
                              // bgcolor: "#0a3114",
                            },
                          }}
                          disabled={project?.isAwarded}
                          onClick={(ev) =>
                            redirectToCheckout(ev, project, praposal?.user)
                          }
                        >
                          {project?.isAwarded ? t("Awarded") : t("Award")}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))
            ) : (
              <Box>
                <NodataFound />
              </Box>
            )}

            {/* <Box

              sx={{
                backgroundColor: "#fff",
                py: 1,
                borderBottom: 1,
                borderColor: "#e3e3e3",
              }}
            >
              <Box
                className="flexColumnRespo"
                sx={{
                  p: 2.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex" }}>
                    <img src={AvatarJob} className="responsive-image avatarImg" alt="dfh" />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                    <Box sx={{ display: "flex", textDecoration: 'none' }} component={Link} to="/reviewratings" >
                      <Typography
                        variant="h5"
                        className="userNameText"

                        sx={{ color: "#000", fontWeight: 500 }}
                      >
                        John Doe
                      </Typography>
                      <Typography
                        variant="h5"
                        className="userNameText"

                        sx={{ color: "#222", fontWeight: 400, px: 2 }}
                      >
                        @johndoe
                      </Typography>
                    </Box>
                    <Box className="flexWrapResp" sx={{ display: "flex", py: 1, alignItems: "center" }}>
                      <Rating
                        className="catBtnTop"
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {value !== null && (
                        <Box className="catBtnTop" sx={{ mx: 2 }}>
                          {labels[hover !== -1 ? hover : value]}
                        </Box>
                      )}

                      <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
                        <img src={chatIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          200
                        </Typography>
                      </Box>
                      <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
                        <img src={dollarIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          8.7
                        </Typography>
                      </Box>
                      <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
                        <img src={pieChart} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          98%
                        </Typography>
                      </Box>
                      <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
                        <img src={indiaIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          India
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", py: 0.5 }}>
                      <Typography
                        className="langText skillText"
                        variant="h6"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          borderRight: 1,
                          fontWeight: 500,
                          px: 2,
                          pl: 0,
                        }}
                      >
                        UI/UX Designer
                      </Typography>
                      <Typography
                        className="langText skillText"

                        variant="h6"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          borderRight: 1,
                          fontWeight: 500,
                          px: 2,
                        }}
                      >
                        Illustrations Designer
                      </Typography>
                      <Typography
                        variant="h6"
                        className="langText skillText"

                        sx={{
                          color: "#222",
                          fontSize: 18,
                          fontWeight: 500,
                          px: 2,
                        }}
                      >
                        Figma Expert
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="flexDirectionRowResp catBtnTop"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#000", px: 2, fontWeight: 500 }}
                  >
                    $150
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "#222", px: 2, fontWeight: 500 }}
                  >
                    In 7 Days
                  </Typography>
                </Box>
              </Box>

              <Grid container spacing={2} className=" catBtnTop flexColumnRespo"
              >
                <Grid itemxs={12} xl={8} lg={8} md={8}>
                  <Box
                    sx={{
                      p: 2.5,
                      pt: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
                    >
                      I'm seeking an experienced and efficient app developer to
                      create an innovative social networking application for iOS
                      platform. Details about the target audience are currently
                      confidential. Since the completion I'm seeking an
                      experienced and efficient app developer to create more....
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                    ml: "auto",
                  }}
                >
                  <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
                    <Button
                      sx={{
                        display: "flex",
                        mx: 1,
                        textTransform: "capitalize",
                        border: 1,
                        borderColor: "#0a3114",
                        fontSize: 16,
                        color: "#000",
                        borderRadius: 10,

                        px: 5,
                        py: 1,
                        "&:hover": {
                          bgcolor: "#0a3114",
                          color: '#fff'
                        },
                      }}
                    >
                      Chat
                    </Button>
                    <Button
                      component={Link} to="/reviewratings"
                      sx={{
                        display: "flex",
                        fontFamily: "inherit",
                        textTransform: "capitalize",
                        bgcolor: "#0a3114",
                        fontSize: 16,
                        color: "#fff",
                        borderRadius: 10,
                        px: 5,
                        py: 1,
                        "&:hover": {
                          bgcolor: "#0a3114",
                        },
                      }}
                    >
                      Award
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}

            <Box
              sx={{
                py: 2,
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                onClick={onBack}
                sx={{
                  fontFamily: "inherit",
                  px: 4,
                  fontSize: "16px !important",
                  pb: 1,
                  backgroundColor: "#0B3013",
                  width: "auto",
                  maxWidth: "fit-content",
                  textTransform: "capitalize",
                  color: "#fff",
                  display: "flex",
                  mr: "auto",
                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                {t("BackButton")}
              </Button>
              <Box>
                {/* <CustomPagination className='catBtnTop' count={1} /> */}
                <CustomPagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
