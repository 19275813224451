// AppRoutes.js

import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import MainLayout from "../Layout/MainLayout/MainLayout";
import Home from "../Pages/Home/Home";
import Signin from "../Auth/Signin/Signin";
import Signup from "../Auth/Signup/Signup";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Dashboard from "../Dashboard/Dashboard";
import DashboardLayout from "../Dashboard/DashboardLayout/DashboardLayout";
import EmployeeDashboard from "../Dashboard/EmployeeDashboardPages/EmployeeDashboard/EmployeeDashboard";
import EmployeeMyProposals from "../Dashboard/EmployeeDashboardPages/EmployeeMyProposals/EmployeeMyProposals";
import EmployeerDashboard from "../Dashboard/EmployeerDashboardPages/EmployeerDashboard/EmployeerDashboard";
import EmployeerMyProjects from "../Dashboard/EmployeerDashboardPages/EmployeerMyProjects/EmployeerMyProjects";
import EmployeeMessage from "../Dashboard/EmployeeDashboardPages/EmployeeMessage/EmployeeMessage";
import EmployeeProfile from "../Dashboard/EmployeeDashboardPages/EmployeeProfile/EmployeeProfile";
import BrowseJobs from "../Pages/BrowseJobs/BrowseJobs";
import Help from "../Pages/Help/Help";
// import EmployeerBookmark from '../Dashboard/EmployeerDashboardPages/EmployeerBookmark/EmployeerBookmark';
import EmployeerMessage from "../Dashboard/EmployeerDashboardPages/EmployeerMessage/EmployeerMessage";
import EmployeerCreateProject from "../Dashboard/EmployeerDashboardPages/EmployeerCreateProject/EmployeerCreateProject";
import EmployeerProfile from "../Dashboard/EmployeerDashboardPages/EmployeerProfile/EmployeerProfile";
import ProposedEmployeer from "../Pages/ProposedEmployeerReviewRating/ProposedEmployeer";
import SendAnOfferAll from "../Pages/SendAnOfferAll/SendAnOfferAll";
import ProjectDetails from "../Pages/ProjectDetails/ProjectDetails";
import Services from "../Pages/Services/Services";
import EmployeeSaved from "../Dashboard/EmployeeDashboardPages/EmployeeSaved/EmployeeSaved";
import EmployeeMyProjects from "../Dashboard/EmployeeDashboardPages/EmployeeMyProjects/EmployeeMyProjects";
import EmployeerEditProject from "../Dashboard/EmployeerDashboardPages/EmployeerEditProject/EmployeerEditProject";
import { GetUserDetails } from "../Helpers/Utility";
import ProtectedRoute from "./ProctedRoutes";
import EmployeerBrowseProfileDashboard from "../Dashboard/EmployeerDashboardPages/EmployeerBrowseProfileDashboard/EmployeerBrowseProfileDashboard";
import EmployeerVerifyEmail from "../Dashboard/EmployeerDashboardPages/EmployeerVerifyEmail/EmployeerVerifyEmail";
import EmployeerChangePassword from "../Dashboard/EmployeerDashboardPages/EmployeerChangePassword/EmployeerChangePassword";
import EmployeerBillingPay from "../Dashboard/EmployeerDashboardPages/EmployeerBillingPay/EmployeerBillingPay";
import { Alert } from "../Helpers/Alert";
import Terms from "../Pages/Terms/Terms";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import EmployeeAwardReq from "../Dashboard/EmployeeDashboardPages/EmployeeAwardReq/EmployeeAwardReq";
import TrustAndSafetey from "../Pages/TrustAndSafetey/TrustAndSafetey";
import HelpAndSupport from "../Pages/HelpAndSupport/HelpAndSupport";
import EmployeerPayments from "../Dashboard/EmployeerDashboardPages/EmployeerPayments/EmployeerPayments";
import PricingFees from "../Pages/PricingFees/PricingFees";
import { toast } from "react-toastify";
import DeletionPolicy from "../Pages/DeletionPolicy/DeletionPolicy";
import {
  ACCOUNTANT_ROLE,
  ADMIN_ROLE,
  CHAT_ROLE,
  EMPLOYEE_ROLE,
  EMPLOYER_ROLE,
} from "../Helpers/roles";
import RolesManagement from "../Dashboard/AdminPages/RolesManagement/RolesManagement";
import UserManagement from "../Dashboard/AdminPages/UserManagement/UserManagement";
import AdminMessage from "../Dashboard/AdminPages/AdminMessage/AdminMessage";
import AdminDashboard from "../Dashboard/AdminPages/AdminDashboard/AdminDashboard";
import PayFeesManage from "../Dashboard/AdminPages/PayFeesManage/PayFeesManage";
import SupportManagement from "../Dashboard/AdminPages/SupportManagement/SupportManagement";
import DisputeCaseManagement from "../Dashboard/AdminPages/DisputeCaseManagement/DisputeCaseManagement";
import DonationManagement from "../Dashboard/AdminPages/DonationManagement/DonationManagement";
import RevenueReports from "../Dashboard/AdminPages/RevenueReports/RevenueReports";
import AdvertisementManagement from "../Dashboard/AdminPages/AdvertisementManagement/AdvertisementManagement";
import WithdrawalHistory from "../Dashboard/WithdrawalHistory/WithdrawalHistory";
import ProjectManagement from "../Dashboard/AdminPages/ProjectManagement/ProjectManagement";
import DonationPayoutManagement from "../Dashboard/AdminPages/DonationPayoutManagement/DonationPayoutManagement";
import UserDispute from "../Dashboard/UserDispute/UserDispute";
import AdminDispute from "../Dashboard/AdminPages/AdminDispute/AdminDispute";
import { useTranslation } from "react-i18next";
import loader from "../Assets/Images/loader.svg";

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);

  return <></>;
};

const Admin = () => {
  return <h1>Admin support</h1>;
};

const ChatSupport = () => {
  return <h1>Chat support</h1>;
};

export default function AppRoutes() {
  const userDetails = GetUserDetails();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    // Check if we have navigated in this session already
    if(window.location.pathname != "/") {
       sessionStorage.setItem("hasNavigated", true);
      return;
    }
    let hasNavigated = sessionStorage.getItem("hasNavigated");
    if (searchParams.get("crypto_payment_status")) {
      if (searchParams.get("crypto_payment_status") == "success") {
        toast.info(t("toast1"));
      } else {
        toast.info(t("toast2"));
      }
      searchParams.delete("crypto_payment_status");
      setSearchParams(searchParams);
    }
    if (
      !hasNavigated &&
      !window.location.pathname.includes("dashboard") &&
      !window.location.pathname.includes("sendanoffer")
    ) {
      let loginData = localStorage.getItem("loginData");
      if (loginData) {
        let parsedLoginData = JSON.parse(loginData);
        let authToken = parsedLoginData.token;
        let role = parsedLoginData.role;
        if (searchParams.get("change_password_token")) {
          return;
        }

        if (authToken && role.includes(ADMIN_ROLE)) {
          sessionStorage.setItem("hasNavigated", "true");
          navigate("/dashboard/admin/dashboard");
          return;
        }
        if (authToken && role.includes(EMPLOYER_ROLE)) {
          navigate("/dashboard/employer/browseprofiles");
          // Set the flag in sessionStorage
          sessionStorage.setItem("hasNavigated", "true");
          return;
        }
        if (authToken && role.includes(EMPLOYEE_ROLE)) {
          navigate("/dashboard/employee");
          // Set the flag in sessionStorage
          sessionStorage.setItem("hasNavigated", "true");
          return;
        }
        if (
          authToken &&
          role.includes(ACCOUNTANT_ROLE) &&
          !role.includes(ADMIN_ROLE)
        ) {
          sessionStorage.setItem("hasNavigated", "true");
          navigate("/dashboard/accountant/payments");
          return;
        }
        if (
          authToken &&
          role.includes(CHAT_ROLE) &&
          !role.includes(ADMIN_ROLE)
        ) {
          sessionStorage.setItem("hasNavigated", "true");
          navigate("/dashboard/admin/chat");
          return;
        }
      }
    }
  }, [navigate]);

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="deletion-policy" element={<DeletionPolicy />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} />
        <Route path="projectdetails" element={<ProjectDetails />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<ContactUs />} />
        <Route
          path="browsejobs"
          element={<BrowseJobs userRole={"employee"} />}
        />
        <Route
          path="reviewratings"
          element={<ProposedEmployeer userRole={userDetails?.role} />}
        />
        <Route
          path="sendanoffer"
          element={<SendAnOfferAll userRole={userDetails?.role} />}
        />
        <Route
          path="dashboard/*"
          element={<DashboardLayout role={userDetails?.role} />}
        />
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="trustsaftey" element={<TrustAndSafetey />} />
        <Route path="help" element={<HelpAndSupport />} />
        <Route path="pricingfees" element={<PricingFees />} />
      </Route>
      {/* Dashboard routes */}
      <Route
        path="dashboard"
        element={
          <ProtectedRoute
            element={<DashboardLayout role={userDetails?.role} />}
          />
        }
      >
        {/* <Route path="/dashboard" element={<DashboardLayout role={userDetails?.role} />}> */}
        <Route index element={<Dashboard />} />
        {/* Employee Dashboard */}
        <Route path="employee/*" element={<EmployeeDashboard />} />
        <Route
          path="dispute"
          element={
            <div
              id="hideloding"
              className="loding-display"
              style={{ display: "none" }}
            >
              <img src={loader} alt="loader-img" />
            </div>
          }
        />
        <Route path="employee/myproposals" element={<EmployeeMyProposals />} />
        <Route path="employee/myprojects" element={<EmployeeMyProjects />} />
        <Route path="employee/bookmark" element={<EmployeeSaved />} />
        <Route path="employee/message" element={<EmployeeMessage />} />
        <Route path="employee/profile" element={<EmployeeProfile />} />
        <Route path="employee/projectdetails" element={<ProjectDetails />} />
        <Route path="employee/awardrequest" element={<EmployeeAwardReq />} />
        <Route path="employee/dispute" element={<UserDispute />} />
        <Route
          path="employee/withdrawal-history"
          element={<WithdrawalHistory />}
        />

        {/* Employer Dashboard */}
        <Route path="employer/*" element={<EmployeerDashboard />} />
        <Route
          path="employer/browseprofiles"
          element={<EmployeerBrowseProfileDashboard />}
        />
        <Route path="employer/myprojects" element={<EmployeerMyProjects />} />
        <Route
          path="employer/reviewratings"
          element={<ProposedEmployeer userRole={userDetails?.role} />}
        />
        <Route
          path="employer/sendanoffer"
          element={<SendAnOfferAll userRole={userDetails?.role} />}
        />
        {/* <Route path="employer/bookmark" element={<EmployeerBookmark />} /> */}
        <Route path="employer/message" element={<EmployeerMessage />} />
        <Route
          path="employer/createproject"
          element={<EmployeerCreateProject />}
        />
        <Route path="employer/editproject" element={<EmployeerEditProject />} />
        <Route path="employer/profile" element={<EmployeerProfile />} />
        <Route path="employer/verifyemail" element={<EmployeerVerifyEmail />} />
        <Route
          path="employer/withdrawal-history"
          element={<WithdrawalHistory />}
        />
        {/* <Route path="employer/changepassword" element={<EmployeerChangePassword />} /> */}
        <Route
          path="employer/bilingpayments"
          element={<EmployeerBillingPay />}
        />
        <Route path="employer/dispute" element={<UserDispute />} />

        <Route path="accountant/payments" element={<EmployeerPayments />} />

        {/* ADMIN ROUTER START */}
        <Route path="admin/dashboard" element={<AdminDashboard />} />
        <Route
          path="admin/wwh-user-management"
          element={<UserManagement isWWH={true} />}
        />
        <Route
          path="admin/user-management"
          element={<UserManagement isWWH={false} />}
        />
        <Route path="admin/roles-management" element={<RolesManagement />} />
        <Route path="admin/fees-management" element={<PayFeesManage />} />
        <Route
          path="admin/support-management"
          element={<SupportManagement />}
        />
        <Route
          path="admin/contact-management"
          element={<SupportManagement />}
        />
        {/* <Route path="admin/support-management" element={<SupportManagement />} /> */}
        <Route path="admin/dispute-management" element={<AdminDispute />} />
        <Route
          path="admin/donation-management"
          element={<DonationManagement />}
        />
        <Route
          path="admin/donation-payout-management"
          element={<DonationPayoutManagement />}
        />
        <Route
          path="admin/project-management"
          element={<ProjectManagement />}
        />
        <Route path="admin/revenue-reports" element={<RevenueReports />} />
        <Route path="admin/chat" element={<AdminMessage />} />
        <Route
          path="admin/advertisement"
          element={<AdvertisementManagement />}
        />
        {/* ADMIN ROUTER END */}
      </Route>
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}
