import { useTheme } from "@emotion/react";
import {
  Button,
  FormLabel,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, display, height, minHeight } from "@mui/system";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useEffect, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { postAPI, updateAPI } from "../../../Services/Api";
import {
  GetUserDetails,
  isValidUrl,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import { useTranslation } from "react-i18next";

export default function AddAdvertisementDialog({
  open,
  onClose,
  onSave,
  orderStart,
  advertisement,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isFocused, setIsFocused] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState({});
  const [status, setStatus] = useState(""); // Default to 'active'
  const [url, setUrl] = useState("");

  const handleFocus = () => {};

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const { t } = useTranslation();

  function convertToDateInputValue(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (!open) {
      setStartDate("");
      setEndDate("");
      setUrl("");
      setError({});
      setFileNames([]);
      setFiles([]);
    } else if (advertisement?._id && open) {
      setStartDate(convertToDateInputValue(advertisement?.displayStart));
      setEndDate(convertToDateInputValue(advertisement?.displayEnd));
      setFileNames([advertisement?.url]);
      setUrl(advertisement?.link);
      setStatus(advertisement?.isActive ? "active" : "inactive");
    }
  }, [open, advertisement]);

  // const handleFileChange = (newFiles) => {
  //   if (
  //     [...files, ...newFiles].reduce((val, ele) => ele?.size + val, 0) >
  //     1_048_576
  //   ) {
  //     toast.warn("Maximum size of attachments sho uld be less than 2mb.");
  //     return;
  //   }
  //   setFiles([...files, ...newFiles]);
  //   const names = newFiles.map((file) => URL.createObjectURL(file));
  //   setFileNames((dt) => [...dt, ...names]);
  // };

  const handleFileChange = (newFiles) => {
    if (newFiles.length > 0) {
      if ([...newFiles].reduce((val, ele) => ele?.size + val, 0) > 1_048_576) {
        toast.warn(t("toast16"));
        return;
      }
      setFiles([...newFiles]);
      const names = newFiles.map((file) => URL.createObjectURL(file));
      setFileNames((dt) => [...names]);
    }
  };

  const handleDeleteFile = (file, index) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => index != i));
    setFileNames((prevFiles) => prevFiles.filter((f, i) => index != i));
  };

  const validateform = () => {
    let err = {};
    let isValid = true;

    if (!startDate.trim()) {
      err = { ...err, startDate: t("plzEnterstDt") };
      isValid = false;
    }

    if (!endDate.trim()) {
      err = { ...err, endDate: t("plzEnterenDt") };
      isValid = false;
    }

    if (fileNames.length <= 0) {
      err = { ...err, files: t("plzSelectFile") };
      isValid = false;
    }

    if (advertisement?._id && !status) {
      err = { ...err, status: t("plzSelectstatus") };
      isValid = false;
    }

    if (url && !isValidUrl(url)) {
      err = { ...err, url: t("plxenterValUrl") };
      isValid = false;
    }

    setError(err);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateform()) return;
    const token = GetUserDetails()?.token;
    if (!token) return;
    if (advertisement?._id) {
      const dataToSend = new FormData();
      dataToSend.append("displayStart", new Date(startDate));
      dataToSend.append("id", advertisement?._id);
      dataToSend.append("displayEnd", new Date(endDate));
      dataToSend.append("link", url);

      // files.map((ele, index) => {
      //   dataToSend.append(`images`, ele);
      //   dataToSend.append(`order_${index + 1}`, orderStart + index + 1);
      // });
      dataToSend.append("isActive", status == "active");
      if (files.length > 0) {
        dataToSend.append(`images`, files[0]);
      }
      try {
        LoaderShow();
        const response = await updateAPI(
          "advertisement/update",
          dataToSend,
          token
        );
        if (response.statusCode == 200) {
          toast.success(t("Advertisement updated successfully"));
          onClose();
          onSave();
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    } else {
      const dataToSend = new FormData();
      dataToSend.append("displayStart", new Date(startDate));
      dataToSend.append("displayEnd", new Date(endDate));

      // files.map((ele, index) => {
      //   dataToSend.append(`images`, ele);
      //   dataToSend.append(`order_${index + 1}`, orderStart + index + 1);
      // });

      dataToSend.append(`images`, files[0]);
      dataToSend.append(`order_1`, orderStart + 1);
      if (url) {
        dataToSend.append("link", url);
      }
      try {
        LoaderShow();
        const response = await postAPI(
          "advertisement/create",
          dataToSend,
          token
        );
        if (response.statusCode == 200) {
          toast.success(t("Advertisement created successfully"));
          onClose();
          onSave();
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    }
    LoaderHide();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="logout-modal"
      aria-describedby="logout-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          maxHeight: "90dvh",
          overflow: "auto",
          boxShadow: 24,
          p: 3,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
          {advertisement?._id ? t("updateAdvst") : t("addAdvst")}
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            top: 14,
            right: 10,
          }}
          onClick={onClose}
        >
          <img src={close} alt="Close" />
        </IconButton>
        <Box sx={{ flex: 1, mt: 3 }}>
          <FormLabel
            sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
          >
            {t("startDate")}
          </FormLabel>
          <TextField
            onChange={(ev) => setStartDate(ev.target.value)}
            value={startDate}
            error={!!error.startDate}
            helperText={error.startDate}
            type="date"
            variant="outlined"
            sx={{
              background: isFocused ? "#FFFFFF" : "transparent",
              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            }}
            fullWidth
            placeholder={t("startDate")}
            onFocus={handleFocus}
            onBlur={handleFocus}
            required
          />
        </Box>
        <Box sx={{ flex: 1, mt: 2 }}>
          <FormLabel
            sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
          >
            {t("endDate")}
          </FormLabel>
          <TextField
            onChange={(ev) => setEndDate(ev.target.value)}
            value={endDate}
            error={!!error.endDate}
            helperText={error.endDate}
            type="date"
            variant="outlined"
            sx={{
              background: isFocused ? "#FFFFFF" : "transparent",
              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            }}
            fullWidth
            placeholder={t("endDate")}
            onFocus={handleFocus}
            onBlur={handleFocus}
            required
          />
        </Box>
        <Box sx={{ flex: 1, mt: 2 }}>
          <FormLabel
            sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
          >
            {t("link")}
          </FormLabel>
          <TextField
            onChange={(ev) => setUrl(ev.target.value)}
            value={url}
            error={!!error.url}
            helperText={error.url}
            type="url"
            variant="outlined"
            sx={{
              background: isFocused ? "#FFFFFF" : "transparent",
              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            }}
            fullWidth
            placeholder={t("link")}
            onFocus={handleFocus}
            onBlur={handleFocus}
          />
        </Box>
        {advertisement?._id && (
          <Box sx={{ flex: 1, mt: 2 }}>
            <FormLabel
              sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
            >
              {t("Status")}
            </FormLabel>
            <Dropdown
              value={status}
              onChange={(ev) => setStatus(ev.target.value)}
              options={[
                { value: "active", label: t("ActiveOne") },
                { value: "inactive", label: t("Inactive") },
              ]}
              label="Status"
            />
            {error?.status && (
              <span style={{ color: "red" }}>{error.status}</span>
            )}
          </Box>
        )}
        <Box className="dropdown" sx={{ flex: 1, mt: 3 }}>
          <DropzoneArea
            dropzoneText={t("DragandDrop")}
            acceptedFiles={["image/*"]}
            onChange={handleFileChange}
            showPreviewsInDropzone={false}
            showAlerts={false}
            sx={{
              minHeight: "150px",
            }}
            dropzoneclassName="custom-dropzone"
            filesLimit={1}
          />
          {error.files && <span style={{ color: "red" }}>{error.files}</span>}
        </Box>
        {fileNames.length > 0 && (
          <Box
            style={{
              marginTop: "20px",
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {fileNames.map((ele, index) => (
              <div key={index} style={{ position: "relative" }}>
                <img style={{ height: "100px" }} src={ele} />
              </div>
            ))}
          </Box>
        )}
        <Box sx={{ my: 3, display: "flex" }}>
          <Button
            onClick={onClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              textTransform: "capitalize",
              bgcolor: "#0a3114",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              px: 5,
              py: 1,
              "&:hover": {
                bgcolor: "#0a3114",
              },
            }}
            endIcon={<ArrowOutwardIcon color="#fff" />}
          >
            {t("Cancel")}
          </Button>{" "}
          <Button
            onClick={handleSave}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              textTransform: "capitalize",
              bgcolor: "#0a3114",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              px: 5,
              py: 1,
              "&:hover": {
                bgcolor: "#0a3114",
              },
            }}
            endIcon={<ArrowOutwardIcon color="#fff" />}
          >
            {t("Save")}
          </Button>{" "}
        </Box>
      </Box>
    </Modal>
  );
}
