import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  FormLabel,
  FormControl,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PayCryptoPaypal({ data, saveData }) {
  const [paypalAmount, setPaypalAmount] = useState("");
  const [percentage1, setPercentage1] = useState("");
  const [percentage2, setPercentage2] = useState("");
  const [error, setError] = useState({
    paypalAmount: "",
    percentage1: "",
    percentage2: "",
  });

  const { t } = useTranslation();

  const handleCryptoAmountChange = (event) =>
    setPaypalAmount(event.target.value);
  const handlePercentage1Change = (event) => setPercentage1(event.target.value);
  const handlePercentage2Change = (event) => setPercentage2(event.target.value);

  useEffect(() => {
    setPaypalAmount(data?.paypalAmount || "");
    setPercentage1(data?.paypalMinPercentage || "");
    setPercentage2(data?.paypalPercentage || "");
    setError({
      paypalAmount: "",
      percentage1: "",
      percentage2: "",
    });
  }, [data]);

  const validateData = () => {
    setError({
      paypalAmount: "",
      percentage1: "",
      percentage2: "",
    });
    let err = {};
    let isValid = true;
    if (!paypalAmount || paypalAmount < 0) {
      err.paypalAmount = t("amtgrttheEq0");
      isValid = false;
    }
    if (!percentage1 || percentage1 < 0 || percentage1 > 100) {
      err.percentage1 = t("minPerVal");
      isValid = false;
    }
    if (!percentage2 || percentage2 < 0 || percentage2 > 100) {
      err.percentage2 = t("maxPerVal");
      isValid = false;
    }

    setError(err);
    return isValid;
  };

  const setData = async () => {
    if (!validateData()) return;
    saveData(
      {
        paypalAmount: paypalAmount,
        paypalMinPercentage: percentage1,
        paypalPercentage: percentage2,
      },
      data._id
    );
  };

  return (
    <section>
      <Box p={1}>
        <Box width={"100%"} borderBottom={1} borderColor={"#E9E9E9"} pb={2}>
          <Typography variant="h6" fontWeight={600}>
            {t("paymethPaypal")}
          </Typography>
        </Box>
        <Box my={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>{t("amt")}</FormLabel>
              <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                {" "}
                <TextField
                  value={paypalAmount}
                  onChange={handleCryptoAmountChange}
                  id="amount"
                  placeholder={t("amt")}
                  inputProps={{ "aria-label": "amount" }}
                  type="number"
                  error={!!error?.paypalAmount}
                  helperText={error?.paypalAmount}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel>{t("minPerLessAmt")}</FormLabel>
              <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                {" "}
                <TextField
                  type="number"
                  value={percentage1}
                  onChange={handlePercentage1Change}
                  placeholder={t("minPerLessAmt")}
                  id="amount"
                  inputProps={{ "aria-label": "amount" }}
                  error={!!error.percentage1}
                  helperText={error.percentage1}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel>{t("maxPerGrtamt")}</FormLabel>
              <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                {" "}
                <TextField
                  type="number"
                  value={percentage2}
                  onChange={handlePercentage2Change}
                  placeholder={t("maxPerGrtamt")}
                  id="amount"
                  inputProps={{ "aria-label": "amount" }}
                  error={!!error.percentage2}
                  helperText={error.percentage2}
                />
              </FormControl>
            </Grid>
            <Button
              onClick={setData}
              sx={{
                ml: 2,
                display: "flex",
                fontFamily: "inherit",
                textTransform: "capitalize",
                justifyContent: "start",
                mr: "auto",
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                mt: 4,
                px: 5,
                py: 1,
                maxWidth: "fit-content",
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
            >
              {t("Save")}
            </Button>
          </Grid>
        </Box>
      </Box>
    </section>
  );
}
