import React from "react";
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import Con from "../../Assets/Images/Contact1.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
import "./HelpAndSupport.css";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { isValidEmail, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { postAPI } from "../../Services/Api";
import { toast } from "react-toastify";
import loader from "../../Assets/Images/loader.svg";

export default function HelpAndSupport() {
  const [helpOption, setHelpOption] = React.useState("");
  const [message, setmessage] = React.useState("");
  const [title, settitle] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [errors, setError] = React.useState({});
  const { t } = useTranslation();

  const validateForm = () => {
    let isValid = true;
    setError({});
    if (!helpOption.trim()) {
      setError((dt) => {
        return { ...dt, helpOption: t("selectOpt") };
      });
      isValid = false;
    }

    if (!message.trim()) {
      setError((dt) => {
        return { ...dt, message: t("enterMsg") };
      });
      isValid = false;
    }
    if (!title.trim()) {
      setError((dt) => {
        return { ...dt, title: t("enterTitle") };
      });
      isValid = false;
    }
    if (!email.trim()) {
      setError((dt) => {
        return { ...dt, email: t("EnterEmailAdd") };
      });
      isValid = false;
    } else if (!isValidEmail(email)) {
      setError((dt) => {
        return { ...dt, email: t("InvalidEmailFormat") };
      });
      isValid = false;
    }

    return isValid;
  };

  const handleFocus = () => {
    validateForm();
  };

  const onSubmit = async () => {
    if (!validateForm()) return;
    LoaderShow();
    try {
      const res = await postAPI("contactus/contact-us", {
        message,
        title,
        helpOption,
        email,
        status: "pending",
        type: "help",
      });
      if (res.statusCode == 200) {
        toast.success(t("toast39"));
        settitle("");
        setmessage("");
        setHelpOption("");
        setError({});
      }
    } catch (err) {
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const handleChange = (event) => {
    setHelpOption(event.target.value);
  };

  const options = [
    {
      value: "Signup/SignIn troubleshoot",
      label: "Signup/SignIn troubleshoot",
    },
    { value: "Recover account issues", label: "Recover account issues" },
    { value: "Issues with post projects", label: "Issues with post projects" },
    { value: "Issue in Adding proposal", label: "Issue in Adding proposal" },
    {
      value: "Issues in Milestone payment",
      label: "Issues in Milestone payment",
    },
    { value: "Payment related queries", label: "Payment related queries" },
    { value: "Other issues", label: "Other issues" },
  ];

  return (
    <section className="helpSupport">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Container maxWidth="xl" sx={{ px: 2 }}>
        <Box sx={{ py: 10, px: 1, position: "relative" }} className="">
          <Typography variant="h3" sx={{ color: "#fff", fontWeight: "medium" }}>
            {t("HelpSupport")}
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#fff", py: 2, display: "block", fontWeight: "light" }}
          >
            {t("FindSol")}
          </Typography>

          <Box
            sx={{
              py: 15,
              pb: 2,
              px: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            className=""
          >
            <Typography
              variant="h2"
              sx={{ color: "#fff", fontWeight: "medium" }}
            >
              {t("LetsGet")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: "#fff",
                py: 2,
                pb: 0,
                display: "block",
                fontWeight: "light",
              }}
            >
              {t("HaveTrouble")}
            </Typography>
          </Box>
          <Grid
            container
            className="contactBg"
            md={{ px: 8 }}
            sx={{
              px: 3,
              my: 5,
              backgroundColor: "#fff",
              maxWidth: "1500px",
              mx: "auto",
            }}
          >
            <Grid item xs={12} md={6} sx={{ display: "block", m: "auto" }}>
              <Box sx={{ p: 2 }}>
                <img style={{ width: "100%" }} src={Con} alt="nf" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2, py: 10 }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel id="help-select-label" sx={{ py: 2 }}>
                    {" "}
                    {t("Email")}
                  </FormLabel>
                  <TextField
                    placeholder={t("Email")}
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    id="title-input"
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    required
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel id="help-select-label" sx={{ py: 2 }}>
                    {" "}
                    {t("HowWeCan")}
                  </FormLabel>
                  <Dropdown
                    value={helpOption}
                    onChange={handleChange}
                    options={options.map((ele) => ({
                      ...ele,
                      label: t(ele.label),
                    }))}
                    label={t("HowWeCan")}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    error={!!errors.helpOption}
                    helperText={errors.helpOption}
                  />
                  <span style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                    {errors.helpOption}
                  </span>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel htmlFor="title-input" sx={{ py: 2 }}>
                    {t("TitleContact")}
                  </FormLabel>
                  <TextField
                    placeholder={t("TitleContact")}
                    value={title}
                    onChange={(ev) => settitle(ev.target.value)}
                    id="title-input"
                    variant="outlined"
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    error={!!errors.title}
                    helperText={errors.title}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel htmlFor="needs-input" sx={{ py: 2 }}>
                    {t("describePlease")}
                  </FormLabel>
                  <TextField
                    placeholder={t("describePlease")}
                    value={message}
                    onChange={(ev) => setmessage(ev.target.value)}
                    id="needs-input"
                    multiline
                    rows={4}
                    variant="outlined"
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    error={!!errors.message}
                    helperText={errors.message}
                  />
                </FormControl>
                <Box sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
                  <Button
                    className="catBtnTop"
                    sx={{
                      textTransform: "capitalize",
                      bgcolor: "#0B3013",
                      fontSize: 16,
                      color: "#fff",
                      borderRadius: 10,
                      px: 5,
                      py: 1,
                      "&:hover": {
                        bgcolor: "#0B3013",
                      },
                    }}
                    onClick={onSubmit}
                    endIcon={<ArrowOutwardIcon color="#fff" />}
                  >
                    {t("Submite")}
                  </Button>{" "}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
